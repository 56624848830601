import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {login, sendOtp, resetFilters} from '../../../actions'
import {getError, getOtpNeeded} from '../../../reducers'
import imgLogo from '../../../images/logo.png'
import LoginForm from './LoginForm'
import OTPForm from './OTPForm'
import ForgotPassword from './ForgotPassword'


const Login = ({history}) => {

  const loginError = useSelector(getError)
  const otpNeeded = useSelector(getOtpNeeded)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetFilters())
  }, [dispatch])

  const [forgot, setForgot] = useState(false)
  const onSubmitLogin = values => {
    dispatch(login(values, history))
  }

  const onSubmitOTP = values => {
    dispatch(sendOtp(values, otpNeeded, history))
  }

  return (
    <>
      <LoginContainer>
        <Logo><img src={imgLogo} alt='logo'/></Logo>
        {otpNeeded &&
        <OTPForm
          loginError={loginError}
          onSubmit={onSubmitOTP}
        />
        }
        {forgot &&
        <ForgotPassword setForgot={setForgot}/>
        }
        {!otpNeeded && !forgot &&
        <LoginForm
          loginError={loginError}
          onSubmit={onSubmitLogin}
          setForgot={setForgot}
        />
        }
      </LoginContainer>
    </>
  )
}

/* region STYLE */

const LoginContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 550px;
  width: 350px;
  background: #218FC1;
  border-radius: 15px;
  box-shadow: 0 0 16px 1px rgba(0,0,0,0.75);
  border: 1px solid #818181;
`

//   background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, #218FC1 100%);
// 20px 30px 50px 30px
const Logo = styled.div`
  padding: 30px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  & img {
    width: 100%;
  }
`

/* endregion */

export default Login