import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import Filters from './Filters'
import Table from './Table'
import withPagination from '../../withPagination'
import {setTitle} from '../../../actions'

const TablePaginated = withPagination(Filters, Table)

const UserGroups = () => {

  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'User - gruppi')

  const {entities, total} = useEntities("user-groups")
  const [shownEntities, setShownEntities] = useState([])

  useEffect(() => {
    setShownEntities(entities)
  }, [entities])

  return (
    <TablePaginated
      entities={entities}
      shownEntities={shownEntities}
      setShownEntities={setShownEntities}
      total={total}
      isLocal
    />
  )
}

export default UserGroups