import React from 'react'
import {TableContainer, Table, Tr, Th, Td, TdImage} from '../../common'

const TableTicketTemplate = ({entities, order, switchEnabled, ...rest}) => {
  return (
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th field='id' {...rest}>ID</Th>
          <Th field='name' {...rest}>Nome</Th>
          <Th field='priority' {...rest} >Priorità</Th>
          <Th field='type' {...rest} >Tipo</Th>
          <Th field='category' {...rest} >Categoria</Th>
          <Th field='subcategory'{...rest} >Sottocategoria</Th>
          <Th field='problemType' {...rest} >Tipo di problema</Th>
        </tr>
        </thead>
        <tbody data-cy={'ticket-templates-table'}>
        {entities && entities
          .map(entity =>
            <Tr key={entity.id} data-cy={`ticket-templates-row-${entity.id}`}>
              <TdImage tooltip="Modifica template" editPath={`/ticket-templates/${entity.id}`}/>
              <Td dataCy={'id'}>{entity.id || '-'}</Td>
              <Td dataCy={'name'}>{entity.name || '-'}</Td>
              <Td dataCy={'priority'}>{entity.priority ? entity.priority.name : '-'}</Td>
              <Td dataCy={'type'}>{entity.type ? entity.type.name : '-'}</Td>
              <Td dataCy={'category'}>{entity.category ? entity.category.name : '-'}</Td>
              <Td dataCy={'subcategory'}>{entity.subcategory ? entity.subcategory.name : '-'}</Td>
              <Td dataCy={'problemType'}>{entity.problemType ? entity.problemType.name : '-'}</Td>
            </Tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default TableTicketTemplate