import React from 'react'
import {TableContainer, Table, Tr, Th, Td, TdImage} from '../../common'

const TableTicketState = ({shownEntities, order, switchEnabled, ...rest}) => {
  return (
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th field='id' {...rest}>ID</Th>
          <Th field='name' {...rest}>Nome</Th>
          <Th field='sorting' {...rest}>Ordinamento</Th>
        </tr>
        </thead>
        <tbody data-cy={'states-table'}>
        {shownEntities && shownEntities
          .map(state =>
            <Tr key={state.id} data-cy={`states-row-${state.id}`}>
              {
                state.locked ? <Td/> :
                  <TdImage tooltip="Modifica stato" editPath={`/ticket-states/${state.id}`}/>
              }
              <Td dataCy={'id'}>{state.id || '-'}</Td>
              <Td dataCy={'name'}>{state.name || '-'}</Td>
              <Td dataCy={'sorting'}>{state.sorting || '-'}</Td>
            </Tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default TableTicketState