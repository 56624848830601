import React from 'react'
import styled from 'styled-components'
import imgArrow from '../../images/arrow-order-asc.svg'


const OrderArrow = ({ type }) => {
  return(
    <Arrow src={imgArrow} alt='arrow-order' type={type} />
  )
}

const Arrow = styled.img`
  width: 12px;
  margin-right: 8px;
  ${props => props.type === 'ASC' && 'transform: rotate(180deg);'}
`

export { OrderArrow }