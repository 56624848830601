import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import imgEdit from '../../images/edit.svg'
import imgEditDisabled from '../../images/edit-disabled.svg'
import ReactTooltip from 'react-tooltip'


const TdImage = (props) => {

  const {
    editPath,
    disabled,
    image = imgEdit,
    imageDisabled = imgEditDisabled,
    alt = 'edit',
    tooltip,
    onClick
  } = props

  return (
    <Td data-cy={`linkTd${editPath}`} data-tip data-for={tooltip}>
      { onClick
        ? (<Image src={disabled ? imageDisabled : image} alt={alt} onClick={onClick}/>)
        : (<LinkStyled to={editPath} disabled={disabled}>
            <Image src={disabled ? imageDisabled : image} alt={alt} />
          </LinkStyled>)
      }
      { tooltip &&
        <ReactTooltip id={tooltip} effect="solid" place="top" type="dark">{tooltip}</ReactTooltip>
      }
    </Td>
  )
}

/* region STYLE */


const LinkStyled = styled(Link)`
  ${props => props.disabled ? 'pointer-events: none;' : ''}
`

const Image = styled.img`
  width: 22px;
  cursor: pointer;
`

const Td = styled.td`
  padding: 6px;
  box-sizing: border-box;
  width: 34px;
`

/* endregion */

export {TdImage}