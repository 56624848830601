import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import posed from 'react-pose'
import {Td, TdImage, Tr} from '../../common'
import imgMore from '../../../images/more.svg'
import TableHistory from './TableHistory'
import imgLoader from '../../../images/loader.svg'
import axios from 'axios'
import {baseUrl} from '../../../constants/config'

const TicketRow = ({ticket, withDaysDiff, worklist}) => {

  const [expanded, setExpanded] = React.useState(false)
  const [history, setHistory] = React.useState(null)


  const getCreated = (created) => {
    const date = moment(created)
    const daysDiff = moment().diff(date, 'days')
    let result = `${date.format('DD-MM-YYYY HH:mm')}`
    if (withDaysDiff && ticket.state.id !== 2) result += ` - ${daysDiff}`
    return result
  }

  const onClickMore = (ticket) => {
    if (expanded) {
      setExpanded(false)
    } else {
      setExpanded(true)
      !history && getHistory(ticket.id).then(history => setHistory(history))
    }
  }

  const getHistory = async (ticketId) => {
    try {
      const token = localStorage.getItem('token')
      const {data} = await axios.get(`${baseUrl}/tickets/${ticketId}`, {headers: {'Authorization': `Bearer ${token}`}})
      return data.payload.history
    } catch (e) {
      console.log(e.response)
    }
  }

  return (
    <>
      <Tr expanded={expanded} data-cy={`tickets-row-${ticket.id}`}>
        <TdImage tooltip={`Modifica ticket ${ticket.id}`}
                 editPath={`/customers/${ticket.customer.id}/tickets/${ticket.id}`}/>
        <TdImage tooltip={`Mostra lo storico del ticket ${ticket.id}`} image={imgMore} alt="more"
                 onClick={() => onClickMore(ticket)}/>
        <Td dataCy={'id'}>{ticket.id || '-'}</Td>
        <Td dataCy={'priority'}>
          <Priority
            bgColor={ticket.priority && ticket.priority.backgroundColor}
            textColor={ticket.priority && ticket.priority.textColor ? '#FFFFFF' : '#000000'}
          >
            {ticket.priority ? ticket.priority.name : '-'}
          </Priority>
        </Td>
        <Td dataCy={'state'}>{ticket.state ? ticket.state.name : '-'}</Td>
        <Td dataCy={'created'}>{ticket.created ? getCreated(ticket.created) : '-'}</Td>
        <Td dataCy={'updated'}>{ticket.updated ? moment(ticket.updated).format('DD-MM-YYYY HH:mm') : '-'}</Td>
        <Td dataCy={'customer'}>
          {ticket.customer
            ? `${ticket.customer.name || ticket.customer.structureName} ${ticket.customer.surname || ticket.customer.businessName}`
            : '-'
          }
        </Td>
        <Td dataCy={'category'}>{ticket.category ? ticket.category.name : '-'}</Td>
        <Td dataCy={'subcategory'}>{ticket.subcategory ? ticket.subcategory.name : '-'}</Td>
        {!worklist &&
        <Td dataCy={'assignedTo'}>{ticket.user ? `${ticket.user.surname} ${ticket.user.name}` : '-'}</Td>}
        <Td dataCy={'problemType'}>{ticket.problemType ? ticket.problemType.name : '-'}</Td>
        {!worklist &&<Td dataCy={'company'}>{ticket.company===1 ? 'MSD' : 'Organon'}</Td>}
      </Tr>
      <Tr data-cy={`tickets-history-table-${ticket.id}`} withoutHover height={expanded ? '0px' : null}>
        <Details/>
        <Details/>
        <Details expanded={expanded} colSpan={8} pose={expanded ? 'open' : 'closed'}>
          {(history && expanded)
            ? <TableHistory history={history}/>
            : <Loader visible={expanded}><img src={imgLoader} alt="loader"/></Loader>
          }
        </Details>
        <Details/>
      </Tr>
    </>
  )
}

export default TicketRow


//region Style

const detailsProps = {
  closed: {height: '0px', padding: '0px', margin: '0px'},
  open: {height: '150px'}
}

const Details = styled(posed.td(detailsProps))`
  ${({expanded}) => expanded && 'background-color: #f8f8f8;'};
  ${({expanded}) => expanded && 'padding: 0 30px !important;'};
  ${({expanded}) => expanded && 'width: 100%;'};
  border-radius: 20px;
`

const Loader = styled.div`
  ${({visible}) => visible ? '' : 'display: none;'};
  width: 100%;
  text-align: center;
  & img {
    width: 50px;
    height: 50px;
    margin: auto;
  }
`

const Priority = styled.div`
  background: ${({bgColor}) => bgColor || 'transparent'} !important;
  color: ${({textColor}) => textColor};
  padding: 2px 10px;
  text-align: center;
  border-radius: 15px;
  font-size: 0.85rem;
`

//endregion