import {combineReducers} from 'redux'
import {
  CHANGE_TITLE, START_LOADING, STOP_LOADING,
  SET_REDIRECT, SET_FILTERS, RESET_FILTERS, SET_PREVIOUS_PATH
} from '../actions/types'

const title = (state = '', {type, payload}) => {
  switch (type) {
    case CHANGE_TITLE :
      return payload
    default :
      return state
  }
}

const filters = (state = new Map(), {type, payload}) => {
  switch (type) {
    case SET_FILTERS :
      return payload
    case RESET_FILTERS :
      return new Map()
    default :
      return state
  }
}

const loading = (state = false, {type}) => {
  switch (type) {
    case START_LOADING :
      return true
    case STOP_LOADING :
      return false
    default :
      return state
  }
}

const redirectTo = (state = '/', {type, payload}) => {
  switch (type) {
    case SET_REDIRECT :
      return payload
    default :
      return state
  }
}

const previousPath = (state = null, {type, payload}) => {
  switch (type) {
    case SET_PREVIOUS_PATH :
      return payload
    default :
      return state
  }
}

export default combineReducers({
  title,
  loading,
  redirectTo,
  filters,
  previousPath
})