import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import {useRouteMatch} from 'react-router'
import withPagination from '../../withPagination'
import Filters from './Filters'
import Table from './Table'
import useEntities from '../../../hooks/useEntities'
import {getPageFilters} from '../../../reducers'


const TablePaginated = withPagination(Filters, Table)


const PageCustomerHistory = () => {

  const match = useRouteMatch()
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()
  const [opened, setOpened] = useState([])
  const [closed, setClosed] = useState([])

  const idCustomer = match.params.customerID
  const options = {
    isNotPaginated: true,
    initialFilters: {
      ...filters,
      withFirstNote: true,
      customerId: parseInt(idCustomer),
      orderBy: "created",
      orderType: "desc"
    }
  }
  const {entities, total, get} = useEntities('tickets', options)
  const {entities: states} = useEntities('ticket-states', {isNotPaginated: true})
  const {entities: ticketType} = useEntities('ticket-types', {isNotPaginated: true})
  const {entities: priorities} = useEntities('ticket-priorities', {isNotPaginated: true})
  const {entities: categories} = useEntities('ticket-categories', {isNotPaginated: true})
  const {entities: subcategories} = useEntities('ticket-subcategories', {isNotPaginated: true})
  const {entities: problemTypes} = useEntities('ticket-problemtypes', {isNotPaginated: true})
  const {entities: customer} = useEntities('customers', {id: idCustomer})


  const [open, setOpen] = useState(false)

  useTitle((title) => dispatch(setTitle(title)), `Cronologia ticket del cliente ${customer.name || customer.businessName} ${customer.surname || customer.structureName}`)

  useEffect(() => {
    const o = []
    const c = []
    for (let ticket of entities) {
      if (ticket.state.id === 2) {
        c.push(ticket)
      } else {
        o.push(ticket)
      }
    }
    setOpened(o)
    setClosed(c)
  }, [entities])

  return (
    <TablePaginated
      entities={entities}
      opened={opened}
      closed={closed}
      total={total}
      get={get}
      idCustomer={idCustomer}
      states={states}
      ticketType={ticketType}
      priorities={priorities}
      categories={categories}
      subcategories={subcategories}
      problemTypes={problemTypes}
      initialFilters={options.initialFilters || null}
      open={open}
      setOpen={setOpen}
    />
  )
}

export default PageCustomerHistory