import React from 'react'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import { Form, Field } from 'react-final-form'
import { Modal, Input, ButtonBlu } from '../../common'
import imgAdd from '../../../images/ico-add.svg'


const ModalNewSubcategory = ({ isOpened, closeModal, categoryID, refreshData }) => {

  const { asyncCreate } = useEntities('ticket-subcategories', { isNotPaginated : true, withoutEffect : true })

  const saveSubcategory = async (subcategory) => {
    await asyncCreate({
      name: subcategory.name,
      category: { id: categoryID },
      enabled: true
    })
    closeModal()
    refreshData()
  }

  return(
    <Modal isOpened={isOpened} closeModal={closeModal} initWidth={500} initHeight={250}>
      <ModalContainer data-cy={'sub-category-modal'}>
        <ModalTitle>Crea una sottocategoria</ModalTitle>
        <ModalFormContainer>
          <Form
            onSubmit={saveSubcategory}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FilterBar>
                  <InputBox data-cy={'subcategory-search'}>
                    <Field
                      name="name"
                      component={Input}
                      type="text"
                      label="Nome"
                      autoFocus
                      required
                    />
                  </InputBox>
                  <ButtonBlu text='Salva' image={imgAdd} dataCy={'modal-add'} />
                </FilterBar>
              </form>
            )}
          />
        </ModalFormContainer>
      </ModalContainer>
    </Modal>
  )

}

/* region STYLE */

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
`

const ModalTitle = styled.div`
  font-size: 1.2rem;
  opacity: 0.7;
  font-weight: bold;
`

const ModalFormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  width: 100%;
`

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
  margin-bottom: 50px;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
`

/* endregion */

export default ModalNewSubcategory