import React from 'react'
import styled from 'styled-components'

const Card = ({ number, label, image, background, clickable }) => {
  return(
    <CardContainer background={background} clickable={clickable} >
      <CardNumber>{number}</CardNumber>
      <CardLabel>{label}</CardLabel>
      <CardImage src={image} alt="card-image" />
    </CardContainer>
  )
}

const CardContainer = styled.div`
  height: 230px;
  width: 370px;
  color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 15px 20px 15px 20px;
  border-bottom-right-radius: 50px;
  margin: 20px 30px;
  box-shadow: 2px 5px 13px -2px rgba(0,0,0,0.75);
  background: ${props => props.background ? props.background : '#218FC1'};
  cursor: ${props => props.clickable ? 'pointer' : 'initial'};
`

const CardNumber = styled.div`
  font-size: 3.5rem;
`

const CardLabel = styled.div`
  margin-top: 15px;
  font-size: 1.3rem;
  font-weight: bold;
  width: 250px;
`

const CardImage = styled.img`
  opacity: 0.5;
  width: 60px;
  position: absolute;
  bottom: 20px;
  right: 25px;
`

export default Card