import React from 'react'
import {Form, Field} from 'react-final-form'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import {Input} from './Input'
import {Select} from './Select'
import {ButtonGreen} from './ButtonGreen'
import imgSearch from '../../images/ico-search.svg'
import * as conf from '../../constants/config'


const Pagination = ({total, changePage, currentPage, itemsPerPage}) => {

  const pages = total / (itemsPerPage || conf.itemsPerPage)
  const page =
    (currentPage !== undefined || currentPage !== null)
      ? currentPage :
      null

  const onSubmit = values => {
    const paginationParameters = {
      selected: values['selected'] - 1,
      numberOfResults: values['itemsPerPage']
    }
    changePage(paginationParameters)
  }

  return (
    <PaginationContainer data-cy={'pagination-container'} display={total ? 'flex' : 'none'}>
      <ReactPaginate
        breakLabel={'...'}
        pageCount={pages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        containerClassName={'pagination'}
        previousClassName={'previous'}
        previousLabel={'precedente'}
        nextClassName={'next'}
        nextLabel={'successivo'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        breakClassName={'break-me'}
        onPageChange={changePage}
        forcePage={page}
      />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          itemsPerPage: itemsPerPage
        }}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <ButtonContainer data-cy={'button-container'}>
              <InputContainer data-cy={'pagination-page-number'}>
                <Field
                  label='N° pagina'
                  name="selected"
                  component={Input}
                  type="text"
                />
              </InputContainer>
              <InputContainer data-cy={'pagination-numberPerPages'}>
                <Field
                  label='N° per pagina'
                  name="itemsPerPage"
                  component={Select}
                  type="text"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </Field>
              </InputContainer>
              <ButtonGreen
                dataCy={'pagination-search'}
                text='Cerca'
                image={imgSearch}
              />
            </ButtonContainer>
          </form>
        )}
      />
    </PaginationContainer>
  )
}

/* region STYLE */

const PaginationContainer = styled.div`
  width: 100%;
  margin: 30px 0 0 0;
  box-sizing: border-box;
  display: ${props => props.display};
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
   @media(max-width: 1024px) {
    flex-direction: column;
    align-items: center;
}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: normal;
  @media(max-width: 1024px) {
    margin-top:10px;
}
`
const InputContainer = styled.div`
  width: 100px;
  margin-right: 10px;
`

/* endregion */

export {Pagination}