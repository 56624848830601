
const makeHeader = (responseType, params) => {
  const token = localStorage.getItem('token')
  const header = { headers: { Authorization: "Bearer " + token } }
  if (responseType) {
    header['responseType'] = responseType
  }
  if (params) {
    header.params = params
  }
  return header
}

export default makeHeader