import React from 'react'
import styled from 'styled-components'
import {TableContainer, Table, Tr, Th} from '../../common'
import TicketRow from './TicketRow'

const TicketRowMemo = React.memo(TicketRow)

const TableTickets = ({entities, order, switchEnabled, userIsAdmin, usedFilters, worklist, ...rest}) => {

  return (
    <TableContainer>
      {(usedFilters || entities.length > 0) &&
      <Table>
        <thead>
        <tr style={{background: '#e2e2e2'}}>
          <Th/>
          <Th {...rest}/>
          <Th {...rest} onClick={() => order('id')} field='id' dataCy={`title-id`}>ID</Th>
          <Th {...rest} onClick={() => order('priority')} field='priority' dataCy={`title-priority`}>Priorità</Th>
          <Th {...rest} onClick={() => order('state')} field='state' dataCy={`title-state`}>Stato</Th>
          <Th {...rest} onClick={() => order('created')} field='created' dataCy={`title-created`} capitalize={false}>Creato
            il</Th>
          <Th {...rest} onClick={() => order('updated')} field='updated' dataCy={`title-updated`} capitalize={false}>Modificato
            il</Th>
          <Th {...rest} >Cliente</Th>
          <Th {...rest} onClick={() => order('category')} field='category' dataCy={`title-category`}>Categoria</Th>
          <Th {...rest} onClick={() => order('subcategory')} field='subcategory'
              dataCy={`title-subcategory`}>Sottocategoria</Th>
          {!worklist && <Th dataCy={`title-assignedTo`}>Assegnato A</Th>}
          <Th {...rest} onClick={() => order('problemType')} field='problemType' dataCy={`title-problemType`}>Tipo di
            problema</Th>
          {!worklist && <Th {...rest} dataCy={`title-company`}>Azienda</Th>}
        </tr>
        </thead>
        <tbody data-cy={'tickets-table'}>
        {entities && entities.length === 0 &&
        <Tr withoutHover><NoRecords colSpan={12}>Nessun ticket</NoRecords></Tr>
        }
        {entities && entities.length > 0 && entities.map(ticket => (
          <TicketRowMemo key={ticket.id} ticket={ticket} withDaysDiff={rest.match.path !== '/tickets/all'}
                         worklist={worklist}/>
        ))}
        </tbody>
      </Table>
      }
      {
        !usedFilters && entities.length === 0 &&
        <NoTicket>
          Inserisci dei criteri di ricerca per iniziare
        </NoTicket>
      }
    </TableContainer>
  )
}

export default TableTickets

//region Style
const NoTicket = styled.div`
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.85;
  height: 200px;
`

const NoRecords = styled.td`
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.85;
  height: 200px;
`
//endregion