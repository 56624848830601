import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import Filters from './Filters'
import Table from './Table'
import withPagination from '../../withPagination'
import {setTitle} from '../../../actions'
import {useRouteMatch} from "react-router"
import {getPageFilters} from "../../../reducers"

const TablePaginated = withPagination(Filters, Table)

const TicketSubcategories = () => {
  const match = useRouteMatch()
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'Ticket - sottocategorie')
  const options = {initialFilters: filters || {}}

  const {entities, setEntities, total, get, asyncUpdate} = useEntities("ticket-subcategories", options)

  const switchEnabled = async (category) => {
    const action = category.enabled ? 'disable' : 'enable'
    await asyncUpdate({}, category.id, {action})
    setEntities(entities.map(c => {
      if (c.id === category.id)
        c.enabled = !c.enabled
      return c
    }))
  }

  return (
    <TablePaginated
      entities={entities}
      total={total}
      get={get}
      switchEnabled={switchEnabled}
    />
  )
}

export default TicketSubcategories
