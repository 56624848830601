import React from 'react'
import styled from 'styled-components'

const Button = (props) => {

  const {text, type, image, background, disabled, onClick, dataCy, minWidth, width, margin, marginText} = props

  const onClickButton = (e) => {
    !disabled && onClick && onClick(e)
  }

  return (
    <ButtonStyled
      data-cy={dataCy}
      background={background}
      disabled={disabled}
      type={type}
      width={width}
      minWidth={minWidth}
      margin={margin}
      onClick={(e) => onClickButton(e)}
    >
      <ButtonImage src={image} alt='image'/>
      <ButtonText
        marginText={marginText}
      >{text}</ButtonText>
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button`
  border: 0;
  font-size: 0.9rem; 
  min-width: ${props => props.minWidth ? props.minWidth : '100px'} ;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background: ${props => props.background ? props.background : '#1A6C9C'};
  color: #FFFFFF;
  padding: 8px 10px;
  margin: ${({margin}) => margin || '15px'};
  cursor: pointer;
  opacity: ${props => props.disabled ? '0.5' : '1'};
  ${({width}) => `width: ${width}`};
  
  &:disabled {
    cursor: not-allowed;
  }
     
  @media print {
    display:none;
  }
`

const ButtonImage = styled.img`
  width: 18px;
`

const ButtonText = styled.div`
  margin-left: 15px;
  ${({marginText}) => marginText && `margin: auto`};
`


export {Button}