import React, {useState} from 'react'
import {connect} from 'react-redux'
import {setTitle} from "../../../../actions"
import useTitle from "../../../../hooks/useTitle"
import useEntities from "../../../../hooks/useEntities"
import Report from './Table'
import Filters from './Filters'
import * as moment from "moment"
import Graph from "./Graph"

const ByGroup = ({setTitle}) => {

    useTitle(setTitle, 'Report - By Group (Yearly)')
    const years = () => {
        const dateArray = []

        let now = parseInt(moment().format('YYYY'))

        for (let y = now; y >= 2010; y--) {
            dateArray.push(y)
        }
        return dateArray
    }

    const {entities: groups} = useEntities('user-groups', {isNotPaginated: true})
    const [open, setOpen] = useState(false)
    const [keys, setKeys] = useState([])
    const [filteredGroups, setFilteredGroups] = useState(groups)

    const {entities: data, total, get} = useEntities("reports/by-groups-yearly", {
        isNotPaginated: true,
        initialFilters: {year: moment().year()}
    })
    const {entities: categories} = useEntities('ticket-categories', {isNotPaginated: true})
    const {entities: subcategories} = useEntities('ticket-subcategories', {isNotPaginated: true})
    const {entities: problemTypes} = useEntities('ticket-problemtypes', {isNotPaginated: true})
    const {entities: sources} = useEntities('ticket-sources', {isNotPaginated: true})

    return (
        <>
            <Filters
                data={data}
                open={open}
                years={years()}
                setFilteredGroups={setFilteredGroups}
                setOpen={setOpen}
                categories={categories}
                subcategories={subcategories}
                problemTypes={problemTypes}
                sources={sources}
                total={total}
                groups={groups}
                onSubmit={get}
                keys={keys}
            />
            <Graph
                keys={keys}
                reportRows={data}
            />
            <Report
                groups={filteredGroups.length > 0 ? filteredGroups : groups}
                keys={keys}
                setKeys={setKeys}
                data={data}
                total={total}
            />
        </>

    )
}

export default connect(null, {setTitle})(ByGroup)
