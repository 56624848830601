import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import {baseUrl} from '../constants/config'
import Template from './template/Template'
import * as p from '../constants/pages'
import {setLoginInformations, setRedirectTo, startLoading, stopLoading} from '../actions'
import {getUser} from '../reducers'
import withPermission from './withPermissions'
import queryString from 'query-string'


const App = () => {

  const [goToLogin, setGoToLogin] = useState(false)
  const [goToReset, setGoToReset] = useState(false)

  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    const tokenFromUrl = queryString.parse(window.location.search).token
    if (tokenFromUrl) return
    const token = localStorage.getItem('token') || null
    if (token) {
      dispatch(startLoading())
      axios.get(`${baseUrl}/login/whoami`, {headers: {Authorization: 'Bearer ' + token}})
        .then(({data}) => {
          dispatch(setLoginInformations(data.payload.user, token))
          if (data.payload.user.resetPassword) {
            setGoToReset(true)
          }
        })
        .catch(e => setGoToLogin(true))
        .finally(() => {
          setGoToLogin(false)
          setGoToReset(false)
        })
      dispatch(stopLoading())
    } else {
      if (window.location.pathname !== '/login')
        dispatch(setRedirectTo(window.location.pathname))
      setGoToLogin(true)
    }
  }, [])

  const TemplateWithPermissions = withPermission(Template)

  return (
    <>
      <BrowserRouter>
        <PageLayout>
          <Switch>
            <Route path="/login" exact component={p.Login}/>
            <Route path="/reset-password" exact component={p.ResetPassword}/>
            <TemplateWithPermissions user={user} path="/" exact component={p.Home}/>
            <TemplateWithPermissions user={user} path="/tickets/:customerID" exact component={p.Tickets}/>
            <TemplateWithPermissions user={user} path="/my-tickets" exact component={p.MyTickets}/>
            <TemplateWithPermissions user={user} path="/ticket-templates" exact component={p.TicketTemplates}/>
            <TemplateWithPermissions user={user} path="/ticket-templates/:ID" exact component={p.TicketTemplate}/>
            <TemplateWithPermissions user={user} path="/customers/:customerID/tickets" exact component={p.CustomerHistory}/>
            <TemplateWithPermissions user={user} path="/customers/:customerID/tickets/:ID" exact component={p.Ticket}/>
            <TemplateWithPermissions user={user} path="/customers" exact component={p.Customers}/>
            <TemplateWithPermissions user={user} path="/customers/:ID" exact component={p.Customer}/>
            <TemplateWithPermissions user={user} path="/customer-types" exact component={p.CustomerTypes}/>
            <TemplateWithPermissions user={user} path="/customer-types/:ID" exact component={p.CustomerType}/>
            <TemplateWithPermissions user={user} path="/address-types" exact component={p.AddressTypes}/>
            <TemplateWithPermissions user={user} path="/ticket-categories" exact component={p.TicketCategories}/>
            <TemplateWithPermissions user={user} path="/ticket-categories/:ID" exact component={p.TicketCategory}/>
            <TemplateWithPermissions user={user} path="/ticket-priorities" exact component={p.TicketPriorities}/>
            <TemplateWithPermissions user={user} path="/ticket-priorities/:ID" exact component={p.TicketPriority}/>
            <TemplateWithPermissions user={user} path="/ticket-problemtypes" exact component={p.TicketProblemTypes}/>
            <TemplateWithPermissions user={user} path="/ticket-problemtypes/:ID" exact component={p.TicketProblemType}/>
            <TemplateWithPermissions user={user} path="/ticket-sources" exact component={p.TicketSources}/>
            <TemplateWithPermissions user={user} path="/ticket-sources/:ID" exact component={p.TicketSource}/>
            <TemplateWithPermissions user={user} path="/ticket-states" exact component={p.TicketStates}/>
            <TemplateWithPermissions user={user} path="/ticket-states/:ID" exact component={p.TicketState}/>
            <TemplateWithPermissions user={user} path="/ticket-subcategories" exact component={p.TicketSubcategories}/>
            <TemplateWithPermissions user={user} path="/ticket-subcategories/:ID" exact component={p.TicketSubcategory}/>
            <TemplateWithPermissions user={user} path="/users" exact component={p.Users}/>
            <TemplateWithPermissions user={user} path="/users/:ID" exact component={p.User}/>
            <TemplateWithPermissions user={user} path="/user-groups" exact component={p.UserGroups}/>
            <TemplateWithPermissions user={user} path="/user-groups/:ID" exact component={p.UserGroup}/>
            <TemplateWithPermissions user={user} path="/user-roles" exact component={p.UserRoles}/>
            <TemplateWithPermissions user={user} path="/user-profile" exact component={p.Profile}/>
            <TemplateWithPermissions user={user} path="/reports" exact component={p.Reports}/>
            <TemplateWithPermissions user={user} path="/reports/by-group-yearly" exact component={p.ByGroup}/>
            <TemplateWithPermissions user={user} path="/reports/by-agent" exact component={p.ByAgent}/>
            <TemplateWithPermissions user={user} path="/reports/by-customer-type" exact component={p.ByCustomer}/>
            <TemplateWithPermissions user={user} path="/reports/by-subcategory" exact component={p.BySubcategory}/>
            <TemplateWithPermissions user={user} path="/reports/by-category" exact component={p.ByCategory}/>
            <TemplateWithPermissions user={user} path="/reports/by-source" exact component={p.BySource}/>
            <Template path="/" component={p.NotFound}/>
          </Switch>
        </PageLayout>
        {goToReset && <Redirect to="/reset-password"/>}
        {goToLogin && <Redirect to="/login"/>}
      </BrowserRouter>
    </>
  )
}

/* region STYLE */

const PageLayout = styled.div`
  font-family: Open Sans, sans-serif !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #ECECEC;
`

/* endregion */

export default App
