import React from 'react'
import {Table, TableContainer, Td, Th, Tr} from "../../../common"


const ByCustomerTable = ({data, dateArray}) => {

  return (
    <TableContainer paddingTop={'10px'}>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th dataCy={`title-customerType`} report> Tipologia Customer </Th>
          {
            dateArray && dateArray.map(date => <Th report key={date} dataCy={`title-${date}`}> {date} </Th>)
          }
          <Th dataCy={`title-total`} report> Totale </Th>
          <Th dataCy={`title-ticketRate`} report> % </Th>
        </tr>
        </thead>
        <tbody data-cy={'report-table'}>
        {
          data && data.map(reportRow => (
            <Tr key={reportRow['Tipologia Customer']} data-cy={`report-row-${reportRow['Tipologia Customer']}`}>
              <Td report/>
              <Td dataCy={'customerType'} report>{reportRow['Tipologia Customer'] || '-'}</Td>
              {
                dateArray && dateArray
                  .map(date =>
                    <Td key={`${reportRow['Tipologia Customer']}-${date}`}
                        dataCy={`${reportRow['Tipologia Customer']}-${date}`}>
                      {reportRow[date] || '0'}
                    </Td>)
              }
              <Td dataCy={`total`} report> {reportRow['Totale'] || '0'}  </Td>
              <Td dataCy={`total`} report> {reportRow['%'] || '-'}  </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  )


}

export default ByCustomerTable