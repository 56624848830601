import React, {useEffect, useState, useRef} from 'react'
import {EditorState, convertToRaw, ContentState} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import {ErrorMessage} from '../../common'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './new-ticket-rich-text-editor.css'
import styled from 'styled-components'

// Hooks version of the Class below (done by me)
const WYSIWYGEditor = ({input, meta, ...rest}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const refEditor = useRef(null)
  useEffect(() => {
    if (rest.note) {
      const contentBlock = htmlToDraft(rest.note)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
        setEditorState(editorState)
        refEditor.current.focusEditor()
      }
    }
  }, [])

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    if (value === '<p></p>\n') value = ''
    if (rest.onChange) {
      rest.onChange(value)
    }
    return input.onChange(value)
  }

  return (
    <React.Fragment>
      <CustomLabel>{rest.label}</CustomLabel>
      <Editor
        ref={refEditor}
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        onEditorStateChange={onEditorStateChange}
        stripPastedStyles
        wrapperStyle={{
          marginTop: 5
        }}
        toolbar={{
          options: ['inline', 'list', 'textAlign', 'colorPicker', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']
          },
          list: {
            options: ['unordered', 'ordered']
          }
        }}
        localization={{
          locale: 'it',
        }}
        editorStyle={{
          backgroundColor: '#F3F3F3',
          padding: '5px 10px',
          maxHeight: rest.small ? 400 : 250,
          overflow: 'auto',
          fontSize: rest.small ? '12px' : '14px'
        }}
      />
      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
    </React.Fragment>
  )
}

export default WYSIWYGEditor

//region Style

const CustomLabel = styled.label`
  opacity: 0.7;
  font-size: 0.95rem;
`

//endregion
