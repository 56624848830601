import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import {useRouteMatch} from "react-router"
import withPagination from "../../withPagination"
import Filters from './Filters'
import Table from './Table'
import useEntities from "../../../hooks/useEntities"
import {getPageFilters} from "../../../reducers"

const TablePaginated = withPagination(Filters, Table)


const PageTicketTemplates = () => {
  const match = useRouteMatch()
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'Ticket - template')
  const options = {initialFilters: filters || {}}

  const {entities, total, get} = useEntities('ticket-templates', options)

  const {entities: ticketType} = useEntities('ticket-types', {isNotPaginated: true})
  const {entities: priorities} = useEntities('ticket-priorities', {isNotPaginated: true})
  const {entities: categories} = useEntities('ticket-categories', {isNotPaginated: true})
  const {entities: subcategories} = useEntities('ticket-subcategories', {isNotPaginated: true})
  const {entities: problemTypes} = useEntities('ticket-problemtypes', {isNotPaginated: true})

  const [open, setOpen] = useState(false)


  return (
    <TablePaginated
      entities={entities}
      total={total}
      get={get}
      ticketType={ticketType}
      priorities={priorities}
      categories={categories}
      subcategories={subcategories}
      problemTypes={problemTypes}
      open={open}
      setOpen={setOpen}
    />
  )
}

export default PageTicketTemplates