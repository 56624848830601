import React from 'react'
import styled from 'styled-components'
import { TableContainer, Table, Tr, Th, Td, TdImage } from '../../common'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/remove.svg'

const TableTicketSubcategories = ({ entities, order, switchEnabled, ...rest }) => {
  return(
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th onClick={() => order('id')} field='id' dataCy={'title-id'} {...rest}>ID</Th>
          <Th onClick={() => order('name')} field='name' dataCy={'title-name'} {...rest}>Nome</Th>
          <Th onClick={() => order('categoryName')} field='categoryName' dataCy={'title-categoryName'} {...rest}>Categoria</Th>
          <Th {...rest} dataCy={'title-enable'} >Abilitata</Th>
        </tr>
        </thead>
        <tbody data-cy={'subcategories-table'}>
        { entities && entities.map( subcategory =>
          <Tr key={subcategory.id} data-cy={`subcategories-row-${subcategory.id}`}>
            <TdImage tooltip="Modifica sottocategoria" editPath={`/ticket-subcategories/${subcategory.id}`} />
            <Td dataCy={'id'} >{subcategory.id || '-'}</Td>
            <Td dataCy={'name'} >{subcategory.name || '-'}</Td>
            <Td dataCy={'category'} >{(subcategory.category && subcategory.category.name) || '-'}</Td>
            <Td>
              {subcategory.enabled
                ? <ImageButton data-cy={'item-enabled'} src={imgEnabled} onClick={() => switchEnabled(subcategory)} />
                : <ImageButton data-cy={'item-disabled'} src={imgDisabled} onClick={() => switchEnabled(subcategory)} />
              }
            </Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

//region STYLE

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

//endregion

export default TableTicketSubcategories
