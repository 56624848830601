import moment from 'moment'

const dateFields = ['fromDate', 'toDate']

const formatValues = values => {
  const filters = {}
  Object.entries(values).forEach(([key, value]) => {
    if (dateFields.includes(key)) {
      if (value) {
        filters[key] = moment(value).valueOf()
      } else {
        delete filters[key]
      }
    } else
      filters[key] = values[key]
  })
  return filters
}

export default formatValues
