import React, {useState, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'


const OpenedTickets = () => {

  const chartRef = useRef(null)

  const [stats, setStats] = useState({ mine: 0, total: 0 })
  const { call: getStats } = useEntities('', { withoutEffect: true, withoutNotifications: true })

  useLayoutEffect(() => {
    getStats('get', '/home/opened-tickets')
      .then(({ payload }) => {
        setStats(payload)
        const chart = am4core.create("opened-tickets", am4charts.PieChart)

        chart.data = [
          { category: 'assegnati', value: payload.mine },
          { category: 'totali', value: payload.total }
        ]
        const pieSeries = chart.series.push(new am4charts.PieSeries())
        pieSeries.dataFields.value = "value"
        pieSeries.dataFields.category = "category"
        pieSeries.labels.template.disabled = true
        pieSeries.ticks.template.disabled = true

        chartRef.current = chart
      })
      .catch(e => console.log(e))
  }, [])

  return (
    <Container>
      <Row>
        <Left>
          <Title>Ticket aperti questo mese</Title>
          <Row><Description>Assegnati</Description><Description>Totali</Description></Row>
          <Stats><Number>{stats.mine}</Number> <Separator>/</Separator> <Number>{ stats.total }</Number></Stats>
        </Left>
        <Right>
          <div id="opened-tickets" style={{ width: '350px', height: '140px' }}/>
        </Right>
      </Row>

    </Container>
  )
}

export default OpenedTickets

//region Style

const Container = styled.div`
  width: 600px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
`

const Title = styled.div`
  width: 100%;
  font-size: 1.2rem;
  opacity: 0.85;
  margin-bottom: 10px;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Description = styled.div`
  width: 45%;
  font-size: 1.1rem;
  opacity: 0.75;
  text-align: center;
`

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 10px;
`

const Number = styled.div`
  font-size: 1.4rem;
  opacity: 0.9;
`

const Separator = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`

const Left = styled.div`
  width: 250px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Right = styled.div`
  width: 350px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
//endregion