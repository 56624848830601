import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import { setTitle } from '../../../actions'
import FormUserGroup from './Form'


const UserGroup = ({ setTitle, match, history  }) => {

  const id = match.params.ID === 'new' ? null : match.params.ID
  const options = {
    id,
    redirect : history.push,
    withoutEffect : match.params.ID === 'new'
  }
  const {
    entities : userGroup,
    update,
    create,
    remove,
  } = useEntities("user-groups", options)

  useTitle(setTitle, id ? 'Dettaglio Gruppo di utenti' : 'Nuovo Gruppo di utenti')

  return(
    <PageContainer>
      <FormUserGroup
        entity={userGroup}
        action={id ? update : create}
        remove={remove}
      />
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

/* endregion */

export default withRouter(connect(null, { setTitle })(UserGroup))