import React from 'react'
import {Table, TableContainer, Td, Th, Tr} from "../../../common"

const ByAgentTable = ({data, dateArray}) => {

  return (
    <TableContainer paddingTop={'10px'}>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th dataCy={`title-agent`} report> User </Th>
          {
            dateArray && dateArray.map(date => <Th report key={date} dataCy={`title-${date}`}> {date} </Th>)
          }
          <Th dataCy={`title-total-opened`} report> Totale Aperti </Th>
          <Th dataCy={`title-total-closed`} report> Totale Chiusi </Th>
        </tr>
        </thead>
        <tbody data-cy={'report-table'}>
        {
          data && data.map(reportRow => (
            <Tr key={reportRow.Agent} data-cy={`report-row-${reportRow.Agent}`}>
              <Td report/>
              <Td dataCy={'name'} report>{reportRow.Agent || '-'}</Td>
              {
                dateArray && dateArray
                  .map(date =>
                    <Td key={`${reportRow.Agent}-${date}`} dataCy={`${reportRow.Agent}-${date}`}>
                      {reportRow[`${date}`] || '0'}
                    </Td>)
              }
              <Td dataCy={'total-opened'} report>{reportRow['Totale Aperti'] || '0'}</Td>
              <Td dataCy={'total-closed'} report>{reportRow['Totale Chiusi'] || '0'}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  )


}

export default ByAgentTable