import React from 'react'
import {Link} from 'react-router-dom'
import {Field, Form} from 'react-final-form'
import styled from 'styled-components'
import {ButtonBlu, ButtonGreen, ButtonRed, DatePicker, Input, Select} from '../../common'
import imgSearch from '../../../images/ico-search.svg'
import imgAdd from '../../../images/ico-add.svg'
import imgReset from "../../../images/resetFilters.svg"
import imgArrowUp from '../../../images/up-arrow.svg'
import imgArrowDown from "../../../images/down-arrow.svg"

const CustomerHistoryFilters = (props) => {

  const {
    onSubmit, states,
    priorities, categories,
    subcategories, problemTypes,
    ticketType, idCustomer,
    open, setOpen, filters
  } = props

  const getInitialValues = (filters) => {
    filters.fromDate = filters.fromDate ? new Date(filters.fromDate) : null
    filters.toDate = filters.toDate ? new Date(filters.toDate) : null
    return filters
  }

  const onSearch = (values) => {
    if (idCustomer) {
      values.customerId = idCustomer
      values.withHistory = true
    }
    const filters = {...values}
    filters.fromDate = values.fromDate
    filters.toDate = values.toDate


    onSubmit(filters)
    setOpen(false)
  }
  //region ShowFilters
  const openFilters = (e) => {
    e.preventDefault()
    setOpen(!open)
  }
  const resetFilters = (form) => {
    form.reset()
    onSearch({withHistory: true})
  }
  const disableResetFilters = (submitting, values) => {
    const usedFilter = Object.keys(values).filter(k => {
      if (k === 'customerId' || k === 'user' || k === 'withHistory') return false
      return !!values[k]
    })
    return !(submitting || usedFilter.length > 0)
  }
  const ShowFilters = (values) => {
    const usedFilters = Object.keys(values)
      .filter(k => !(k === 'fromDate' || k === 'toDate' || k === 'customerId' || k === 'withHistory'))
    return (
      <ShowFiltersButton
        data-cy={'show-filters'}
        background='#218FC1'
        onClick={openFilters}
      >
        <ButtonImage src={open ? imgArrowUp : imgArrowDown}/>
        {
          usedFilters.length > 0 &&
          <ValorizedFilter data-cy={'filters-notification'}/>
        }
      </ShowFiltersButton>
    )
  }
//endregion

  return (
    <Form
      onSubmit={onSearch}
      initialValues={getInitialValues(filters)}
      render={({handleSubmit, values, form, submitting}) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <FilterBar>
            {idCustomer &&
            <LinkStyled data-cy={'ticket-new'} to={`/customers/${idCustomer}/tickets/new`}>
              <ButtonGreen text='Nuovo' image={imgAdd}/>
            </LinkStyled>
            }
            <DateBox data-cy={'filters-from'}>
              <Field
                name="fromDate"
                component={DatePicker}
                type="text"
                label="Dal"
                maxDate={new Date()}
              />
            </DateBox>
            <DateBox data-cy={'filters-to'}>
              <Field
                name="toDate"
                component={DatePicker}
                type="text"
                label="Al"
                maxDate={new Date()}
              />
            </DateBox>
            <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
            <ButtonRed
              image={imgReset}
              dataCy={'filters-reset'}
              text={'Resetta'}
              type="button"
              onClick={() => resetFilters(form, values)}
              disabled={disableResetFilters(submitting, values)}
            />
            {
              ShowFilters(values)
            }
            {open &&
            <FilterContainer open={open}>
              <FilterRow>
                {!idCustomer &&
                <InputBox data-cy={'filters-customer'}>
                  <Field
                    name="customer"
                    component={Input}
                    type="text"
                    label="Cliente"
                  />
                </InputBox>
                }
                <InputBox data-cy={'filters-state'}>
                  <Field
                    name="states"
                    component={Select}
                    type="text"
                    label="Stato Ticket"
                  >
                    <option value={null}/>
                    {states && states.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-type'}>
                  <Field
                    name="type"
                    component={Select}
                    type="text"
                    label="Tipo"
                  >
                    <option value={null}/>
                    {ticketType && ticketType.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-priority'}>
                  <Field
                    name="priority"
                    component={Select}
                    type="text"
                    label="Priorità"
                  >
                    <option value={null}/>
                    {priorities && priorities.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-company'}>
                  <Field
                    name="company"
                    component={(props) => <Select {...props} loading={states.length === 0}/>}
                    type="text"
                    label="Azienda"
                  >
                    <option value={null}/>
                    <option value={1}>Msd</option>
                    <option value={2}>Organon</option>
                  </Field>
                </InputBox>
              </FilterRow>
              <FilterRow>
                <InputBox data-cy={'filters-category'}>
                  <Field
                    name="category"
                    component={Select}
                    type="text"
                    label="Categoria"
                  >
                    <option value={null}/>
                    {categories && categories.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-subcategory'}>
                  <Field
                    name="subcategory"
                    component={Select}
                    type="text"
                    label="Sottocategoria"
                  >
                    <option value={null}/>
                    {subcategories && subcategories.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-problemType'}>
                  <Field
                    name="problemType"
                    component={Select}
                    type="text"
                    label="Tipo di problema"
                  >
                    <option value={null}/>
                    {problemTypes && problemTypes.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
              </FilterRow>
            </FilterContainer>
            }
          </FilterBar>
        </form>
      )}
    />
  )
}

/* region STYLE */

const FilterBar = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        color: #000000 !important;
        margin-bottom: 50px;
        `

const InputBox = styled.div`
        margin: 20px;
        opacity: 0.9;
        `
const DateBox = styled.div`
        margin: 30px;
        `
const FilterContainer = styled.div`
        display: flex;
        height: ${props => props.open || '0px !important'};
        flex-direction: column;
        `
const FilterRow = styled.div`
        display: flex;
        flex-direction: row;
        `
const ShowFiltersButton = styled.button`
        position: relative;
        border: 0;
        font-size: 0.9rem;
        min-width: 35px;
        min-height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        background: ${props => props.background ? props.background : '#1A6C9C'};
        color: #FFFFFF;
        padding: 8px 10px;
        margin: 15px;
        cursor: pointer;
        `

const ButtonImage = styled.img`
        width: 18px;
        `
const ValorizedFilter = styled.span`
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        min-width: 7px !important;
        min-height: 7px !important;
        border-radius: 7px;
        `

const LinkStyled = styled(Link)`
        text-decoration: none;
        `

/* endregion */

export default CustomerHistoryFilters
