import {toast} from 'react-toastify'
import {NotificationContent} from '../components/template/NotificationContent'

export default (result, message) => {
  const option = {
    className: result === 'success' ? "notification-success" : "notification-error",
    closeOnClick: true,
    hideProgressBar: true,
    closeButton: false,
    autoClose: 3000,
    type: result === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR
  }
  return toast(NotificationContent(result, message), option)
}