import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import {useRouteMatch} from 'react-router'
import withPagination from '../../withPagination'
import Filters from './Filters'
import Table from './Table'
import useEntities from '../../../hooks/useEntities'
import {getPageFilters} from '../../../reducers'

const TablePaginated = withPagination(Filters, Table)


const PageTickets = () => {
  const initialLimit = 50
  const match = useRouteMatch()
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()


  useTitle((title) => dispatch(setTitle(title)), 'Cerca ticket')

  const options = {
    initialFilters: filters
      ? {
        orderBy: 'created',
        orderType: 'DESC',
        ...filters
      }
      : {
        orderBy: 'created',
        orderType: 'DESC',
      },
    withoutEffect: true
  }

  options.initialLimit = initialLimit

  const idCustomer = match.params.customerID === 'all' ? null : match.params.customerID
  if (idCustomer) {
    options.initialFilters.customerId = idCustomer
  }
  const {entities, setEntities, loading, total, get} = useEntities('tickets', options)
  const {entities: states} = useEntities('ticket-states', {withoutLoading: true, isNotPaginated: true})
  const {entities: ticketType} = useEntities('ticket-types', {withoutLoading: true, isNotPaginated: true})
  const {entities: priorities} = useEntities('ticket-priorities', {withoutLoading: true, isNotPaginated: true})
  const {entities: categories} = useEntities('ticket-categories', {
    withoutLoading: true, isNotPaginated: true,
    initialFilters: {
      withProblemTypes: true
    }
  })
  const {entities: subcategories} = useEntities('ticket-subcategories', {withoutLoading: true, isNotPaginated: true})
  const {entities: problemTypes} = useEntities('ticket-problemtypes', {withoutLoading: true, isNotPaginated: true})
  const {entities: users} = useEntities('users', {withoutLoading: true, isNotPaginated: true})

  const [open, setOpen] = useState(false)
  const [usedFilters, setUsedFilters] = useState(false)

  useEffect(() => {
    if (filters && Object.keys(filters).length !== 0) {
      get(filters)
    } else {
      const interval = setInterval(() => {
        if (!loading) {
          setEntities([])
          clearInterval(interval)
        }
      }, 10)
    }
  }, [filters])

  return (
    <TablePaginated
      match={match}
      entities={entities}
      total={total}
      get={get}
      idCustomer={idCustomer}
      states={states}
      ticketType={ticketType}
      priorities={priorities}
      categories={categories}
      subcategories={subcategories}
      problemTypes={problemTypes}
      users={users}
      initialFilters={options.initialFilters || null}
      open={open}
      setOpen={setOpen}
      usedFilters={usedFilters}
      setUsedFilters={setUsedFilters}
      initialLimit={initialLimit}
    />
  )
}

export default PageTickets