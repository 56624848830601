import React from 'react'
import {Field, Form} from 'react-final-form'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import {Input, ButtonRed, ButtonGreen, ErrorMessage} from '../../common'
import imgDelete from '../../../images/ico-delete.svg'
import imgAdd from '../../../images/ico-add.svg'
import {required} from '../../../helpers/formErrors'
import parseBooleanValues from "../../../helpers/parseBooleanValues";

const FormTicketSource = ({entity = {}, action, remove}) => {

  //const { entities : locations } = useEntities("locations", { isNotPaginated : true })

  const onSubmit = values => {
    values.items = []
    values.enabled = parseBooleanValues(values.enabled)
    action(values)
  }

  const onRemove = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cancellazione sorgente ticket',
      message: 'Vuoi davvero cancellare la sorgente? La cancellazione andrà a buon fine solo se nessun ticket è associato a questa sorgente',
      buttons: [
        {label: 'Si', onClick: () => remove()},
        {label: 'No'}
      ]
    })
  }

  const getInitialValues = (initialValues) => {
    if (initialValues.date) {
      initialValues.date = new Date(initialValues.date)
    }
    return initialValues
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={getInitialValues(entity)}
      validateOnBlur
      render={({handleSubmit}) => (
        <FormStyled onSubmit={handleSubmit}>
          <OrderContainer>
            <InputBox data-cy={'entity-name'}>
              <Field
                name="name"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Nome *"
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <ButtonContainer>
              <ButtonRed
                dataCy='delete-entity'
                text='Elimina'
                image={imgDelete}
                disabled={!entity.id}
                onClick={(e) => onRemove(e)}
              />
              <ButtonGreen
                dataCy='save-entity'
                text='Salva'
                image={imgAdd}
                type='submit'
              />
            </ButtonContainer>
          </OrderContainer>
        </FormStyled>
      )}
    />
  )
}

/* region STYLE */

const OrderContainer = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 400px;
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  @media(max-width: 768px) {
    width: 100%;
    min-height: 10%;
  }
`

const FormStyled = styled.form`
  height: 100%;
  @media(max-width: 768px) {
    width: 100%;
    height: unset;
  }
`

const InputBox = styled.div`
  margin: 15px 0;
  opacity: 0.9;
  width: 280px;
`

const ButtonContainer = styled.div`
  width: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

/* endregion */

export default FormTicketSource