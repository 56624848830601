import React from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import FormTicketCategory from './Form'
import TableSubcategories from './TableSubcategories'
import TableProblemTypes from './TableProblemTypes'


const TicketCategory = ({setTitle, match, history}) => {

  const id = match.params.ID === 'new' ? null : match.params.ID
  const options = {
    id,
    redirect: history.push,
    withoutEffect: match.params.ID === 'new'
  }
  const {
    entities: category,
    setEntities,
    getOne,
    update,
    create,
    remove,
  } = useEntities("ticket-categories", options)

  const {asyncUpdate: updateSubcategory} = useEntities('ticket-subcategories', {withoutEffect: true})
  const {asyncUpdate: updateProblemType} = useEntities('ticket-problemtypes', {withoutEffect: true})

  useTitle(setTitle, id ? 'Dettaglio categoria ticket' : 'Nuova categoria ticket')

  const switchEnabledSubcategory = async (subcategory) => {
    const action = subcategory.enabled ? 'disable' : 'enable'
    await updateSubcategory({}, subcategory.id, {action})
    const newCategory = {...category}
    newCategory.subcategories = newCategory.subcategories.map(s => {
      if (s.id === subcategory.id)
        s.enabled = !s.enabled
      return s
    })
    setEntities(newCategory)
  }

  const switchEnabledProblemType = async (problemType) => {
    const action = problemType.enabled ? 'disable' : 'enable'
    await updateProblemType({}, problemType.id, {action})
    const newCategory = {...category}
    newCategory.problemTypes = newCategory.problemTypes.map(p => {
      if (p.id === problemType.id)
        p.enabled = !p.enabled
      return p
    })
    setEntities(newCategory)
  }

  return (
    <PageContainer data-cy={'page-container'}>
      <FormTicketCategory
        entity={category}
        action={id ? update : create}
        remove={remove}
      />
      <SubtablesContainer data-cy={'sub-table'}>
        <TableSubcategories
          subcategories={category ? category.subcategories : []}
          categoryID={category.id}
          refreshData={getOne}
          disabled={!id}
          switchEnabled={switchEnabledSubcategory}
        />
        <TableProblemTypes
          problemTypes={category ? category.problemTypes : []}
          category={category}
          refreshData={getOne}
          disabled={!id}
          switchEnabled={switchEnabledProblemType}
        />
      </SubtablesContainer>

    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const SubtablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  @media(min-width: 1920px) {
     flex-direction: row;
  }
`

/* endregion */

export default withRouter(connect(null, {setTitle})(TicketCategory))