import React, {useLayoutEffect, useRef} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";


const ByCustomerGraph = ({reportRows}) => {

  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("by-customer-graph", am4charts.XYChart)

    const data = reportRows.filter(row => row['Tipologia Customer'] !== 'Totali').map(row => ({
      name: row['Tipologia Customer'],
      total: row['Totale']
    }))

    x.data = data

    let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.title.text = "Tipologia Customer"
    categoryAxis.dataFields.category = "name"
    categoryAxis.title.fontWeight = "bold"
    categoryAxis.renderer.grid.template.location = 0

    let valueAxis = x.yAxes.push(new am4charts.ValueAxis())
    valueAxis.tooltip.disabled = true
    valueAxis.title.text = "Totale"
    valueAxis.title.fontWeight = "bold"
    valueAxis.min = 0
    valueAxis.renderer.minWidth = 35

    let series = x.series.push(new am4charts.ColumnSeries())
    series.dataFields.categoryX = "name"
    series.dataFields.valueY = "total"
    series.tooltipText = "{valueY.value}"
    series.columns.template.fill = am4core.color("#273A7D")
    x.cursor = new am4charts.XYCursor()

    chart.current = x

    return () => {
      x.dispose()
    }
  }, [reportRows])

  return (
    <div id="by-customer-graph" style={{width: "100%", height: "500px"}}/>
  )


}

export default ByCustomerGraph