import React from 'react'
import styled from 'styled-components'


const ErrorMessage = ({ message, color }) => {
  return (
    <Message
      data-cy={'error'}
      color={color}
    >
      {message}
    </Message>
  )
}

const Message = styled.span`
  display: inline-block;
  height: 25px;
  color: ${props => props.color ? props.color : '#941212'};
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0px;
  font-weight: bold;
  text-align: center;
  font-size: 0.95rem;
`

export { ErrorMessage }