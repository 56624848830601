import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import {Autocomplete, ButtonBlu, ButtonGreen, ButtonRed, DatePicker, ErrorMessage, Input, Select} from '../../common'
import imgDelete from '../../../images/ico-delete.svg'
import imgAdd from '../../../images/ico-add.svg'
import {required} from '../../../helpers/formErrors'
import {Link} from 'react-router-dom'
import useEntities from '../../../hooks/useEntities'
import parseBooleanValues from '../../../helpers/parseBooleanValues'


const FormCustomer = (props) => {

  const {
    entity = {},
    action,
    remove,
    customerTypes,
    regions,
    id,
    userIsAdmin,
  } = props

  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [disabledField, SetDisabledField] = useState('')

  const {
    entities: provinces,
    setEntities: setProvinces,
    asyncGet: fetchProvinces
  } = useEntities('provinces', {withoutEffect: true, isNotPaginated: true})

  const {
    entities: cities,
    setEntities: setCities,
    asyncGet: fetchCities
  } = useEntities('cities', {withoutEffect: true, isNotPaginated: true})

  useEffect(() => {
    entity && entity.customerType && setSelectedType(entity.customerType.id)
  }, [entity])

  const onSubmit = values => {
    values.customerType = {id: values.customerTypeId}
    values.addressType = null
    values.birthdate = values.birth_date
      ? values.birth_date.getTime() : values.birthdate
    if (values.structureName) {
      if (values.businessName.indexOf('P.IVA ') !== 0) {
        values.businessName = 'P.IVA ' + values.businessName.replace('p.iva ', '')
      }
    }
    values.enabled = values.enabled === undefined ? true : parseBooleanValues(values.enabled)
    values.elite = parseInt(values.customerTypeId) === 13 ? parseBooleanValues(values.elite) : false

    action(values)
  }

  const oneRequired = ({name, surname, businessName, structureName}, field) => (value) => {
    const values = [name, surname, businessName, structureName]
    if (!value) {
      if (!values.find(val => val)) {
        SetDisabledField('')
        return `Almeno uno dei campi:  
      Nome, Cognome, Struttura, Ragione Sociale 
      deve essere valorizzato`
      }
    } else {
      if (field === 'name' || field === 'surname') {
        SetDisabledField('company')
      } else {
        SetDisabledField('person')
      }
    }
    if (field === 'name' || field === 'surname') {
      if (name && !surname)
        return 'Entrambi i campi nome e cognome devono essere valorizzati'
      if (surname && !name)
        return 'Entrambi i campi nome e cognome devono essere valorizzati'
    }
  }

  const onRemove = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cancellazione Customer',
      message: 'Vuoi davvero cancellare il customer? La cancellazione andrà a buon fine solo se nessun ticket è associato a questo customer',
      buttons: [
        {label: 'Si', onClick: () => remove()},
        {label: 'No'}
      ]
    })
  }
  const getInitialValues = (initialValues) => {
    initialValues.birth_date = initialValues.birthdate ? new Date(initialValues.birthdate) : new Date()
    initialValues.customerTypeId = initialValues.customerType ? initialValues.customerType.id : null
    return initialValues
  }

  //region Mutators

  const changeRegion = (args, state) => {
    const regionValue = state.formState.values.region
    const region = regions.find(r => r.name === regionValue)
    if (!region) return
    setSelectedRegion(region.id)
    setSelectedProvince(null)
    state.fields['province'].change(null)
    setSelectedCity(null)
    state.fields['city'].change(null)
    fetchProvinces({region: region.id})
  }

  const changeProvince = (args, state) => {
    const provinceValue = state.formState.values.province
    const province = provinces.find(p => p.name === provinceValue)
    if (!province) return
    if (!selectedRegion) {
      state.fields['region'].change(province.region.name)
    }
    setSelectedProvince(province)
    setSelectedCity(null)
    state.fields['city'].change(null)
    fetchCities({province: province.id})
  }

  const changeCity = (args, state) => {
    const cityValue = state.formState.values.city
    const city = cities.find(c => c.name === cityValue)
    if (!city) return
    if (!selectedRegion) {
      state.fields['region'].change(city.province.region.name)
    }
    if (!selectedProvince) {
      state.fields['province'].change(city.province.name)
    }
    setProvinces([])
    setSelectedCity(city)
  }

  const changeCustomerTypeId = (args, state) => {
    setSelectedType(parseInt(state.formState.values.customerTypeId))
  }


  //endregion

  const showStructureAndBusiness = (values) => {
    if (!values) return false
    return id === null || (values.structureName !== null || values.businessName !== null) || (!values.name && !values.surname)
  }
  const showNameAndSurname = (values) => {
    if (!values) return false
    return id === null || (values.name !== null || values.surname !== null) || (!values.structureName && !values.businessName)
  }
  // region Autocomplete

  const onSuggestionsFetchRequestedProvinces = ({value}) => {
    const filters = {name: value}
    if (selectedRegion) filters.region = selectedRegion
    return fetchProvinces(filters)
  }

  const onSuggestionsFetchRequestedCities = ({value}) => {
    const filters = {name: value}
    if (selectedProvince) filters.province = selectedProvince.id
    if (selectedRegion) filters.region = selectedRegion
    return fetchCities(filters)
  }

  //endregion
  return (
    <Form
      mutators={{
        changeRegion,
        changeProvince,
        changeCity,
        changeCustomerTypeId
      }}
      onSubmit={onSubmit}
      initialValues={getInitialValues(entity)}
      validateOnBlur
      render={({handleSubmit, form, values}) => (
        <FormStyled onSubmit={handleSubmit}>
          <Container>
            {
              showNameAndSurname(values)
              && <InputBox data-cy={'entity-name'}>
                <Field
                  name="name"
                  validate={oneRequired(values, 'name')}
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        type="text"
                        label="Nome *"
                        disabled={disabledField === 'person'}
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
            }
            {
              showNameAndSurname(values)
              && <InputBox data-cy={'entity-surname'}>
                <Field
                  name="surname"
                  validate={oneRequired(values, 'surname')}
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        type="text"
                        label="Cognome *"
                        disabled={disabledField === 'person'}
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
            }
            {
              showStructureAndBusiness(values)
              && <InputBox data-cy={'entity-structureName'}>
                <Field
                  name="businessName"
                  validate={oneRequired(values, 'structureName')}
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        type="text"
                        label="P.iva Struttura *"
                        disabled={disabledField === 'company'}
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
            }
            {
              showStructureAndBusiness(values)
              && <InputBox data-cy={'entity-businessName'}>
                <Field
                  name="structureName"
                  validate={oneRequired(values, 'businessName')}
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        type="text"
                        label="Ragione sociale *"
                        disabled={disabledField === 'company'}
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
            }
            {
              (id === null ||
                (entity.name !== null || entity.surname !== null))
              && <InputBox data-cy={'entity-birthDate'}>
                <Field
                  name="birth_date"
                  component={DatePicker}
                  type="text"
                  label="Data di nascita"
                />
              </InputBox>
            }
            <InputBox data-cy={'entity-email'}>
              <Field
                name="email"
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Email"
                      {...input}
                    />
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-fax'}>
              <Field
                name="fax"
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Fax"
                      {...input}
                    />
                  </>
                )}
              </Field>
            </InputBox>
          </Container>
          <Container>
            <InputBox data-cy={'entity-telephone'}>
              <Field
                name="phone"
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Telefono"
                      {...input}
                    />
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-mobile'}>
              <Field
                name="mobile"
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Cellulare"
                      {...input}
                    />
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-customerType'}>
              <Field
                name="customerTypeId"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Tipo *"
                      {...input}
                      onChange={(args) => {
                        input.onChange(args);
                        form.mutators.changeCustomerTypeId()
                      }}
                    >
                      <option value={null}/>
                      {customerTypes && customerTypes.map(type =>
                        <option key={type.id} value={type.id}>{type.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            {selectedType === 13 &&
            <InputBox data-cy={'entity-elite'}>
              <Field name="elite">
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Elite"
                      {...input}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Si</option>
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            }
            <InputBox data-cy={'entity-code'}>
              <Field
                name="code"
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Codice"
                      {...input}
                    />
                  </>
                )}
              </Field>
            </InputBox>
            {
              userIsAdmin &&
              <InputBox data-cy={'entity-enabled'}>
                <Field
                  name="enabled"
                  component={Select}
                  type="text"
                  label="Abilitato"
                >
                  <option value={true}>Si</option>
                  <option value={false}>No</option>
                </Field>
              </InputBox>
            }
          </Container>
          <Container>
            <InputBox data-cy={'entity-address'}>
              <Field
                name="address"
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Indirizzo"
                      {...input}
                    />
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-region'}>
              <Field
                name="region"
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Regione"
                      {...input}
                      onChange={(args) => {
                        input.onChange(args);
                        form.mutators.changeRegion()
                      }}
                    >
                      <option value={null}></option>
                      {regions && regions.map(region =>
                        <option key={region.id} value={region.name}>{region.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-province'}>
              <Field
                name="province"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Autocomplete
                      label="Provincia *"
                      suggestions={provinces || []}
                      setSuggestions={setProvinces}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequestedProvinces}
                      getSuggestionValue={s => s.name}
                      getSuggestionLabel={s => s.name}
                      onClick={form.mutators.changeProvince}
                      activateMutator={form.mutators.changeProvince}
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-city'}>
              <Field
                name="city"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Autocomplete
                      label="Città *"
                      suggestions={cities || []}
                      setSuggestions={setCities}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequestedCities}
                      getSuggestionValue={s => s.name}
                      getSuggestionLabel={s => s.name}
                      onClick={form.mutators.changeProvince}
                      activateMutator={form.mutators.changeCity}
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <ButtonContainer>
              <ButtonRed
                dataCy={'delete-entity'}
                text='Elimina'
                image={imgDelete}
                disabled={!entity.id}
                onClick={(e) => onRemove(e)}
              />
              <ButtonGreen
                dataCy={'save-entity'}
                text='Salva'
                image={imgAdd}
                type='submit'
              />

            </ButtonContainer>
            {entity.id &&
            <LinkStyled data-cy={'ticket-new'} to={`/customers/${entity.id}/tickets/new`}>
              <ButtonBlu text='Nuovo Ticket' image={imgAdd}/>
            </LinkStyled>
            }
          </Container>
        </FormStyled>
      )}
    />
  )
}

/* region STYLE */

const Container = styled.div`
              position: relative;
              background: #FFFFFF;
              width: 400px;
              min-height: 100%;
              padding: 20px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: nowrap;
              @media(max-width: 768px) {
              width: 100%;
              min-height: 10%;
              }
              `
const FormStyled = styled.form`
              min-height: 100%;
              display: flex;
              flex-direction: row;
              @media(max-width: 768px) {
              width: 100%;
              height: unset;
              }
              `

const InputBox = styled.div`
              margin: 15px 0;
              opacity: 0.9;
              width: 280px;
              `

const ButtonContainer = styled.div`
              width: 310px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;
              `
const LinkStyled = styled(Link)`
  text-decoration: none;
`
/* endregion */

export default FormCustomer