import imgHome from '../images/home.svg'
import imgSearchTickets from '../images/search-tickets.svg'
import imgCustomers from '../images/customers.svg'
import imgMyTickets from '../images/my-tickets.svg'
import imgTickets from '../images/tickets.svg'
import imgTemplates from '../images/template.svg'
import imgUsers from '../images/users.svg'
import imgTicketCategories from '../images/categories.svg'
import imgTicketSubcategories from '../images/subcategories.svg'
import imgTicketProblemTypes from '../images/problem-types.svg'
import imgTicketPriorities from '../images/priorities.svg'
import imgTicketSources from '../images/sources.svg'
import imgTicketStates from '../images/states.svg'
import imgUserGroups from '../images/groups.svg'
import imgUserRoles from '../images/roles.svg'
import imgUserCustomerTypes from '../images/customer-type.svg'
import imgReports from '../images/reports.svg'


const menuItems = [
  {
    label: 'Home',
    img: imgHome,
    link: '/',
    alt: 'home'
  },
  {
    label: 'I miei ticket',
    img: imgMyTickets,
    link: '/my-tickets',
    alt: 'ticket'
  },
  {
    label: 'Clienti',
    img: imgCustomers,
    link: '/customers',
    alt: 'customer'
  },
  {
    label: 'Cerca Ticket',
    img: imgSearchTickets,
    link: '/tickets/all',
    alt: 'tickets',
  },
  {
    label: 'Gestione ticket',
    img: imgTickets,
    link: '/tickets-submenu',
    alt: 'tickets',
    roles: [1],
    submenu: [
      {
        label: 'Categorie',
        img: imgTicketCategories,
        link: '/ticket-categories',
        alt: 'ticket categories',
        roles: [1]
      },
      {
        label: 'Sotto categorie',
        img: imgTicketSubcategories,
        link: '/ticket-subcategories',
        alt: 'ticket subcategories',
        roles: [1]
      },
      {
        label: 'Tipi di problema',
        img: imgTicketProblemTypes,
        link: '/ticket-problemtypes',
        alt: 'ticket problem types',
        roles: [1]
      },
      {
        label: 'Priorità',
        img: imgTicketPriorities,
        link: '/ticket-priorities',
        alt: 'ticket priorities',
        roles: [1]
      },
      {
        label: 'Sorgenti',
        img: imgTicketSources,
        link: '/ticket-sources',
        alt: 'ticket sources',
        roles: [1]
      },
      {
        label: 'Stati',
        img: imgTicketStates,
        link: '/ticket-states',
        alt: 'ticket states',
        roles: [1]
      },
      {
        label: 'Template',
        img: imgTemplates,
        link: '/ticket-templates',
        alt: 'ticket templates',
        roles: [1]
      },
    ]
  },
  {
    label: 'Gestione utenti',
    img: imgUsers,
    link: '/users-submenu',
    alt: 'users',
    roles: [1],
    submenu: [
      {
        label: 'Utenti',
        img: imgUsers,
        link: '/users',
        alt: 'users',
        roles: [1]
      },
      {
        label: 'Gruppi',
        img: imgUserGroups,
        link: '/user-groups',
        alt: 'user groups',
        roles: [1]
      },
      {
        label: 'Ruoli',
        img: imgUserRoles,
        link: '/user-roles',
        alt: 'user roles',
        roles: [1]
      }
    ]
  },
  {
    label: 'Tipi di cliente',
    img: imgUserCustomerTypes,
    link: '/customer-types',
    alt: 'customer types',
    roles: [1]
  },
  {
    label: 'Reports',
    img: imgReports,
    link: '/reports',
    alt: 'reports',
    roles: [1]
  },

]
export default menuItems