import React, {useState} from 'react'
import styled from 'styled-components'

const MenuButton = ({collapsed, setCollapsed}) => {

  const [active, setActive] = useState(false)

  const onClick = () => {
    setCollapsed(!collapsed)
    setActive(!active)
  }

  return (
    <StyledBurger
      close={collapsed}
      onClick={() => onClick()}
      data-cy={'close-menu'}
    >
      <span/>
      <span/>
      <span/>
    </StyledBurger>
  )
}

const StyledBurger = styled.button`
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  right:${({close}) => close ? '200px' : '10px'};
  transition:right 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  &:focus { outline: none; }
  z-index: 10;
  
  span {
    width: 1.5rem;
    height: 3px !important;
    background: #FFFFFFFF;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({close}) => !close ? 'rotate(45deg)' : 'rotate(0)'};
    }
    :nth-child(2) {
      opacity: ${({close}) => !close ? '0' : '1'};
      transform: ${({close}) => !close ? 'translateX(20px)' : 'translateX(0)'};
    }
    :nth-child(3) {
      transform: ${({close}) => !close ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`
export {MenuButton}