import React from 'react'
import {connect} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import Media from 'react-media'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import {getUser, getTitle} from '../../reducers'
import {logout} from '../../actions'
import imgLogo from '../../images/logo.png'
import imgSmallLogo from '../../images/logo-small.png'
import imgLogout from '../../images/btn-logout.svg'
import ImgGoBack from "../../images/goBack.svg"
import {useRouteMatch} from "react-router";


const mapStateToProps = state => ({
  user: getUser(state),
  title: getTitle(state)
})


const Topbar = ({user, title, history, logout, collapsed}) => {
  const match = useRouteMatch()

  return (
    <Media query={{maxWidth: 1024}}>
      {matches =>
        <TopbarContainer data-cy={'top-bar-container'}>
          <TopbarAboveSidebar collapsed={collapsed || matches}>
            {(collapsed || matches)
              ? (<LogoCollapsed src={imgSmallLogo} alt='logo'/>)
              : (<Logo src={imgLogo} alt='logo'/>)
            }
          </TopbarAboveSidebar>
          <TopbarAboveContainer collapsed={collapsed || matches}>
            {(!!match.params.ID || match.path === '/customers/:customerID/tickets') ? <GoBackButton
                data-cy={'show-filters'}
                background='#ECECEC'
                onClick={(e) => history.goBack()}
              >
                <ButtonImage src={ImgGoBack}/>
              </GoBackButton>
              :
              <NoButton></NoButton>
            }
            <Title data-cy={'top-bar-title'}>{title}</Title>
            <LoginInformationBox>
              <LoginWelcome data-cy={'top-bar-welcome'} to="/user-profile">
                <Username>{user.name} {user.surname}</Username>
                <Avatar>{user.name && user.name.charAt(0)} {user.surname && user.surname.charAt(0)}</Avatar>
              </LoginWelcome>
              <Logout data-cy={'top-bar-logout'}
                      data-tip
                      data-for="tooltip-logout"
                      src={imgLogout} alt='logout'
                      onClick={() => logout(history)}
                />
              <ReactTooltip id="tooltip-logout" effect="solid" place="left" type="dark">Logout</ReactTooltip>
            </LoginInformationBox>
          </TopbarAboveContainer>
        </TopbarContainer>
      }
    </Media>
  )
}

/* region STYLE */

const TopbarContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  background: inherit;
  color: #000000;
  box-sizing: border-box;
  border-bottom: 1px solid #BBBBBB;
`

const TopbarAboveSidebar = styled.div`
  width: ${props => props.collapsed ? '64px' : '250px'};
  transition:width 0.3s linear;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ECECEC;
`

const TopbarAboveContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: ${props => props.collapsed ? '64px' : '250px'};
  transition:left 0.3s linear;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width:  170px;
`
const LogoCollapsed = styled.img`
  width: 35px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  opacity: 0.7;
  margin-left: ${props => props.marginLeft ? props.marginLeft : '40px'};
`

const LoginInformationBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  padding: 0px 25px;
`

const LoginWelcome = styled(Link)`
  opacity: 0.8;
  font-size: 1.0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: inherit;
  text-decoration: none;

  &:hover {
     color: #6d6d6d;
     text-decoration:none;
     cursor:pointer;
  }
`

const Username = styled.div`
  display: inline-block;
  font-weight: bold;
  margin-left: 2px;
  text-transform: capitalize;
`

const Logout = styled.img`
  width: 30px;
  margin-left: 20px;
  transform: rotate(180deg);
  cursor: pointer;
`

const Avatar = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #d4d4d4;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 1.1rem;
  letter-spacing: -2px;
  padding: 5px;
  cursor: pointer;
  font-family: "Times New Roman", sans-serif;
  text-transform: uppercase;
  &:hover {
    background: #b3b3b3;
  }

`
const GoBackButton = styled.button`
        position: relative;
        border: 0;
        font-size: 0.9rem;
        min-width: 35px;
        min-height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        background: ${props => props.background ? props.background : '#1A6C9C'};
        color: #FFFFFF;
        padding: 8px 10px;
        margin: 15px;
        cursor: pointer;
        `
const ButtonImage = styled.img`
        width: 25px;
        `
const NoButton = styled.div`
        padding: 8px 10px;
        margin: 15px;
        width: 25px;
        height: 25px;
`
/* endregion */

export default withRouter(connect(mapStateToProps, {logout})(Topbar))
