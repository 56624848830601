import React from 'react'
import {Field, Form} from "react-final-form"
import {ButtonBlu, ButtonGreen, ButtonRed, DatePicker, Select} from "../../../common"
import imgSearch from "../../../../images/ico-search.svg"
import imgReset from "../../../../images/resetFilters.svg"
import styled from "styled-components"
import imgArrowUp from "../../../../images/up-arrow.svg"
import imgArrowDown from "../../../../images/down-arrow.svg"
import * as moment from "moment"
import imgExcel from "../../../../images/excel.svg"
import {createExcel} from "../../../../helpers/createExcel";

const BySubcategoryFilters = (props) => {
    const {
        setDates,
        onSubmit, dateArray,
        data, categories,
        sources, groups,
        setOpen, open
    } = props

    const getInitialValues = () => {
        return {
            opened: true
        }
    }

    const generateArray = (from, to) => {
        const dateArray = []

        let start, end

        from
            ? start = moment(from).startOf('month')
            : start = moment().subtract(1, 'month').startOf('month')
        to
            ? end = moment(to).endOf('month')
            : end = moment().endOf('month')

        while (end > start || start.format('M') === end.format('M')) {
            dateArray.push(start.format('MMM YYYY'))
            start.add(1, 'month')
        }

        setDates(dateArray)
    }
    const generateFilters = (values) => {
        const filters = {...values}
        if (values.from_Date) filters.from = moment(values.from_Date).valueOf()
        if (values.to_Date) filters.to = moment(values.to_Date).valueOf()

        filters.from_Date = null
        filters.to_Date = null

        return filters
    }
    const changeCategoryName = (record) => {
        return {
          ...record,
          Subcategory: `${record.Subcategory}${record.Category ? ' (' + record.Category + ')' : ''}`
        }
    }
    const saveXls = async () => {
        const header = ['Subcategory']
        dateArray.forEach(d => header.push(d))
        header.push('Totale')

        const blob = await createExcel('By Subcategory',
            data.map(changeCategoryName)
            , header)
        const a = document.createElement('a')
        a.style.display = 'none'
        document.body.appendChild(a)

        const url = window.URL.createObjectURL(blob)

        a.href = url
        a.download = 'By-subcategory.xlsx'

        a.click();

        window.URL.revokeObjectURL(url)

        if (a && a.parentElement) {
            a.parentElement.removeChild(a)
        }
    }

    const onSearch = (values) => {
        const filters = generateFilters(values)

        generateArray(values.from_Date, values.to_Date)

        onSubmit(filters)
        setOpen(false)
    }

    /** region Show filter */
    const resetFilters = (form) => {
        form.reset()
        onSubmit({})
        setOpen(false)
    }

    const openFilters = (e) => {
        e.preventDefault()
        setOpen(!open)
    }

    const ShowFilters = (values) => {
        const usedFilters = Object.keys(values)
            .filter(k => {
                if (k === 'from_Date' || k === 'to_Date') return false
                if (k === 'opened') return values[k] === 'false'
                return !!values[k]
            })
        return (
            <ShowFiltersButton
                data-cy={'show-filters'}
                background='#218FC1'
                onClick={openFilters}
            >
                <ButtonImage src={open ? imgArrowUp : imgArrowDown}/>
                {
                    usedFilters.length > 0 &&
                    <ValorizedFilter data-cy={'filters-notification'}/>
                }
            </ShowFiltersButton>
        )
    }
    const disableResetFilters = (submitting, values) => {
        const usedFilter = Object.keys(values).filter(k => {
            if (k === 'opened' && values[k] === true) return false
            return !!values[k]
        })
        return !(submitting || usedFilter.length > 0)
    }

    /** endregion */

    return (
        <Form
            onSubmit={onSearch}
            initialValues={getInitialValues}
            render={({handleSubmit, values, form, submitting}) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <FilterBar>
                        <DateBox data-cy={'filters-from'}>
                            <Field
                                name="from_Date"
                                component={DatePicker}
                                type="text"
                                label="Dal"
                                maxDate={new Date()}
                            />
                        </DateBox>
                        <DateBox data-cy={'filters-to'}>
                            <Field
                                name="to_Date"
                                component={DatePicker}
                                type="text"
                                label="Al"
                                maxDate={new Date()}
                            />
                        </DateBox>
                        <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
                        <ButtonGreen
                            dataCy='filters-excel' text='Esporta' image={imgExcel}
                            onClick={() => saveXls()}
                        />
                        <ButtonRed
                            image={imgReset}
                            dataCy={'filters-reset'}
                            text={'Resetta'}
                            type="button"
                            onClick={() => resetFilters(form)}
                            disabled={disableResetFilters(submitting, values)}
                        />
                        {
                            ShowFilters(values)
                        }
                        {open &&
                        <FilterContainer open={open}>
                            <FilterRow>
                                <InputBox data-cy={'filters-source'}>
                                    <Field
                                        name="source"
                                        component={Select}
                                        type="text"
                                        label="Sorgente"
                                    >
                                        <option value={null}/>
                                        {sources && sources.map(entity =>
                                            <option key={entity.id} value={entity.id}>{entity.name}</option>
                                        )}
                                    </Field>
                                </InputBox>
                                <InputBox data-cy={'filters-category'}>
                                    <Field
                                        name="category"
                                        component={Select}
                                        type="text"
                                        label="Categoria"
                                    >
                                        <option value={null}/>
                                        {categories && categories.map(entity =>
                                            <option key={entity.id} value={entity.id}>{entity.name}</option>
                                        )}
                                    </Field>
                                </InputBox>
                                <InputBox data-cy={'filters-groups'}>
                                    <Field
                                        name="groups"
                                        component={Select}
                                        type="text"
                                        label="Gruppo"
                                    >
                                        <option value={null}/>
                                        {groups && groups.map(entity =>
                                            <option key={entity.id} value={entity.id}>{entity.name}</option>
                                        )}
                                    </Field>
                                </InputBox>
                                <InputBox data-cy={'filters-status'}>
                                    <Field
                                        name="opened"
                                        component={Select}
                                        type="text"
                                        label="Status"
                                    >
                                        <option value={true}> Aperti</option>
                                        <option value={false}> Chiusi</option>
                                    </Field>
                                </InputBox>
                            </FilterRow>
                        </FilterContainer>
                        }
                    </FilterBar>
                </form>
            )}
        />
    )

}

/* region STYLE */
const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
  margin-bottom: 50px;
`
const ShowFiltersButton = styled.button`
        position: relative;
        border: 0;
        font-size: 0.9rem;
        min-width: 35px;
        min-height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        background: ${props => props.background ? props.background : '#1A6C9C'};
        color: #FFFFFF;
        padding: 8px 10px;
        margin: 15px;
        cursor: pointer;
        `
const ValorizedFilter = styled.span`
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        min-width: 7px !important;
        min-height: 7px !important;
        border-radius: 7px;
        `
const ButtonImage = styled.img`
        width: 18px;
        `
const InputBox = styled.div`
        margin: 20px;
        opacity: 0.9;
        min-width: 300px!important;
        `
const DateBox = styled.div`
        margin: 30px;
        min-width: 230px!important;
        `
const FilterContainer = styled.div`
        display: flex;
        height: ${props => props.open || '0px !important'};
        flex-direction: column;
        `
const FilterRow = styled.div`
        display: flex;
        flex-direction: row;
        `
/* endregion */

export default BySubcategoryFilters
