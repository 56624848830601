import React, {useState} from 'react'
import styled from 'styled-components'
import useFilters from '../hooks/useFilters'
import {Pagination} from './common'
import {useLocation} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {getPageFilters} from '../reducers'


const withPagination = (Filters, Table) => {

  const Wrapper = (props) => {

    const dispatch = useDispatch()

    const {
      entities, setShownEntities,
      total, get, isLocal, userIsAdmin,
      initialLimit = false,
      initialFilters = {}, ...rest
    } = props

    const pathname = useLocation().pathname


    const [filters, setFilter] = useFilters()
    const [currentPage, setCurrentPage] = useState(null)
    const [itemsPerPage, setItemsPerPage] = useState(initialLimit | null)
    const [orderName, setOrderName] = useState(null)
    const [orderType, setOrderType] = useState('ASC')


    const currentFilters = useSelector((state) => getPageFilters(state, pathname))

    const onSubmit = values => {
      dispatch(setFilter(pathname, values))
      isLocal
        ? search(values)
        : get(values, 1, itemsPerPage)
    }

    const search = (values) => {
      let newEntities = [...entities]
      Object.entries(values).forEach(([key, value]) => {
        if (value && typeof value === 'string') {
          newEntities = newEntities.filter(e => e[key].toLowerCase().includes(value.toLowerCase()))
        }
      })
      setShownEntities(newEntities)
    }

    const order = orderBy => {
      const newFilter = {...currentFilters, ...initialFilters, orderBy, orderType}
      orderType === 'ASC' ? setOrderType('DESC') : setOrderType('ASC')
      dispatch(setFilter(pathname, newFilter))
      setOrderName(orderBy)
      get(newFilter, 1, itemsPerPage)
    }

    const changePage = ({selected, numberOfResults}) => {
      const allFilters = {...currentFilters, ...initialFilters}
      let page = 1
      // Page selected
      if (isNaN(selected) || selected < 0) {
        setCurrentPage(0)
      } else {
        page = parseInt(selected) + 1
        setCurrentPage(selected)
      }
      // No Items per page
      if (!numberOfResults && !itemsPerPage) {

        get(allFilters, page)
      } else if (numberOfResults) {

        // I've just change the items per page
        setItemsPerPage(numberOfResults)
        get(allFilters, page, numberOfResults)
      } else {

        // The items per page is sets in the state
        get(allFilters, page, itemsPerPage)
      }
    }

    return (
      <PageContainer>
        <Filters
          entities={entities}
          userIsAdmin={userIsAdmin}
          onSubmit={onSubmit}
          filters={filters.get(pathname) || {}}
          initialLimit={initialLimit || 25}
          total={total}
          {...rest}
        />
        <Table
          entities={entities}
          order={order}
          orderName={orderName}
          orderType={orderType}
          {...rest}
          userIsAdmin={userIsAdmin}
        />
        <Pagination
          total={total}
          changePage={changePage}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </PageContainer>
    )
  }

  return Wrapper
}

/* region STYLE */

const PageContainer = styled.div`
  margin: 10px 40px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
`

/* endregion */

export default withPagination
