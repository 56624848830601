import React, {useState} from 'react'
import {Field, Form} from "react-final-form"
import {ButtonBlu, ButtonGreen, ButtonRed, Select} from "../../../common"
import imgSearch from "../../../../images/ico-search.svg"
import imgReset from "../../../../images/resetFilters.svg"
import styled from "styled-components"
import imgArrowUp from "../../../../images/up-arrow.svg"
import imgArrowDown from "../../../../images/down-arrow.svg"
import imgExcel from "../../../../images/excel.svg"
import {createExcel} from "../../../../helpers/excel/report-by-group"
import moment from "moment";

const ByGroupFilters = (props) => {
  const {
    onSubmit,
    categories, subcategories, groups,
    problemTypes, sources, keys, setFilteredGroups,
    setOpen, open, data, years
  } = props

  const [year, setYear] = useState(moment().format('YYYY'))

  const generateArray = (groupId) => {
    const filteredGroups = groups.filter(({id}) => id === parseInt(groupId))

    setFilteredGroups(filteredGroups)
  }

  const onSearch = (values) => {

    generateArray(values.group)

    setYear(values.year)

    onSubmit(values)
    setOpen(false)
  }

  const saveXls = async () => {
    const blob = await createExcel('By Group Yearly', data, keys, year)
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)

    const url = window.URL.createObjectURL(blob)

    a.href = url
    a.download = 'By-group(Yearly).xlsx'

    a.click()

    window.URL.revokeObjectURL(url)

    if (a && a.parentElement) {
      a.parentElement.removeChild(a)
    }
  }

  /** region Show filter */
  const resetFilters = (form) => {
    form.reset()
    onSubmit({})
    setOpen(false)
  }

  const openFilters = (e) => {
    e.preventDefault()
    setOpen(!open)
  }

  const ShowFilters = (values) => {
    const usedFilters = Object.keys(values)
      .filter(k => {
        if (k === 'from_Date' || k === 'to_Date' || k === 'year') return false
        return !!values[k]
      })
    return (
      <ShowFiltersButton
        data-cy={'show-filters'}
        background='#218FC1'
        onClick={openFilters}
      >
        <ButtonImage src={open ? imgArrowUp : imgArrowDown}/>
        {
          usedFilters.length > 0 &&
          <ValorizedFilter data-cy={'filters-notification'}/>
        }
      </ShowFiltersButton>
    )
  }

  const disableResetFilters = (submitting, values) => {
    const usedFilter = Object.keys(values).filter(k => {
      return !!values[k]
    })
    return !(submitting || usedFilter.length > 0)
  }

  /** endregion */

  return (
    <Form
      onSubmit={onSearch}
      render={({handleSubmit, values, form, submitting}) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <FilterBar>
            <InputTitleBox data-cy={'filters-year'}>
              <Field
                name="year"
                component={Select}
                type="text"
                label="Anno"
              >
                {years && years.map(year =>
                  <option key={year} value={year}>{year}</option>
                )}
              </Field>
            </InputTitleBox>
            <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
            <ButtonGreen
              dataCy='filters-excel' text='Esporta' image={imgExcel}
              onClick={() => {
                saveXls()
              }}
            />
            <ButtonRed
              image={imgReset}
              dataCy={'filters-reset'}
              text={'Resetta'}
              type="button"
              onClick={() => resetFilters(form)}
              disabled={disableResetFilters(submitting, values)}
            />
            {
              ShowFilters(values)
            }
            {open &&
            <FilterContainer open={open}>
              <FilterRow>
                <InputBox data-cy={'filters-groups'}>
                  <Field
                    name="group"
                    component={Select}
                    type="text"
                    label="Gruppo"
                  >
                    <option value={null}/>
                    {groups && groups.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-source'}>
                  <Field
                    name="source"
                    component={Select}
                    type="text"
                    label="Sorgente"
                  >
                    <option value={null}/>
                    {sources && sources.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
              </FilterRow>
              <FilterRow>
                <InputBox data-cy={'filters-category'}>
                  <Field
                    name="category"
                    component={Select}
                    type="text"
                    label="Categoria"
                  >
                    <option value={null}/>
                    {categories && categories.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-subcategory'}>
                  <Field
                    name="subcategory"
                    component={Select}
                    type="text"
                    label="Sottocategoria"
                  >
                    <option value={null}/>
                    {subcategories && subcategories.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-problemType'}>
                  <Field
                    name="problemType"
                    component={Select}
                    type="text"
                    label="Tipo di problema"
                  >
                    <option value={null}/>
                    {problemTypes && problemTypes.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
              </FilterRow>
            </FilterContainer>
            }
          </FilterBar>
        </form>
      )}
    />
  )

}

/* region STYLE */
const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
  margin-bottom: 50px;
`
const InputBox = styled.div`
  margin: 20px;
  min-width: 300px!important;
  opacity: 0.9;
`
const InputTitleBox = styled.div`
  margin: 20px;
   min-width: 150px!important;
  opacity: 0.9;
`
const ShowFiltersButton = styled.button`
        position: relative;
        border: 0;
        font-size: 0.9rem;
        min-width: 35px;
        min-height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        background: ${props => props.background ? props.background : '#1A6C9C'};
        color: #FFFFFF;
        padding: 8px 10px;
        margin: 15px;
        cursor: pointer;
        `
const ValorizedFilter = styled.span`
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        min-width: 7px !important;
        min-height: 7px !important;
        border-radius: 7px;
        `
const ButtonImage = styled.img`
        width: 18px;
        `
const FilterContainer = styled.div`
        display: flex;
        height: ${props => props.open || '0px !important'};
        flex-direction: column;
        `
const FilterRow = styled.div`
        display: flex;
        flex-direction: row;
        `
/* endregion */

export default ByGroupFilters
