import React from 'react'
import styled from 'styled-components'
import {Field, Form} from 'react-final-form'
import DropZone from 'react-dropzone'
import useEntities from '../../../hooks/useEntities'
import {ButtonGreen, ButtonRed, ErrorMessage, Modal, Select} from '../../common'
import imgAdd from '../../../images/ico-add.svg'
import imgRemove from '../../../images/ico-delete.svg'
import {required} from '../../../helpers/formErrors'
import RichTextEditor from './RichTextEditor'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {useSelector} from 'react-redux'
import {getUser} from '../../../reducers'

const ModalNewState = ({isOpened, closeModal, entity, states, users, refreshData, note, setNote}) => {

  const user = useSelector(getUser)
  const {call: addHistory} = useEntities('', {withoutEffect: true})

  //region Form
  const onSubmit = async (values, redirect) => {
    const toSubmit = new FormData()
    toSubmit.append('state', values.stateId)
    if (values.assignToId)
      toSubmit.append('assignTo', values.assignToId)
    if (values.note)
      toSubmit.append('note', values.note)
    if (values.attachments && values.attachments.length > 0)
      values.attachments.forEach(f => toSubmit.append('attachments', f))
    try {
      await addHistory('put', `/tickets/${entity.id}/history`, toSubmit)
      refreshData()
      setNote('')
      closeModal()
    } catch (e) {
      console.log(e)
      if (redirect) throw e
    }
  }
  const getInitialValues = (initialValues) => {
    const values = initialValues
    values.assignToId = initialValues.user ? initialValues.user.id : null
    values.stateId = initialValues.stateId ? initialValues.stateId : 1
    values.attachments = []
    values.note = note
    values.assignToId = user.id
    return values
  }
  const submitAndRedirect = async (values, url) => {
    let error
    try {
      await onSubmit(values, true)
    } catch (e) {
      error = e
    }
    if (error) return
    window.location.href = url
  }

  const changeAssignedTo = (args, state, {changeValue}) => {
    const stateId = state.formState.values.stateId
    if (stateId && parseInt(stateId) === 3)
      if (entity.user.id)
        state.fields['assignToId'].change(entity.user.id)
      else
        state.fields['assignToId'].change(user.id)

    else
      state.fields['assignToId'].change(user.id)

  }
  //endregion
  //region Drag & Drop
  const humanFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  const onDrop = (args, state, {changeValue}) => (files) => {
    state.fields['attachments'].blur()
    if (files && files.length > 0) {
      const attachments = state.formState.values['attachments']
      files.forEach(f => attachments.push(f))
      state.fields['attachments'].change(attachments)
      state.fields['attachments'].focus()
    }
  }

  const removeFile = (args, state, {changeValue}) => (filename, number) => {
    const attachments = state.formState.values['attachments'].filter((a, index) =>
      !(a.name === filename && number === index)
    )
    state.fields['attachments'].change(attachments)
  }
  //endregion

  return (
    <Modal
      isOpened={isOpened}
      closeModal={closeModal}
      initWidth={700}
      initHeight={750}
    >
      <ModalContainer data-cy={'modal-history'}>
        <ModalTitle>Nuovo Stato Ticket</ModalTitle>
        <ModalFormContainer>
          <Form
            mutators={{
              onDrop,
              removeFile,
              changeAssignedTo
            }}
            onSubmit={onSubmit}
            initialValues={getInitialValues(entity)}
            render={({handleSubmit, values, form}) => (
              <ModalFormContainer>
                <InputRow>
                  <InputBox data-cy={'entity-state'}>
                    <Field
                      name="stateId"
                      validate={required}
                    >
                      {({input, meta}) => (
                        <>
                          <Select
                            disabled={!entity.id}
                            type="text"
                            label="Stato ticket"
                            onClick={() => form.mutators.changeAssignedTo()}
                            {...input}
                          >
                            <option>{null}</option>
                            {states && states.map(entity =>
                              <option key={entity.id} value={entity.id}>{entity.name}</option>
                            )}
                          </Select>
                          {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                        </>
                      )}
                    </Field>
                  </InputBox>
                  <InputBox data-cy={'entity-assignTo'}>
                    <Field
                      name="assignToId"
                      validate={required}
                    >
                      {({input, meta}) => (
                        <>
                          <Select
                            type="text"
                            label="Assegna a"
                            {...input}
                          >
                            <option>{null}</option>
                            {users && users.map(entity =>
                              <option key={entity.id} value={entity.id}>{`${entity.surname} ${entity.name}`}</option>
                            )}
                          </Select>
                          {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                        </>
                      )}
                    </Field>
                  </InputBox>
                </InputRow>
                <RichTextEditorContainer width="650px" data-cy={'entity-note'}>
                  <Field
                    name="note"
                    component={RichTextEditor}
                    validate={required}
                    onChange={setNote}
                    note={note}
                  />
                </RichTextEditorContainer>
                <FileInput width="650px" data-cy={'entity-file'}>
                  <Field
                    name="attachments"
                  >
                    {({input, meta}) => (
                      <div key={"attachments"}>
                        <DropZone onDrop={(file) => form.mutators.onDrop()(file)}
                                  maxSize={10485760}
                                  multiple
                        >
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <DragTitle>Allega file</DragTitle>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <DragAndDrop data-cy={'DragAndDrop'}>
                                  Clicca o trascina qui un file per caricare un allegato
                                </DragAndDrop>
                              </div>
                            </section>
                          )}
                        </DropZone>
                        {
                          values.attachments && values.attachments.length > 0 ? values.attachments.map((entity, index) => (
                              <InfoContainer key={entity.name}>
                                <ButtonRed onClick={() => form.mutators.removeFile()(entity.name, index)}
                                           dataCy={'remove-file'}
                                           text='Rimuovi allegato'
                                           image={imgRemove}/>
                                <FileInfo data-cy={'file-info'}>
                                  Nome file : {entity.name ? entity.name : ''}
                                  <br/>
                                  Dimensione : {entity.size ? humanFileSize(entity.size) : ''}
                                </FileInfo>
                              </InfoContainer>
                            )) :
                            <NoInfo>

                            </NoInfo>
                        }
                      </div>
                    )}
                  </Field>
                </FileInput>
                <ButtonGreen
                  dataCy={'save-entity-my-tickets'}
                  text='Salva e vai ai miei Tickets'
                  image={imgAdd}
                  marginText
                  minWidth={'242px!important'}
                  onClick={(e) => submitAndRedirect(values, '/my-tickets')}
                />
                <ButtonGreen
                  dataCy={'save-entity-new-ticket'}
                  text='Salva e Crea un nuovo Ticket'
                  image={imgAdd}
                  marginText
                  minWidth={'242px!important'}
                  onClick={(e) => submitAndRedirect(values, `/customers/${entity.customer.id}/tickets/new`)}
                />
                <ButtonGreen
                  dataCy={'save-history'}
                  text='Salva'
                  image={imgAdd}
                  marginText
                  minWidth={'242px!important'}
                  type='submit'
                  onClick={handleSubmit}
                />
              </ModalFormContainer>
            )}
          />
        </ModalFormContainer>
      </ModalContainer>
    </Modal>
  )
}

/* region STYLE */

const ModalContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
`

const ModalTitle = styled.div`
  font-size: 1.2rem;
  opacity: 0.7;
  font-weight: bold;
`

const ModalFormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  width: 100%;
`

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
  ${({width}) => `width: ${width}`};
`

const RichTextEditorContainer = styled.div`
  margin: 20px;
  opacity: 0.9;
  ${({width}) => `width: ${width}`};
  z-index: 100;
`

const FileInput = styled.div`
  margin: 10px;
  opacity: 0.9;
  ${({width}) => `width: ${width}`};
`

const DragAndDrop = styled.section`
  background: #ECECEC;
  padding: 5px;
  margin-top: 15px;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #BBBBBB;
  text-align: -webkit-center;
  min-height: 45px;
  min-width: 380px;
`
const DragTitle = styled.div`
  opacity: 0.7;
  font-size: 0.95rem;
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
`

const FileInfo = styled.div`
  opacity: 0.7;
  margin: 15px;
  font-size: 0.95rem;
`
const NoInfo = styled.div`
  min-height: 64px; 
`
/* endregion */

export default ModalNewState
