import React, {useState} from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import FormUser from './Form'
import {getUser} from "../../../reducers"

const mapStateToProps = state => ({
  user: getUser(state)
})


const User = ({setTitle, match, history, user: me}) => {


  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const id = match.params.ID === 'new' ? null : match.params.ID

  useTitle(setTitle, id ? 'Dettaglio utente' : 'Nuovo utente')
  const options = {
    id,
    redirect: history.push,
    withoutEffect: match.params.ID === 'new'
  }
  const {
    entities: user,
    update,
    create,
    remove,
    asyncUpdate,
    setEntities: setUser
  } = useEntities('users', options)
  const {entities: groups} = useEntities('user-groups')
  const {entities: roles} = useEntities('user-roles')

  const resetPassword = () => {
    asyncUpdate({ reset: !user.resetPassword }, user.id, { action: 'reset-password', throwError: true })
      .then(() => {
        setUser({...user, resetPassword: !user.resetPassword})
      })
      .catch(e => {
        console.log(e.response)
        if (e.response && e.response.data && e.response.data.message && e.response.data.message.includes('Password cannot be')) {
          alert("Devono passare almeno 24 dall'ultimo cambiamento di password")
        }
      })
  }

  return (
    <PageContainer>
      <FormUser
        entity={user}
        action={id ? update : create}
        groups={groups}
        roles={roles}
        remove={remove}
        setShowDisclaimer={setShowDisclaimer}
        userIsAdmin={me.role.isAdmin}
        resetPassword={resetPassword}
      />
      {showDisclaimer &&
      <Disclaimer data-cy={'password-disclaimer'}>
        <Title>Policy per le password</Title>
        <Content>
          La password deve essere lunga almeno 8 caratteri, e soddisfare almeno 3 su 4 di questi requisiti:
          <ul>
            <li>Carattere maiuscolo</li>
            <li>Carattere minuscolo</li>
            <li>Carattere numerico</li>
            <li>Caratteri speciali (!#$%^&*?)</li>
          </ul>
        </Content>
      </Disclaimer>
      }
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const Disclaimer = styled.div`
  padding: 20px;
  font-size: 1.2rem;
  color: #474747;
  width:260px;
  align-self: flex-end;
  @media (max-width: 768px) {
    padding: 50px;
  }
`

const Title = styled.div`
  font-size: 1.4rem;
  color: #474747;
  margin-bottom: 10px;
`

const Content = styled.div`
  font-size: 1.1rem;
  color: #5e5d5d;
`

/* endregion */

export default withRouter(connect(mapStateToProps, {setTitle})(User))