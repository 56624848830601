import React from 'react'
import styled from 'styled-components'

const TextArea = ({ input, label, ...rest }) => {

  return(
    <div>
      <CustomLabel>{label}</CustomLabel>
      <CustomTextArea {...input} {...rest} />
    </div>
  )
}

const CustomTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  background: #ECECEC;
  box-sizing: border-box;
  opacity: 0.9;
  margin-top: 15px;
  color: inherit;
  padding: 15px 10px;
  font-size: 1.1rem;
  border: 1px solid #BBBBBB;
  resize: none;
  
  &:focus {
    outline: none;
  }
`

const CustomLabel = styled.label`
  opacity: 0.7;
  font-size: 0.95rem;
`

export { TextArea }
