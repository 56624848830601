import React from 'react'
import {TableContainer, Table, Tr, Th, Td, TdImage} from '../../common'


const TableCustomers = ({entities, order, switchEnabled, userIsAdmin, ...rest}) => {

  return (
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th {...rest}>Nome </Th>
          <Th {...rest}>Stockist </Th>
        </tr>
        </thead>
        <tbody data-cy={'customerTypes-table'}>
        {entities && entities.map(type =>
          <Tr key={type.id} data-cy={`customer-types-row-${type.id}`}>
            <TdImage editPath={`/customer-types/${type.id}`}/>
            <Td dataCy={'name'}>{type.name || type.businessName || '-'}</Td>
            <Td dataCy={'isStockist'}>{type.isStockist === true ? 'Si' : 'No'}</Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default TableCustomers