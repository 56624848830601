import React from 'react'
import { Button } from './Button'

const ButtonBlu = (props) => {
  return(
    <Button
      {...props}
      background='#115e8b'
    />
  )
}

export { ButtonBlu }