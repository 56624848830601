import React from 'react'
import successImage from '../../images/check.svg'
import errorImage from '../../images/close.svg'
import styled from 'styled-components'

const NotificationContent = (result, message) => {
if (result === 'success')
  return(
    <Container>
      <ImgItem><img src={successImage} alt="success" /></ImgItem>
      <Message>{ message || 'Operazione eseguita con successo' }</Message>
    </Container>
  )
  else
    return(
      <Container>
        <ImgItem><img src={errorImage} alt="error" /></ImgItem>
        <Message>{ message || 'Si è verificato un errore.' }</Message>
      </Container>
    )
}

export { NotificationContent }

//region Style

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`

const ImgItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  & img {
    width: 15px;
  }
`

const Message = styled.span`
  height: 100%;
`

//endregion