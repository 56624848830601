export const required = value => (value ? undefined : "Obbligatorio")

export const validatePassword = (required) => (password) => {
  if (!password) {
    if (required) return "Obbligatorio"
    return
  }
  // Password MUST contain at least 8 characters
  if (password.length < 8) return 'La password deve contenere almeno 8 caratteri'

  // Password MUST contain at least 3 out of 4 of the following categories:
  // - UPPER CASE LETTERS
  // - lower case letters
  // - Numbers (1,2,3,...)
  // - Special characters (!#$%^&*?)

  const conditions = [
    password.match(/[A-Z]/g),                       // UPPER CASE LETTERS
    password.match(/[a-z]/g),                       // lower case letters
    password.match(/\d/g),                          // Numbers (1,2,3,...)
    password.match(/[!#$%^&*?]/g)]          // Special characters (!#$%^&*?)
    .filter(e => !!e).length


  if (conditions < 3) return 'La password non rispetta i requisiti'
}