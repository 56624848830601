import React from 'react'
import imgLoader from '../../images/loader.svg'
import styled from 'styled-components'


const Loader = () => {
  return(
    <LoaderContainer>
      <LoaderImage src={imgLoader} alt='loader' />
    </LoaderContainer>
  )
}

/* region STYLE */

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000;
`

const LoaderImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
`

/* endregion */

export { Loader }