import React from 'react'
import {Field, Form} from 'react-final-form'
import {ButtonGreen, ErrorMessage, Input} from '../../common'
import imgAdd from '../../../images/ico-add.svg'
import styled from 'styled-components'
import {validatePassword} from '../../../helpers/formErrors'
import notify from '../../../helpers/notify'
import useEntities from '../../../hooks/useEntities'

const ChangePassword = ({ user }) => {

  const { asyncUpdate: reset } = useEntities('users', { withoutEffect: true, withoutNotifications: true })

  const validate = (values) => {
    const errors = {}
    if (!values.password) {
      errors.password = 'Obbligatorio'
    } else {
      errors.password = validatePassword(true)(values.password)
    }
    if (!values.confirm) {
      errors.confirm = 'Obbligatorio'
    } else if (values.password !== values.confirm) {
      errors.confirm = 'Le password non corrispondono'
    }
    return errors
  }

  const onSubmit = (values) => {
    const password = values.password
    const passwordConfirm = values.confirm
    reset({ password, passwordConfirm }, user.id, { action: 'change-password', throwError: true })
      .then((res) => {
        return notify('success', 'Password cambiata')
      })
      .catch((e) => {
        console.log(e.response)
        const response = e.response && e.response.data && e.response.data.response
        const message = e.response && e.response.data && e.response.data.message
        if (!response || !message) {
          return notify('failure', 'Qualcosa è andato storto')
        }
        if (e.response.status === 409) {
          if (message.includes('already used'))
            return notify(response, 'Password già utilizzata')
          if (message.includes('cannot be changed until')) {
            const tokens = message.split(' ')
            const date = [tokens[tokens.length - 2], tokens[tokens.length - 1]].join(' ')
            return notify(response, `Potrai cambiare la password in data ed ora: ${date}`)
          }
        }
        return notify('failure', 'Qualcosa è andato storto')
      })
  }

  return (
    <Row style={{ marginTop: '30px' }}>
      <Area>
        <Title width="420px">Cambia password</Title>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({handleSubmit}) => (
            <ChangePasswordForm onSubmit={handleSubmit}>
              <FormContainer>
                <InputBox data-cy={'password'}>
                  <Field name="password">
                    {({input, meta}) => (
                      <>
                        <Input type="password" label="Password" {...input} />
                        {meta.error && (meta.dirty || meta.submitFailed) && meta.touched && <ErrorMessage message={meta.error}/>}
                      </>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'password-confirm'}>
                  <Field name="confirm">
                    {({input, meta}) => (
                      <>
                        <Input type="password" label="Conferma" {...input} />
                        {meta.error && (meta.dirty || meta.submitFailed) && meta.touched && <ErrorMessage message={meta.error}/>}
                      </>
                    )}
                  </Field>
                </InputBox>
                <ButtonGreen
                  dataCy={'send-password'}
                  text='Cambia password'
                  image={imgAdd}
                  type='submit'
                />
              </FormContainer>
            </ChangePasswordForm>
          )}
        />
      </Area>
      <Area>
        <Title width="350px">Policy per le password</Title>
        <DisclaimerRow>
          <Disclaimer>
            <Content>
              La password deve essere lunga almeno 8 caratteri, e soddisfare almeno 3 su 4 di questi requisiti:
              <ul>
                <li>Carattere maiuscolo</li>
                <li>Carattere minuscolo</li>
                <li>Carattere numerico</li>
                <li>Caratteri speciali (!#$%^&*?)</li>
              </ul>
            </Content>
          </Disclaimer>
          <Disclaimer>
            <Content>
              Inoltre non si può cambiare password prima di 24h dal cambio precedente.<br />
              Devi scegliere una password
              che non hai utilizzato negli ultimi 24 mesi.
            </Content>
          </Disclaimer>
        </DisclaimerRow>
      </Area>
    </Row>  )
}

export default ChangePassword

//region Style

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const Area = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const FormContainer = styled.div`
  position: relative;
  width: 400px;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-y: auto;
  @media(max-width: 768px) {
    width: 100%;
    min-height: 10%;
  }
`

const InputBox = styled.div`
  margin: 15px 0;
  opacity: 0.9;
  width: 280px;
`

const Disclaimer = styled.div`
  font-size: 1.2rem;
  color: #474747;
  width:350px;
  &:first-child {
    margin-right: 30px;
  }
`

const Content = styled.div`
  font-size: 1.1rem;
  color: #5e5d5d;
`

const Title = styled.h2`
  opacity: 0.9;
  width: ${({ width }) => width || '100%'};
`

const ChangePasswordForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media(max-width: 768px) {
    width: 100%;
    height: unset;
    flex-direction: column;
  }
`

const DisclaimerRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

//endregion