import React from 'react'
import {Field, Form} from 'react-final-form'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import {Input, ButtonRed, ButtonGreen, Select, ErrorMessage} from '../../common'
import imgDelete from '../../../images/ico-delete.svg'
import imgAdd from '../../../images/ico-add.svg'
import {required} from '../../../helpers/formErrors'


const oneRequired = (others) => (value) => {
  const required = ['categoryId', 'priorityId', 'problemTypeId', 'subcategoryId', 'sourceId', 'typeId']
  if (!value) {

    const requiredValue = required.map(r => others[r])
    if (!requiredValue.find(val => val)) {
      return `Almeno uno dei campi:  
      Categoria, Priorità, Tipo di Problema, Sottocategoria,
      Sorgente, Tipo 
      deve essere valorizzato`
    }
  }
}

const FormTicketTemplate = (props) => {

  const {
    entity = {},
    action,
    remove,
    categories,
    priorities,
    subcategories,
    sources,
    types,
  } = props

  const onSubmit = values => {
    const toSubmit = {}
    toSubmit.id = values.id
    toSubmit.name = values.name
    if (values.categoryId)
      toSubmit.category = {id: values.categoryId}
    if (values.priorityId)
      toSubmit.priority = {id: values.priorityId}
    if (values.problemTypeId)
      toSubmit.problemType = {id: values.problemTypeId}
    if (values.subcategoryId)
      toSubmit.subcategory = {id: values.subcategoryId}
    if (values.sourceId)
      toSubmit.source = {id: values.sourceId}
    if (values.typeId)
      toSubmit.type = {id: values.typeId}
    action(toSubmit)
  }
  //region Advanced Filters
  const resetFilters = (args, state, {changeValue}) => {
    state.fields['subcategoryId'].change(null)
    state.fields['problemTypeId'].change(null)
  }

  const filterSubcategories = (subcategories, categoryId) => {
    let filtered = subcategories
    if (categoryId)
      filtered = subcategories && subcategories.filter(sub => sub.category.id === parseInt(categoryId))
    return filtered.map(entity =>
      <option key={entity.id} value={entity.id}>{entity.name}</option>
    )
  }

  const filterProblemTypes = (categoryId, categories) => {
    if (!categoryId) return []
    if (!categories || categories.length === 0) return []

    let filtered = categories.find(c => c.id === parseInt(categoryId)).problemTypes
    return filtered.map(entity =>
      <option key={entity.id} value={entity.id}>{entity.name}</option>
    )
  }
  //endregion

  const onRemove = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cancellazione priorità',
      message: 'Vuoi davvero cancellare il template ?',
      buttons: [
        {label: 'Si', onClick: () => remove()},
        {label: 'No'}
      ]
    })
  }

  const getInitialValues = (initialValues) => {
    initialValues.categoryId = initialValues.category ? initialValues.category.id : null
    initialValues.priorityId = initialValues.priority ? initialValues.priority.id : null
    initialValues.problemTypeId = initialValues.problemType ? initialValues.problemType.id : null
    initialValues.subcategoryId = initialValues.subcategory ? initialValues.subcategory.id : null
    initialValues.sourceId = initialValues.source ? initialValues.source.id : null
    initialValues.typeId = initialValues.type ? initialValues.type.id : null
    return initialValues
  }

  return (
    <Form
      mutators={{
        resetFilters
      }}
      onSubmit={onSubmit}
      initialValues={getInitialValues(entity)}
      validateOnBlur
      render={({handleSubmit, form, values}) => (
        <FormStyled onSubmit={handleSubmit}>
          <Container>
            <InputBox data-cy={'entity-name'}>
              <Field
                name="name"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Nome *"
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-category'}>
              <Field
                name="categoryId"
                validate={oneRequired(values)}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Categoria"
                      onClick={() => form.mutators.resetFilters()}
                      {...input}
                    >
                      <option>{null}</option>
                      {categories && categories.map(entity =>
                        <option key={entity.id} value={entity.id}>{entity.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-subcategory'}>
              <Field
                name="subcategoryId"
                validate={oneRequired(values)}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      disabled={!values.categoryId}
                      type="text"
                      label="Sottocategoria"
                      {...input}
                    >
                      <option>{null}</option>
                      {filterSubcategories(subcategories, values.categoryId)}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-problemtype'}>
              <Field
                name="problemTypeId"
                validate={oneRequired(values)}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      disabled={!values.categoryId}
                      type="text"
                      label="Tipo di problema"
                      {...input}
                    >
                      <option>{null}</option>
                      {(values.categoryId && categories) && filterProblemTypes(values.categoryId, categories)}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
          </Container>
          <Container>
            <InputBox data-cy={'entity-priority'}>
              <Field
                name="priorityId"
                validate={oneRequired(values)}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Priorità ticket"
                      {...input}
                    >
                      <option>{null}</option>
                      {priorities && priorities.filter(p => p.id !== 1).map(entity =>
                        <option key={entity.id} value={entity.id}>{entity.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-source'}>
              <Field
                name="sourceId"
                validate={oneRequired(values)}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Sorgente"
                      {...input}
                    >
                      <option>{null}</option>
                      {sources && sources.map(entity =>
                        <option key={entity.id} value={entity.id}>{entity.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-type'}>
              <Field
                name="typeId"
                validate={oneRequired(values)}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Tipo"
                      {...input}
                    >
                      <option>{null}</option>
                      {types && types.map(entity =>
                        <option key={entity.id} value={entity.id}>{entity.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <ButtonContainer>
              <ButtonRed
                dataCy='delete-entity'
                text='Elimina'
                image={imgDelete}
                disabled={!entity.id}
                onClick={(e) => onRemove(e)}
              />
              <ButtonGreen
                dataCy='save-entity'
                text='Salva'
                image={imgAdd}
                type='submit'
              />
            </ButtonContainer>
          </Container>
        </FormStyled>
      )}
    />
  )
}

/* region STYLE */

const Container = styled.div`
              position: relative;
              background: #FFFFFF;
              width: 400px;
              min-height: 100%;
              padding: 20px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: nowrap;
              @media(max-width: 768px) {
              width: 100%;
              min-height: 10%;
              }
              `


const FormStyled = styled.form`
              height: 100%;
              display: flex;
              flex-direction: row;
              @media(max-width: 768px) {
              width: 100%;
              height: unset;
              }
          `

const InputBox = styled.div`
  margin: 15px 0;
  opacity: 0.9;
  width: 280px;
`

const ButtonContainer = styled.div`
  width: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

/* endregion */

export default FormTicketTemplate