import React from 'react'
import {Link} from 'react-router-dom'
import {Form, Field} from 'react-final-form'
import styled from 'styled-components'
import {Input, ButtonBlu, ButtonGreen, Select, ButtonRed} from '../../common'
import imgSearch from '../../../images/ico-search.svg'
import imgAdd from '../../../images/ico-add.svg'
import imgReset from '../../../images/resetFilters.svg'


const CustomersFilters = ({onSubmit, customerTypes, filters, userIsAdmin, setUsedFilters}) => {

  const resetFilters = (args, state, {changeValue}) => (values) => {
    Object.keys(values).filter(k => {
      if (k === 'customerId' || k === 'user' || k === 'opened') return false
      return !!values[k]
    }).forEach(k => {
      state.fields[`${k}`].change(null)
    })
  }

  const disableResetFilters = (submitting, values) => {
    const usedFilter = Object.keys(values).filter(k => {
      if (k === 'customerId' || k === 'user' || k === 'opened') return false
      return !!values[k]
    })
    return !(submitting || usedFilter.length > 0)
  }

  const onSearch = (values) => {
    const filters = Object.keys(values).filter(k => {
      if (k === 'customerId' || k === 'user' || k === 'opened') return false
      return !!values[k]
    })
    setUsedFilters(filters.length > 0)
    onSubmit(values)
  }

  return (
    <Form
      mutators={{
        resetFilters
      }}
      onSubmit={onSearch}
      initialValues={filters}
      render={({handleSubmit, form, values, submitting}) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <FilterBar>
            <InputBox data-cy={'filters-customer'}>
              <Field
                name="customer"
                component={Input}
                type="text"
                label="Cliente"
              />
            </InputBox>
            <InputBox data-cy={'filters-customerType'}>
              <Field
                label="Tipo di cliente"
                name="customerType"
                component={Select}
                type="text"
              >
                <option value={null}/>
                {customerTypes && customerTypes.map(type =>
                  <option key={type.id} value={type.id}>{type.name}</option>
                )}
              </Field>
            </InputBox>
            {
              userIsAdmin && <InputBox data-cy={'filters-enabled'}>
                <Field
                  label="Abilitato"
                  name="enabled"
                  component={Select}
                  type="text"
                >
                  <option value={null}/>
                  <option value={true}>Si</option>
                  <option value={false}>No</option>
                </Field>
              </InputBox>}
            <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
            <ButtonRed
              image={imgReset}
              dataCy={'filters-reset'}
              text={'Resetta'}
              type="button"
              onClick={(e) => form.mutators.resetFilters()(values)}
              disabled={disableResetFilters(submitting, values)}
            />
            <LinkStyled data-cy={'customers-new'} to='/customers/new'>
              <ButtonGreen text='Nuovo' image={imgAdd}/>
            </LinkStyled>
          </FilterBar>
        </form>
      )}
    />
  )
}

/* region STYLE */

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
  margin-bottom: 50px;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

/* endregion */

export default CustomersFilters
