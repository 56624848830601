import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions/'
import useEntities from '../../../hooks/useEntities'
import withPagination from '../../withPagination'
import Filters from './Filters'
import Table from './Table'
import {getPageFilters, getUser} from "../../../reducers"
import {useRouteMatch} from "react-router";

const TablePaginated = withPagination(Filters, Table)

const Customers = () => {

  const match = useRouteMatch()
  const me = useSelector(getUser)
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()
  const [usedFilters, setUsedFilters] = useState(false)

  useTitle((title) => dispatch(setTitle(title)), 'Clienti')
  const options = {initialFilters: filters || {}}
  options.withoutEffect = true
  options.initialLimit = 25
  if (!me.role.isAdmin) {
    options.initialFilters.enabled = true
  }

  const {entities, setEntities, total, setTotal, get, asyncUpdate, loading} = useEntities('customers', options)
  const {entities: customerTypes} = useEntities('customer-types', {isNotPaginated: true})
  const {entities: addressTypes} = useEntities('address-types',
    {isNotPaginated: true})

  useEffect(() => {
    if (filters && Object.keys(filters).length !== 0) {
      get(filters)
    } else {
      let iterations = 0
      const interval = setInterval(() => {
        iterations++
        if (!loading || iterations >= 50) {
          setEntities([])
          setTotal(0)
          clearInterval(interval)
        }
      }, 10)
    }
  }, [filters])

  const switchEnabled = async (user) => {
    if (!me.role.isAdmin) return
    const action = user.enabled ? 'disable' : 'enable'
    asyncUpdate({}, user.id, {action, throwError: true})
      .then(() => {
        setEntities(entities.map(c => {
          if (c.id === user.id)
            c.enabled = !c.enabled
          return c
        }))
      })
  }

  return (
    <TablePaginated
      entities={entities}
      total={total}
      get={get}
      usedFilters={usedFilters}
      setUsedFilters={setUsedFilters}
      switchEnabled={switchEnabled}
      customerTypes={customerTypes}
      addressTypes={addressTypes}
      userIsAdmin={me.role.isAdmin}
      initialLimit={25}
    />
  )
}

export default Customers