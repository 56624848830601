import React, {useEffect} from 'react'
import {Table, TableContainer, Td, Th, Tr} from "../../../common"

const ByGroupTable = ({data, keys, setKeys, groups}) => {

  useEffect(() => {
    keyArray()
  }, [groups])

  const keyArray = () => {
    const reportKeys = new Set()
    groups.sort((a, b) => {
      if (a.name === 'MSDCC') return -1
      if (a.name === 'Customer Services') {
        if (b.name !== 'MSDCC') return -1
      }
      if (a.name === 'SBS Bucarest') {
        if (b.name === 'MSDCC') return 1
        if (b.name === 'Customer Services') return 1
        return -1
      }
      return 0
    }).forEach(g => {
      reportKeys.add(`Ticket Opened by ${g.name}`)
      reportKeys.add(`Ticket Closed by ${g.name}`)
      reportKeys.add(`Res. Rate % for ${g.name}`)
    })
    setKeys(['Month/Year', 'Totale Aperti', 'Totale Chiusi', ...reportKeys.values()])
  }

  return (
    <TableContainer paddingTop={'10px'}>
      <Table>
        <thead>
        <tr>
          <Th/>
          {
            keys && keys.map(k => <Th report key={k} dataCy={`title-${k}`}> {k} </Th>)
          }
        </tr>
        </thead>
        <tbody data-cy={'report-table'}>
        {
          data && data.map(reportRow => (
            <Tr key={reportRow['Month/Year']} data-cy={`report-row-${reportRow['Month/Year']}`}>
              <Td report/>
              {
                keys && keys
                  .map(k =>
                    <Td key={`${reportRow['Month/Year']}-${k}`}
                        dataCy={`${reportRow['Month/Year']}-${k}`}>
                      {
                        k.includes('Res. Rate %') ?
                          reportRow[k] || '0.00%'
                          :
                          reportRow[k] || '0'
                      }
                    </Td>)
              }
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  )


}

export default ByGroupTable