import React from 'react'
import { TableContainer, Table, Tr, Th, Td, TdImage } from '../../common'

const TableTicketProblems = ({ shownEntities, order, switchEnabled, ...rest }) => {
  return(
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th {...rest}>ID</Th>
          <Th {...rest}>Nome</Th>
        </tr>
        </thead>
        <tbody data-cy={'sources-table'} >
        { shownEntities && shownEntities.map( problemType =>
          <Tr key={problemType.id} data-cy={`sources-row-${problemType.id}`} >
            <TdImage tooltip="Modifica sorgente" editPath={`/ticket-sources/${problemType.id}`} />
            <Td dataCy={'id'} >{problemType.id || '-'}</Td>
            <Td dataCy={'name'} >{problemType.name || '-'}</Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default TableTicketProblems