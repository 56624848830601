import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import Filters from './Filters'
import Table from './Table'
import withPagination from '../../withPagination'
import {setTitle} from '../../../actions'
import {useRouteMatch} from "react-router"
import {getPageFilters} from "../../../reducers"

const TablePaginated = withPagination(Filters, Table)

const TicketProblemTypes = () => {
  const match = useRouteMatch()
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'Ticket - tipi di problema')
  const options = {initialFilters: filters || {}}

  const {entities, setEntities, total, get, asyncUpdate} = useEntities("ticket-problemtypes", options)

  const switchEnabled = async (ProblemType) => {
    const action = ProblemType.enabled ? 'disable' : 'enable'
    asyncUpdate({}, ProblemType.id, {action, throwError: true})
      .then(() => {
        setEntities(entities.map(c => {
          if (c.id === ProblemType.id)
            c.enabled = !c.enabled
          return c
        }))
      })
  }

  return (
    <TablePaginated
      entities={entities}
      total={total}
      get={get}
      switchEnabled={switchEnabled}
    />
  )
}

export default TicketProblemTypes