import React, { useState, useEffect } from 'react'
import {Field, Form } from 'react-final-form'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import {Input, ButtonRed, ButtonGreen, ErrorMessage} from '../../common'
import imgDelete from '../../../images/ico-delete.svg'
import imgAdd from '../../../images/ico-add.svg'
import {required} from '../../../helpers/formErrors'
import { SliderPicker } from 'react-color'
import Switch  from 'react-switch'


const FormPriority = ({entity = {}, action, remove}) => {

  const [ bgColor, setBgColor ] = useState('#FFFFFF')
  const [ isWhite, setIsWhite ] = useState(false)

  useEffect(() => {
    if (entity.backgroundColor)
      setBgColor(entity.backgroundColor)
    if (entity.textColor)
      setIsWhite(true)
  }, [entity])
  const onSubmit = values => {
    values.textColor = isWhite ? 1 : 0
    action(values)
  }

  const onRemove = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cancellazione priorità',
      message: 'Vuoi davvero cancellare la priorità del ticket? La cancellazione andrà a buon fine solo se nessun ticket è associato a questa priorità',
      buttons: [
        {label: 'Si', onClick: () => remove()},
        {label: 'No'}
      ]
    })
  }

  const getInitialValues = (values) => {
    const initialValues = { ...values }
    initialValues.isWhite = !!initialValues.textColor
    return values
  }
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialValues(entity)}
        validateOnBlur
        render={({handleSubmit}) => (
          <FormStyled onSubmit={handleSubmit}>
            <OrderContainer>
              <InputBox data-cy={'entity-name'}>
                <Field
                  name="name"
                  validate={required}
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        dataCy={'name'}
                        type="text"
                        label="Nome *"
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-background-color'}>
                <Field
                  name="backgroundColor"
                >
                  {({input, meta}) => (
                    <>
                      <CustomLabel>Colore di sfondo</CustomLabel>
                      <SliderPickerStyled
                        dataCy={'background-color'}
                        {...input}
                        onChange={(val) => {setBgColor(val.hex); input.onChange(val.hex)}}
                        color={bgColor}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-text-color'}>
                <Field
                  name="textColor"
                >
                  {({input, meta}) => (
                    <>
                      <CustomLabel>Colore del testo</CustomLabel>
                      <SwitchContainer>
                        Nero <Switch
                          data-cy={'text-color'}
                          type="text"
                          {...input}
                          onChange={(checked) => { setIsWhite(checked); input.onChange(checked) }}
                          checked={isWhite}
                          onColor="#949494"
                          onHandleColor="#FFFFFF"
                          offHandleColor="#000000"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={10}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        /> Bianco
                      </SwitchContainer>

                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <CustomLabel>Preview</CustomLabel>
              <Preview data-cy={'example'} bgColor={bgColor} isWhite={isWhite}> Esempio </Preview>
              <ButtonContainer>
                <ButtonRed
                  dataCy='delete-entity'
                  text='Elimina'
                  image={imgDelete}
                  disabled={!entity.id}
                  onClick={(e) => onRemove(e)}
                />
                <ButtonGreen
                  dataCy='save-entity'
                  text='Salva'
                  image={imgAdd}
                  type='submit'
                />
              </ButtonContainer>
            </OrderContainer>
          </FormStyled>
        )}
      />
    </>
  )
}

/* region STYLE */

const OrderContainer = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 400px;
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  @media(max-width: 768px) {
    width: 100%;
    min-height: 10%;
  }
`

const FormStyled = styled.form`
  height: 100%;
  @media(max-width: 768px) {
    width: 100%;
    height: unset;
  }
`

const InputBox = styled.div`
  margin: 15px 0;
  opacity: 0.9;
  width: 280px;
`

const ButtonContainer = styled.div`
  width: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

const Preview = styled.div`
  margin: 20px 0;
  box-sizing: border-box;
  width: 150px;
  height: 35px;
  padding: 10px 5px;
  color: ${({ isWhite }) => isWhite ? '#FFFFFF' : '#000000'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bgColor }) => bgColor || 'transparent'};
  border-radius: 20px;
`

const CustomLabel = styled.label`
  opacity: 0.7;
  font-size: 0.95rem;
`

const SliderPickerStyled = styled(SliderPicker)`
  margin-top: 20px;
`

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
`

/* endregion */

export default FormPriority