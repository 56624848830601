import React from 'react'
import styled from 'styled-components'
import {OrderArrow} from './OrderArrow'


const isOrdered = (field, orderName, orderType) => {
  if (!field || !orderName || !orderType) {
    return false
  }
  if (field === orderName) {
    return true
  }
  return false
}

const Th = ({onClick, field, orderName, orderType, children, dataCy, report, capitalize = true}) => {
  return (
    <ThStyled onClick={onClick} data-cy={dataCy} report={report} capitalize={capitalize}>
      {isOrdered(field, orderName, orderType) && <OrderArrow type={orderType}/>}
      {children}
    </ThStyled>
  )
}

/* region STYLE */

const ThStyled = styled.th`
  ${props => props.report ? 'padding: 10px 5px;' : 'padding: 20px 10px;'};
  ${props => props.onClick ? 'cursor : pointer;' : ''};
  white-space: nowrap;
  text-align: left;
  font-size: 0.9rem;
  ${props => props.capitalize ? 'text-transform : capitalize' : 'text-transform : none'};
  opacity: 0.8;
`

/* endregion */

export {Th}