import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import {Modal, Input, Th, Tr, Td} from '../../common'
import imgAddGreen from '../../../images/ico-add-green.svg'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/remove.svg'


const ModalNewProblemType = ({isOpened, closeModal, category}) => {

  const {entities: problemTypes} = useEntities('ticket-problemtypes', {isNotPaginated: true})
  const {associate} = useEntities('ticket-categories', {withoutEffect: true})

  const [shownProblemTypes, setShownProblemTypes] = useState([])

  useEffect(() => {
    if (category.problemTypes && problemTypes) {
      const categoryPts = category.problemTypes.map(p => p.id)
      setShownProblemTypes(problemTypes.filter(pt => !categoryPts.includes(pt.id)))
    }
  }, [category, problemTypes])

  const associateProblemType = async (problemType) => {
    if (category.problemTypes && problemTypes) {
      const categoryPts = category.problemTypes.map(p => p.id)

      await associate(category.id, 'problemtype', problemType.id)
      setShownProblemTypes(shownProblemTypes
        .filter(pt => !categoryPts
          .includes(pt.id) && pt.id !== problemType.id
        )
      )
    }
  }

  const searchProblemTypes = (event) => {
    const query = event.target.value
    const categoryPts = category.problemTypes.map(p => p.id)
    setShownProblemTypes(problemTypes.filter(pt => pt.name.toLowerCase().includes(query.toLowerCase()) && !categoryPts.includes(pt.id)))
  }

  return (
    <Modal isOpened={isOpened} closeModal={closeModal} initWidth={600} initHeight={600}>
      <ModalContainer data-cy={'problem-type-modal'}>
        <ModalTitle>Crea o seleziona un tipo di problema</ModalTitle>
        <ModalFormContainer>
          <Input
            data-cy={'problem-search'}
            name="name"
            placeholder="cerca"
            type="text"
            autoFocus
            onChange={searchProblemTypes}
          />
          {shownProblemTypes && shownProblemTypes.length > 0 &&
          <TableContainer>
            <TableStyled>
              <thead>
              <tr>
                <Th/>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th>Abilitato</Th>
              </tr>
              </thead>
              <tbody data-cy={'problem-type-modal-table'}>
              {shownProblemTypes && shownProblemTypes.map(problemType =>
                <Tr key={problemType.id}>
                  <TdAdd data-cy={'modal-add'}>
                    <ImgAdd src={imgAddGreen} alt='addItem'
                            onClick={() => associateProblemType(problemType)}/>
                  </TdAdd>
                  <Td>{problemType.id}</Td>
                  <Td>{problemType.name}</Td>
                  <Td>
                    {problemType.enabled
                      ? <ImageButton src={imgEnabled}/>
                      : <ImageButton src={imgDisabled}/>
                    }
                  </Td>
                </Tr>
              )}
              </tbody>
            </TableStyled>
          </TableContainer>
          }
        </ModalFormContainer>
      </ModalContainer>
    </Modal>
  )

}

/* region STYLE */

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
`

const ModalTitle = styled.div`
  font-size: 1.2rem;
  opacity: 0.7;
  font-weight: bold;
`

const ModalFormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  width: 100%;
`

const TableContainer = styled.div`
  display: inline-block;
  overflow-y: auto;
  max-height: 400px;
  width: 95%;
`

const TableStyled = styled.table`
  overflow-y: auto;
  width: 100%;
  opacity: 0.9;
  border-collapse: collapse;
`

const TdAdd = styled.td`
  padding: 6px;
`
const ImgAdd = styled.img`
  width: 25px;
  cursor: pointer;
`

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
`

/* endregion */

export default ModalNewProblemType