import React from 'react'
import {Field, Form} from 'react-final-form'
import {confirmAlert} from 'react-confirm-alert'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {getUser} from '../../../reducers'
import {ButtonBlu, ButtonGreen, ButtonRed, ErrorMessage, Input, Select} from '../../common'
import imgDelete from '../../../images/ico-delete.svg'
import imgRemove from '../../../images/ico-delete.svg'
import imgAdd from '../../../images/ico-add.svg'
import imgBack from '../../../images/left-arrow.svg'
import {required} from '../../../helpers/formErrors'
import DropZone from 'react-dropzone'
import useEntities from '../../../hooks/useEntities'
import imgEdit from '../../../images/ico-edit.svg'
import imgCopy from '../../../images/copy-icon.svg'
import RichTextEditor from './RichTextEditor'

import 'react-confirm-alert/src/react-confirm-alert.css'


const FormTicket = (props) => {

  const user = useSelector(getUser)

  const {
    entity = {},
    setTicket,
    remove,
    categories, customers,
    priorities, subcategories,
    sources, states, types,
    users, userIsAdmin, templates,
    customerID, setOpenModal, history,
    setOpenModalCustomer
  } = props

  const {call} = useEntities('', {withoutEffect: true, redirect: history.push})

  const onRemove = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cancellazione ticket',
      message: 'Vuoi davvero cancellare il ticket?',
      buttons: [
        {label: 'Si', onClick: () => remove(entity.id, `customers/${customerID}/tickets`)},
        {label: 'No'}
      ]
    })
  }

  //region Form
  const getInitialValues = (initialValues) => {
    initialValues = {...initialValues}
    initialValues.categoryId = initialValues.category ? initialValues.category.id : null
    initialValues.customerId = customerID || (initialValues.customer ? initialValues.customer.id : null)
    initialValues.priorityId = !entity.id ? 4 : (initialValues.priority ? initialValues.priority.id : null)
    initialValues.problemTypeId = initialValues.problemType ? initialValues.problemType.id : null
    initialValues.subcategoryId = initialValues.subcategory ? initialValues.subcategory.id : null
    initialValues.sourceId = initialValues.source ? initialValues.source.id : null
    initialValues.stateId = !entity.id ? 1 : (initialValues.state ? initialValues.state.id : null)
    initialValues.typeId = initialValues.type ? initialValues.type.id : null
    initialValues.userName = initialValues.user ? `${initialValues.user.surname} ${initialValues.user.name}` : ''
    initialValues.createdBy = initialValues.creator ? `${initialValues.creator.surname} ${initialValues.creator.name}` : ''
    initialValues.company = initialValues.company || 2
    if (entity.id) {
      initialValues.ID = entity.id
      initialValues.historyNotes = ''
      initialValues.attachments = []
      if (initialValues && initialValues.customer && initialValues.customer.businessName)
        initialValues.piva = initialValues.customer.businessName
      if (initialValues && initialValues.customer && initialValues.customer.structureName)
        initialValues.structureName = initialValues.customer.structureName
    } else {
      initialValues.assignToId = initialValues.assignToId || user.id
      if (typeof customers[0].elite === 'boolean' && customers[0].elite)
        initialValues.priorityId = 1
    }
    return initialValues
  }
  const getValues = (values) => {
    let toSubmit
    if (!entity.id) {
      //POST non ha bisogno di state
      toSubmit = new FormData()
      toSubmit.append('state', values.stateId)
      toSubmit.append('category', values.categoryId)
      toSubmit.append('customer', values.customerId)
      toSubmit.append('priority', values.priorityId)
      toSubmit.append('problemType', values.problemTypeId)
      toSubmit.append('subcategory', values.subcategoryId)
      toSubmit.append('source', values.sourceId)
      toSubmit.append('type', values.typeId)
      toSubmit.append('company', values.company)
      values.email && toSubmit.append('email', values.email)
      values.delivery && toSubmit.append('delivery', values.delivery)
      values.deliveryValue && toSubmit.append('deliveryValue', values.deliveryValue)
      values.phone && toSubmit.append('phone', values.phone)
      values.assignToId && toSubmit.append('assignTo', values.assignToId)
      values.note && toSubmit.append('note', values.note)
      if (values.attachments && values.attachments.length > 0)
        values.attachments.forEach(f => toSubmit.append('attachments', f))
    } else {
      toSubmit = {}
      toSubmit.id = values.id
      toSubmit.state = {id: values.stateId}
      toSubmit.category = {id: values.categoryId}
      toSubmit.customer = {id: values.customerId}
      toSubmit.priority = {id: values.priorityId}
      toSubmit.problemType = {id: values.problemTypeId}
      toSubmit.subcategory = {id: values.subcategoryId}
      toSubmit.source = {id: values.sourceId}
      toSubmit.type = {id: values.typeId}
      const user = users.find(u => u.id === parseInt(values.assignToId))
      toSubmit.userName = user ? `${user.surname} ${user.name}` : ''
      toSubmit.user = {id: values.userId}
      toSubmit.email = values.email
      toSubmit.delivery = values.delivery
      toSubmit.deliveryValue = values.deliveryValue
      toSubmit.phone = values.phone
      toSubmit.company = values.company
    }
    return toSubmit
  }

  const onSubmit = async (values) => {
    const toSubmit = getValues(values)

    let data
    if (!entity.id) {
      try {
        data = await call('post', `/tickets`, toSubmit, `/customers/${customerID}/tickets`)
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        data = await call('put', `/tickets/${entity.id}`, toSubmit, `/customers/${customerID}/tickets`)
      } catch (e) {
        console.log(e)
      }
    }
    setTicket(data.payload)
  }
  const redirectTo = (e, url, newPage) => {
    e.preventDefault()
    if (newPage) {
      let a = document.createElement('a')
      a.target = '_blank'
      a.href = url
      a.click()
      return
    }
    window.location.href = url
  }
  const disabledOnClosedTicket = () => {
    if (entity && entity.state && entity.state.id) {
      return entity.state.id === 2
    }
    return false
  }

  const disabledOnNotAdminAndClosedTicket = () => {
    if (user.role.isAdmin) return false
    if (entity && entity.state && entity.state.id) {
      return entity.state.id === 2
    }
    return false
  }
  const disabledPriority = () => {
    if (customers && typeof customers[0].elite === 'boolean') {
      return customers[0].elite
    }
    return false
  }

  const submitAndRedirect = async (e, values, url) => {
    e.preventDefault()
    let error = false
    const toSubmit = getValues(values)
    if (!entity.id) {
      try {
        await call('post', `/tickets`, toSubmit)
      } catch (e) {
        error = true
        console.log(e)
      }
    } else {
      try {
        await call('put', `/tickets/${entity.id}`, toSubmit)
      } catch (e) {
        error = true
        console.log(e)
      }
    }
    if (error) return
    window.location.href = url
  }
  const copyData = async ({ID, customer}, copy) => {
    const {name, surname, businessName, structureName} = customer
    if (!copy)
      await navigator.clipboard.writeText(`${surname || businessName} - ${name || structureName} - ${ID}`)
    else
      await navigator.clipboard.writeText(`${copy}`)
  }
  //endregion

  // region Drag & Drop
  const humanFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }
  const onDrop = (args, state, {changeValue}) => (files) => {
    state.fields['attachments'].blur()
    if (files && files.length > 0) {
      const attachments = state.formState.values['attachments']
      files.forEach(f => attachments.push(f))
      state.fields['attachments'].change(attachments)
      state.fields['attachments'].focus()
    }
  }
  const removeFile = (args, state, {changeValue}) => (filename) => {
    const attachments = state.formState.values['attachments'].filter(a => a.name !== filename)
    state.fields['attachments'].change(attachments)
  }
  //endregion

  //region Change Template mutator
  const changeTemplate = (args, state, {changeValue}) => {
    const templateId = state.formState.values.templateId
    if (templateId) {
      //se presente un template id cambio i valori in base ai valori impostati nel template
      const usedTemplate = templates.find(t => t.id === parseInt(templateId))

      if (usedTemplate.priority && usedTemplate.priority.id)
        if (!customers[0].elite)
          state.fields['priorityId'].change(usedTemplate.priority.id)
        else
          state.fields['priorityId'].change(1)
      else if (!customers[0].elite)
        state.fields['priorityId'].change(null)

      if (usedTemplate.category && usedTemplate.category.id)
        state.fields['categoryId'].change(usedTemplate.category.id)
      else
        state.fields['categoryId'].change(null)

      if (usedTemplate.subcategory && usedTemplate.subcategory.id)
        state.fields['subcategoryId'].change(usedTemplate.subcategory.id)
      else
        state.fields['subcategoryId'].change(null)

      if (usedTemplate.problemType && usedTemplate.problemType.id)
        state.fields['problemTypeId'].change(usedTemplate.problemType.id)
      else
        state.fields['problemTypeId'].change(null)

      if (usedTemplate.source && usedTemplate.source.id)
        state.fields['sourceId'].change(usedTemplate.source.id)
      else
        state.fields['sourceId'].change(null)

      if (usedTemplate.type && usedTemplate.type.id)
        state.fields['typeId'].change(usedTemplate.type.id)
      else
        state.fields['typeId'].change(null)
    }
  }
  //endregion

  //region Advanced Filters
  const resetFilters = (args, state, {changeValue}) => {
    state.fields['subcategoryId'].change(null)
    state.fields['problemTypeId'].change(null)
  }
  const filterSubcategories = (subcategories, categoryId) => {
    let filtered = subcategories
    if (categoryId)
      filtered = subcategories && subcategories.filter(sub => sub.category.id === parseInt(categoryId))
    if (filtered && entity && entity.subcategory && !filtered.find(s => s.id === entity.subcategory.id))
      filtered.push(entity.subcategory)
    return filtered.map(entity =>
      <option key={entity.id} value={entity.id}>{entity.name}</option>
    )
  }
  const filterProblemTypes = (categoryId, categories) => {
    if (!categoryId) return []
    if (!categories || categories.length === 0) return []

    let filtered = categories.find(c => c.id === parseInt(categoryId))
    if (!filtered) return [entity.problemType]
    const filteredProblem = filtered.problemTypes || []
    if (entity && entity.problemType && !filteredProblem.find(p => p.id === entity.problemType.id))
      filteredProblem.push(entity.problemType)
    return filteredProblem.map(entity =>
      <option key={entity.id} value={entity.id}>{entity.name}</option>
    )
  }
  const allCategories = () => {
    if (categories && entity && entity.category && !categories.find(c => c.id === entity.category.id))
      categories.push(entity.category)
    return categories
  }
  //endregion

  //region Phone  & Email error
  const phoneShouldBeMandatory = (t) => {
    // il campo non deve essere obbligatorio per categoria Gestione ordini e sottocategoria Status e per source Phone
    return (parseInt(t.categoryId) !== 12 || parseInt(t.subcategoryId) !== 158) && parseInt(t.sourceId) !== 1
  }
  const mailShouldBeMandatory = (t) => {
    // il campo non deve essere obbligatorio per categoria Gestione ordini e sottocategoria Status
    return (parseInt(t.categoryId) !== 12 || parseInt(t.subcategoryId) !== 158)
  }
  const validateForm = (values) => {
    const errors = {}
    if (!values.phone && phoneShouldBeMandatory(values)) {
      errors.phone = 'Obbligatorio'
    }
    if (!values.email && mailShouldBeMandatory(values)) {
      errors.email = 'Obbligatorio'
    }
    return errors
  }
//endregion

  return (
    <Form
      mutators={{
        changeTemplate,
        onDrop,
        removeFile,
        resetFilters
      }}
      onSubmit={onSubmit}
      initialValues={getInitialValues(entity)}
      validate={validateForm}
      render={({handleSubmit, form, values}) => (
        <TicketFormContainer>
          <FormStyled onSubmit={handleSubmit}>
            <Container>
              {
                entity.id && <InputBox data-cy={'entity-ID'}>
                  <Field
                    name="ID"
                  >
                    {({input, meta}) => (
                      <FieldWithIcon>
                        <Input
                          disabled={true}
                          type="text"
                          label="Id Ticket"
                          minWidth={200}
                          fontSize={'0.9rem'}
                          marginTop={'0px'}
                          {...input}
                        />
                        <Edit data-cy={'customer-copy'} src={imgCopy} alt="copy"
                              onClick={() => copyData(values)}/>
                      </FieldWithIcon>
                    )}
                  </Field>
                </InputBox>
              }
              {
                entity.id && values.piva && <InputBox data-cy={'entity-piva'}>
                  <Field
                    name="piva"
                  >
                    {({input, meta}) => (
                      <FieldWithIcon>
                        <Input
                          disabled={true}
                          type="text"
                          label="P.Iva"
                          minWidth={250}
                          fontSize={'0.9rem'}
                          marginTop={'0px'}
                          {...input}
                        />
                        <Edit data-cy={'piva-copy'} src={imgCopy} alt="copy"
                              onClick={() => copyData(values, values.piva)}/>
                      </FieldWithIcon>
                    )}
                  </Field>
                </InputBox>
              }
              {
                entity.id && values.structureName && <InputBox data-cy={'entity-structureName'}>
                  <Field
                    name="structureName"
                  >
                    {({input, meta}) => (
                      <FieldWithIcon>
                        <Input
                          disabled={true}
                          type="text"
                          label="Ragione Sociale"
                          minWidth={200}
                          fontSize={'0.9rem'}
                          marginTop={'0px'}
                          {...input}
                        />
                        {(entity.id && userIsAdmin) &&
                        <Edit data-cy={'customer-edit'} src={imgEdit} alt="edit"
                              onClick={() => setOpenModalCustomer(true)}/>}
                      </FieldWithIcon>
                    )}
                  </Field>
                </InputBox>
              }
              {
                !values.structureName && <InputBox data-cy={'entity-customer'}>
                  <Field
                    name="customerId"
                    validate={required}
                  >
                    {({input, meta}) => (
                      <FieldWithIcon>
                        <Select
                          disabled={true}
                          type="text"
                          label={`Cliente`}
                          minWidth={200}
                          loading={customers.length === 0}
                          fontSize={'0.9rem'}
                          marginTop={'0px'}
                          {...input}
                        >
                          <option value={null}></option>
                          {customers && customers.map(entity =>
                            <option key={'customer'} value={entity.id}>
                              {`${entity.name || entity.structureName} ${entity.surname || entity.businessName}`}
                            </option>
                          )}
                        </Select>
                        {(entity.id && userIsAdmin) &&
                        <Edit data-cy={'customer-edit'} src={imgEdit} alt="edit"
                              onClick={() => setOpenModalCustomer(true)}/>}
                        {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                      </FieldWithIcon>
                    )}
                  </Field>
                </InputBox>}
              {
                !entity.id && <InputBox data-cy={'entity-template'}>
                  <Field
                    name="templateId"
                  >
                    {({input, meta}) => (
                      <>
                        <Select
                          type="text"
                          label="Template ticket"
                          onClick={() => form.mutators.changeTemplate()}
                          loading={templates.length === 0}
                          fontSize={'0.9rem'}
                          marginTop={'0px'}
                          {...input}
                        >
                          <option value={null}>{null}</option>
                          {templates && templates.map(entity =>
                            <option key={entity.id} value={entity.id}>{entity.name}</option>
                          )}
                        </Select>
                        {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                      </>
                    )}
                  </Field>
                </InputBox>
              }
              <InputBox data-cy={'entity-type'}>
                <Field
                  name="typeId"
                  validate={required}
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        disabled={disabledOnClosedTicket()}
                        type="text"
                        label="Tipo *"
                        loading={types.length === 0}
                        fontSize={'0.9rem'}
                        marginTop={'0px'}
                        {...input}
                      >
                        <option>{null}</option>
                        {types && types.map(entity =>
                          <option key={entity.id} value={entity.id}>{entity.name}</option>
                        )}
                      </Select>
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-source'}>
                <Field
                  name="sourceId"
                  validate={required}
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        disabled={disabledOnClosedTicket()}
                        type="text"
                        label="Sorgente *"
                        loading={sources.length === 0}
                        {...input}
                      >
                        <option>{null}</option>
                        {sources && sources.map(entity =>
                          <option key={entity.id} value={entity.id}>{entity.name}</option>
                        )}
                      </Select>
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-priority'}>
                <Field
                  name="priorityId"
                  validate={!customers[0].elite && required}
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        type="text"
                        label="Priorità ticket *"
                        disabled={disabledOnClosedTicket() || disabledPriority()}
                        loading={priorities.length === 0}
                        {...input}
                      >
                        <option value={null}></option>
                        {
                          !customers[0].elite && priorities && priorities.filter(p => p.id !== 1).map(priority =>
                            <option key={priority.id} value={priority.id}>{priority.name}</option>
                          )}
                        {customers[0].elite && priorities && priorities.map(priority =>
                          <option key={priority.id} value={priority.id}>{priority.name}</option>
                        )}
                      </Select>
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-category'}>
                <Field
                  name="categoryId"
                  validate={required}
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        disabled={disabledOnNotAdminAndClosedTicket()}
                        type="text"
                        label="Categoria *"
                        onClick={() => form.mutators.resetFilters()}
                        loading={categories.length === 0}
                        {...input}
                      >
                        <option>{null}</option>
                        {categories && allCategories().map(entity =>
                          <option key={entity.id} value={entity.id}>{entity.name}</option>
                        )}
                      </Select>
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-subcategory'}>
                <Field
                  name="subcategoryId"
                  validate={required}
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        disabled={!values.categoryId || disabledOnNotAdminAndClosedTicket()}
                        type="text"
                        label="Sottocategoria *"
                        loading={subcategories.length === 0}
                        {...input}
                      >
                        <option>{null}</option>
                        {filterSubcategories(subcategories, values.categoryId)}
                      </Select>
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-problemtype'}>
                <Field
                  name="problemTypeId"
                  validate={required}
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        disabled={!values.categoryId || disabledOnNotAdminAndClosedTicket()}
                        type="text"
                        label="Tipo di problema *"
                        loading={categories.length === 0}
                        {...input}
                      >
                        <option>{null}</option>
                        {(values.categoryId && categories) && filterProblemTypes(values.categoryId, categories)}
                      </Select>
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
            </Container>
            {
              !entity.id && <Container>
                <InputBox data-cy={'entity-state'}>
                  <Field
                    name="stateId"
                    validate={required}
                  >
                    {({input, meta}) => (
                      <>
                        <Select
                          type="text"
                          label="Stato ticket *"
                          loading={states.length === 0}
                          {...input}
                          fontSize={'0.9rem'}
                          marginTop={'0px'}
                        >
                          <option>{null}</option>
                          {states && states.map(entity =>
                            <option key={entity.id} value={entity.id}>{entity.name}</option>
                          )}
                        </Select>
                        {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                      </>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'entity-assignTo'}>
                  <Field
                    name="assignToId"
                  >
                    {({input, meta}) => (
                      <>
                        <Select
                          type="text"
                          label="Assegna a"
                          loading={users.length === 0}
                          fontSize={'0.9rem'}
                          marginTop={'0px'}
                          {...input}
                        >
                          <option>{null}</option>
                          {users && users.map(entity =>
                            <option key={entity.id} value={entity.id}>{`${entity.surname} ${entity.name}`}</option>
                          )}
                        </Select>
                        {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                      </>
                    )}
                  </Field>
                </InputBox>
                <RichTextEditorContainer data-cy={'entity-note'}>
                  <Field
                    name="note"
                    component={RichTextEditor}
                    validate={required}
                    label="Note *"
                    small={true}
                  />
                </RichTextEditorContainer>
                <FileInput width="280px" data-cy={'entity-file'}>
                  <Field
                    name="attachments"
                  >
                    {({input, meta}) => (
                      <div key={'attachments'}>
                        <DropZone onDrop={(files) => form.mutators.onDrop()(files)}
                                  maxSize={10485760}
                                  multiple
                        >
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <DragTitle>Allega file</DragTitle>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <DragAndDrop data-cy={'DragAndDrop'}>
                                  Clicca o trascina qui un file per caricare un allegato
                                </DragAndDrop>
                              </div>
                            </section>
                          )}
                        </DropZone>
                        {
                          values.attachments && values.attachments.length > 0 ? values.attachments.map(entity => (
                              <InfoContainer key={entity.name}>
                                <ButtonRed onClick={() => form.mutators.removeFile()(entity.name)}
                                           dataCy={'remove-file'}
                                           text='Rimuovi allegato'
                                           image={imgRemove}/>
                                <FileInfo data-cy={'file-info'}>
                                  Nome file : {entity.name ? entity.name : ''}
                                  <br/>
                                  Dimensione : {entity.size ? humanFileSize(entity.size) : ''}
                                </FileInfo>
                              </InfoContainer>
                            )) :
                            <NoInfo>

                            </NoInfo>
                        }
                      </div>
                    )}
                  </Field>
                </FileInput>
              </Container>
            }
            <Container>
              <InputBox data-cy={'entity-company'}>
                <Field
                  name="company"
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        disabled={disabledOnClosedTicket()}
                        type="text"
                        label="Azienda"
                        loading={types.length === 0}
                        fontSize={'0.9rem'}
                        marginTop={'0px'}
                        {...input}
                      >
                        <option value={2}>Organon</option>
                      </Select>
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-phone'}>
                <Field
                  name="phone"
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        type="text"
                        label="Recapito telefonico"
                        disabled={!!entity.id && disabledOnClosedTicket()}
                        fontSize={'0.9rem'}
                        marginTop={'0px'}
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-email'}>
                <Field
                  name="email"
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        type="text"
                        label="Email"
                        disabled={!!entity.id && disabledOnClosedTicket()}
                        fontSize={'0.9rem'}
                        marginTop={'0px'}
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-company-delivery'}>
                <Field
                  name="delivery"
                >
                  {({input, meta}) => (
                    <>
                      <Select
                        disabled={disabledOnClosedTicket()}
                        type="text"
                        label="DLV/CQ/MDG-CR/RN(EDPAR)"
                        loading={types.length === 0}
                        fontSize={'0.9rem'}
                        marginTop={'0px'}
                        {...input}
                      >
                        <option>{null}</option>
                        <option value={'Delivery'}>Delivery</option>
                        <option value={'Customer Qualification'}>Customer Qualification</option>
                        <option value={'MDG Customer Request'}>MDG Customer Request</option>
                        <option value={'Request Number (EDPAR)'}>Request Number (EDPAR)</option>
                      </Select>
                    </>
                  )}
                </Field>
              </InputBox>
              <InputBox data-cy={'entity-delivery-value'}>
                <Field
                  name="deliveryValue"
                >
                  {({input, meta}) => (
                    <>
                      <Input
                        type="text"
                        label="DLV/CQ/MDG-CR/RN(EDPAR)"
                        disabled={!!entity.id && disabledOnClosedTicket()}
                        fontSize={'0.9rem'}
                        marginTop={'0px'}
                        {...input}
                      />
                      {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                    </>
                  )}
                </Field>
              </InputBox>
              {(entity.user && entity.user.id) && <InputBox data-cy={'entity-user'}>
                <Field
                  name="userName"
                  component={Input}
                  type="text"
                  disabled={true}
                  label="Assegnato a"
                  fontSize={'0.9rem'}
                  marginTop={'0px'}
                />
              </InputBox>}
              {(entity.user && entity.user.id) && <InputBox data-cy={'entity-createdBy'}>
                <Field
                  name="createdBy"
                  component={Input}
                  type="text"
                  disabled={true}
                  label="Creato da"
                  fontSize={'0.9rem'}
                  marginTop={'0px'}
                />
              </InputBox>}
              <ButtonContainer>
                {(user.role.isAdmin || user.role.id === 9) &&
                <ButtonRed
                  dataCy={'delete-entity'}
                  text='Elimina'
                  image={imgDelete}
                  disabled={!entity.id || disabledOnClosedTicket()}
                  onClick={(e) => onRemove(e)}
                  margin="5px 15px"
                />
                }
                {entity.id &&
                <ButtonBlu text='Nuovo Ticket'
                           dataCy={'go-to-new-ticket'}
                           image={imgAdd}
                           margin="5px 15px"
                           onClick={(e) => redirectTo(e, `/customers/${values.customerId}/tickets/new`)}
                />
                }
                <ButtonBlu text='History'
                           dataCy={'go-to-history'}
                           image={imgBack}
                           margin="5px 15px"
                           onClick={(e) => redirectTo(e, `/customers/${values.customerId}/tickets/`, true)}
                />
              </ButtonContainer>
              <ButtonGreen
                dataCy={'save-entity'}
                text='Salva'
                image={imgAdd}
                disabled={disabledOnNotAdminAndClosedTicket()}
                marginText
                minWidth={'242px!important'}
                type='submit'
              />
              <ButtonGreen
                dataCy={'save-entity-new-ticket'}
                text='Salva e Crea un nuovo Ticket'
                image={imgAdd}
                disabled={disabledOnNotAdminAndClosedTicket()}
                marginText
                minWidth="242px!important"
                margin="0 15px"
                onClick={(e) => submitAndRedirect(e, values, `/customers/${values.customerId}/tickets/new`)}
              />
              <ButtonGreen
                dataCy={'save-entity-my-tickets'}
                text='Salva e vai ai miei Tickets'
                image={imgAdd}
                disabled={disabledOnNotAdminAndClosedTicket()}
                marginText
                minWidth={'242px!important'}
                onClick={(e) => submitAndRedirect(e, values, '/my-tickets')}
              />
            </Container>
          </FormStyled>
          <StockistsContainer>
            {entity.id &&
            <StockistsTitle>
              <p>Stockists</p>
              <ButtonGreen
                dataCy={'handle-entity'}
                text='Gestisci'
                disabled={disabledOnClosedTicket()}
                image={imgAdd}
                onClick={(e) => {
                  e.preventDefault()
                  setOpenModal(true)
                }}
              />
            </StockistsTitle>}
            {entity.id && <Stockists withBorder={entity.stockists.length}>
              {entity.stockists && entity.stockists.map(s =>
                <StockistsItem key={s.id}>{s.name || s.businessName} {s.surname || s.structureName}</StockistsItem>
              )}
            </Stockists>}
            {
              entity.id && entity.stockists.length === 0 &&
              <NoStockists>Nessuno stockist</NoStockists>
            }
          </StockistsContainer>
        </TicketFormContainer>
      )}
    />
  )
}

//region STYLE

const Container = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 400px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-y: auto;
  @media(max-width: 768px) {
    width: 100%;
    min-height: 10%;
  }
`

const TicketFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100%;
  background: #FFFFFF;
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: row;
  @media(max-width: 768px) {
    width: 100%;
    height: unset;
    flex-direction: column;
  }
`

const InputBox = styled.div`
  margin: 15px 0;
  opacity: 0.9;
  width: 280px;
`

const RichTextEditorContainer = styled.div`
  margin: 15px 0;
  opacity: 0.9;
  width: 280px;
  z-index: 100;
`

const ButtonContainer = styled.div`
  width: 310px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`

const Stockists = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  ${({withBorder}) => withBorder && 'border-bottom: 1px solid #000000'};
  overflow-y: auto;
`

const StockistsTitle = styled.div`
  font-size: 1.05rem;
  font-weight: bolder;
  margin: 20px 0;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StockistsItem = styled.div`
  box-sizing: border-box;
  opacity: 0.8;
  width: 100%;
  font-size: 0.8rem;
  padding: 7px 4px;
  
  :nth-child(even){
    background:#ECECEC;
  }
  :nth-child(odd){
    background:#fafafa;
  }
`

const NoStockists = styled.div`
  width: 100%;
  background:#fafafa;
  color: #000000;
  opacity: 0.8;
  font-size: 1.2rem;
  padding: 2px 3px;
`

const FileInput = styled.div`
  margin: 10px;
  opacity: 0.9;
  ${({width}) => `width: ${width}`};
`

const DragAndDrop = styled.section`
  background: #ECECEC;
  padding: 5px;
  margin-top: 15px;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #BBBBBB;
  text-align: -webkit-center;
  min-height: 45px;
  min-width: 280px;
`

const DragTitle = styled.div`
  opacity: 0.7;
  font-size: 0.95rem;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const FileInfo = styled.div`
  opacity: 0.7;
  margin: 15px;
  margin-top: 0px;
  font-size: 0.95rem;
`

const NoInfo = styled.div`
  min-height: 64px; 
`

const Edit = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
`

const FieldWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  box-sizing: border-box;
`

const StockistsContainer = styled.div`
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 0 20px 10px 20px;
  @media (min-width: 1650px) {
    width: 100%;
  }
  @media (max-width: 1650px) {
    width: 800px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
//endregion

export default FormTicket
