import React, {useLayoutEffect, useRef} from 'react'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"


const BySubcategoryGraph = ({reportRows}) => {

  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("by-subcategory-graph", am4charts.XYChart)

    const data = reportRows.filter(row => row.Subcategory !== 'Totali')
      .map(row => ({
        name: row.Subcategory,
        total: row['Totale']
      }))


    x.data = data

    let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.title.text = "Nome sotto categoria"
    categoryAxis.dataFields.category = "name"
    categoryAxis.title.fontWeight = "bold"
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.labels.template.disabled = true
    categoryAxis.title.dy = 15

    let valueAxis = x.yAxes.push(new am4charts.ValueAxis())
    valueAxis.tooltip.disabled = true
    valueAxis.title.text = "Totale"
    valueAxis.title.fontWeight = "bold"
    valueAxis.min = 0
    valueAxis.renderer.minWidth = 35

    let axisBreak = valueAxis.axisBreaks.create()
    axisBreak.startValue = 50
    axisBreak.endValue = 250
    axisBreak.breakSize = 0.01

    axisBreak.events.on("over", () => {
      axisBreak.animate(
        [{property: "breakSize", to: 1}, {property: "opacity", to: 0.1}],
        1500,
        am4core.ease.sinOut
      );
    });
    axisBreak.events.on("out", () => {
      axisBreak.animate(
        [{property: "breakSize", to: 0.01}, {property: "opacity", to: 1}],
        1000,
        am4core.ease.quadOut
      );
    });


    let series = x.series.push(new am4charts.ColumnSeries())
    series.dataFields.categoryX = "name"
    series.dataFields.valueY = "total"
    series.tooltipText = "{valueY.value}"
    series.columns.template.fill = am4core.color("#273A7D")
    x.cursor = new am4charts.XYCursor()

    chart.current = x

    return () => {
      x.dispose()
    }
  }, [reportRows])

  return (
    <div id="by-subcategory-graph" style={{width: "100%", height: "500px"}}/>
  )

}

export default BySubcategoryGraph