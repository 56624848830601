import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions/'
import useEntities from '../../../hooks/useEntities'
import withPagination from '../../withPagination'
import Filters from './Filters'
import Table from './Table'
import {useRouteMatch} from "react-router";
import {getPageFilters} from "../../../reducers"

const TablePaginated = withPagination(Filters, Table)


const Users = () => {
  const match = useRouteMatch()
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'Utenti')
  const options = {initialFilters: filters || {}}

  const {entities, setEntities, total, get, asyncUpdate} = useEntities('users', options)
  const {entities: groups} = useEntities('user-groups')
  const {entities: roles} = useEntities('user-roles')

  const switchEnabled = async (user) => {
    const action = user.enabled ? 'disable' : 'enable'
    asyncUpdate({}, user.id, {action, throwError: true})
      .then(() => {
        setEntities(entities.map(c => {
          if (c.id === user.id)
            c.enabled = !c.enabled
          return c
        }))
      })
  }

  return (
    <TablePaginated
      entities={entities}
      total={total}
      get={get}
      switchEnabled={switchEnabled}
      groups={groups}
      roles={roles}
    />
  )
}

export default Users