import React, {useState} from 'react'
import {connect} from 'react-redux'
import {setTitle} from "../../../../actions"
import useTitle from "../../../../hooks/useTitle"
import useEntities from "../../../../hooks/useEntities"
import Report from './Table'
import Filters from './Filters'
import Graph from './Graph'
import * as moment from "moment";

const ByAgent = ({setTitle}) => {

  useTitle(setTitle, 'Report - By agent')

  const [open, setOpen] = useState(false)
  const [dates, setDates] = useState([
    `${moment().subtract(1, 'month').startOf('month').format('MMM YYYY')} Opened`,
    `${moment().subtract(1, 'month').startOf('month').format('MMM YYYY')} Closed`,
    `${moment().endOf('month').format('MMM YYYY')} Opened`,
    `${moment().endOf('month').format('MMM YYYY')} Closed`
  ])

  const {entities: data , total, get} = useEntities("reports/by-agent-msdcc",{isNotPaginated: true})
  const {entities: categories} = useEntities('ticket-categories', {isNotPaginated: true})
  const {entities: subcategories} = useEntities('ticket-subcategories', {isNotPaginated: true})
  const {entities: problemTypes} = useEntities('ticket-problemtypes', {isNotPaginated: true})
  const {entities: sources} = useEntities('ticket-sources', {isNotPaginated: true})
  const {entities: groups} = useEntities('user-groups', {isNotPaginated: true})

  return (
    <>
      <Filters
        open={open}
        data={data}
        dateArray={dates}
        setOpen={setOpen}
        setDates={setDates}
        categories={categories}
        subcategories={subcategories}
        problemTypes={problemTypes}
        sources={sources}
        groups={groups}
        total={total}
        onSubmit={get}
      />
      <Graph
        reportRows={data}
        dateArray={dates}
      />
      <Report
        data={data}
        dateArray={dates}
        total={total}
      />
    </>

  )
}

export default connect(null, {setTitle})(ByAgent)