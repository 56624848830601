import React from 'react'
import styled from 'styled-components'
import imgLoading from '../../images/loader.svg'


const Select = ({input, label, loading, fontSize = '0.95rem', ...rest}) => {

  return (
    <div>
      <CustomLabel fontSize={fontSize}>{label}{loading && <img src={imgLoading}/>}</CustomLabel>
      <CustomSelect {...input} {...rest} />
    </div>
  )
}

const CustomSelect = styled.select`
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid;
  opacity: 0.9;
  margin-top: ${({marginTop}) => marginTop ? marginTop : '8px'};
  color: inherit;
  padding: 7px 0;
  font-size: 0.9rem;
  min-width: ${({minWidth}) => minWidth ? `${minWidth}px` : '100px'};
  max-width: ${({maxWidth}) => maxWidth ? `${maxWidth}px` : 'unset'};
  
  &:focus {
    outline: none;
  }
`

const CustomLabel = styled.label`
  opacity: 0.7;
  font-size: ${({fontSize}) => fontSize};
  & img {
    width: 15px;
    margin-left: 10px;
  }
`

export {Select}