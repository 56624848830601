import React from 'react'
import styled from 'styled-components'

const Input = ({input = {}, label, dataCy, onBlur, onFocus, fontSize = '0.95rem', withAutocomplete = false, ...rest}) => {

  if (!withAutocomplete) {
    input.autoComplete = "no"
  }

  return (
    <div data-cy={dataCy}>
      <CustomLabel
        fontSize={fontSize}
      >{label}</CustomLabel>
      <CustomInput
        {...input} {...rest}
        onFocus={onFocus || rest.onFocus}
        onBlur={onBlur || rest.onBlur}
      />
    </div>
  )
}

const CustomInput = styled.input`
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid;
  opacity: 0.9;
  margin-top: ${({marginTop}) => marginTop ? marginTop : '8px'};
  color: inherit;
  padding: 7px 0 8.5px 0;
  font-size: 0.9rem;
  ${props => props.name === 'password' ? 'padding-right: 30px;' : ''};
  min-width: ${({minWidth}) => minWidth ? `${minWidth}px` : '100px'};
  &:focus {
    outline: none;
  }
`

const CustomLabel = styled.label`
  opacity: 0.7;
  font-size: ${({fontSize}) => fontSize};
`

export {Input}