import React from 'react'
import {Field, Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import {ButtonBlu, ButtonGreen, ButtonRed, ErrorMessage, Input, Select} from '../../common'
import imgDelete from '../../../images/ico-delete.svg'
import imgAdd from '../../../images/ico-add.svg'
import {required} from '../../../helpers/formErrors'
import parseBooleanValues from '../../../helpers/parseBooleanValues'
import imgRemove from '../../../images/ico-delete-red.svg'

const FormUser = (props) => {

  const {entity = {}, action, remove, groups, roles, userIsAdmin, resetPassword} = props

  const onSubmit = values => {
    const toSubmit = {...values}
    toSubmit.group = {id: values.groupId}
    toSubmit.role = {id: values.roleId}
    toSubmit.enabled = parseBooleanValues(values.enabled)
    toSubmit.emails = [...values.emails.filter(m => !!m)]
    toSubmit.groupId = undefined
    toSubmit.roleId = undefined
    action(toSubmit)
  }

  const onRemove = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cancellazione utente',
      message: 'Vuoi davvero cancellare l\'utente? La cancellazione andrà a buon fine solo se nessun ticket è associato a questo utente',
      buttons: [
        {label: 'Si', onClick: () => remove()},
        {label: 'No'}
      ]
    })
  }

  const checkIfEmailIsValorized = (emails) => {
    if (emails && emails.length > 0)
      if (emails.filter(m => m === undefined || m === '' || m === null).length > 0) {
        return true
      }
    return false
  }

  const onResetPassword = (e) => {
    e.preventDefault()
    resetPassword()
  }

  const getInitialValues = (initialValues) => {
    initialValues.groupId = initialValues.group ? initialValues.group.id : null
    initialValues.roleId = initialValues.role ? initialValues.role.id : null
    if (!entity.id) {
      initialValues.emails = ['']
      initialValues.enabled = true
    }
    return initialValues
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      initialValues={getInitialValues(entity)}
      render={({
                 handleSubmit,
                 values,
                 form: {
                   mutators: {push}
                 }
               }) => (
        <FormStyled onSubmit={handleSubmit}>
          <Container>
            <InputBox data-cy={'entity-username'}>
              <Field
                name="username"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Username *"
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-name'}>
              <Field
                name="name"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Nome *"
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-surname'}>
              <Field
                name="surname"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="text"
                      label="Cognome *"
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-group'}>
              <Field
                name="groupId"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Gruppo *"
                      {...input}
                    >
                      <option value={''}>{''}</option>
                      {groups && groups.map(group =>
                        <option key={group.id} value={group.id}>{group.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-role'}>
              <Field
                name="roleId"
                validate={required}
              >
                {({input, meta}) => (
                  <>
                    <Select
                      type="text"
                      label="Ruolo *"
                      {...input}
                    >
                      <option value={''}>{''}</option>
                      {roles && roles.map(role =>
                        <option key={role.id} value={role.id}>{role.name}</option>
                      )}
                    </Select>
                    {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'entity-enabled'}>
              <Field
                name="enabled"
                component={Select}
                type="text"
                label="Abilitato"
              >
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </Field>
            </InputBox>
            <ButtonContainer>
              <ButtonRed
                dataCy={'delete-entity'}
                text='Elimina'
                image={imgDelete}
                disabled={!entity.id}
                onClick={(e) => onRemove(e)}
              />
              <ButtonGreen
                dataCy={'save-entity'}
                text='Salva'
                image={imgAdd}
                type='submit'
              />
            </ButtonContainer>
            {
              entity.id && userIsAdmin && !entity.resetPassword &&
              <ResetPasswordContainer>
                <ButtonBlu
                  dataCy={'reset-password'}
                  text='Reset password'
                  image={imgAdd}
                  disabled={!entity.id}
                  onClick={(e) => onResetPassword(e)}
                />
              </ResetPasswordContainer>
            }
            {
              userIsAdmin && entity.resetPassword &&
              <ResetPasswordDisclaimer>
                L'utente deve cambiare la password per poter accedere (<Undo
                onClick={(e) => onResetPassword(e)}>Annulla</Undo>)
              </ResetPasswordDisclaimer>
            }
          </Container>
          <Container>
            <FieldArray name="emails">
              {({fields}) =>
                fields.map((name, index) => (
                  <Field
                    name={`${name}`}
                    validate={required}
                    key={name}
                  >
                    {({input, meta}) => (
                      <div style={{ width: 300 }}>
                        <FieldWithIcon>
                          <Input
                            type="text"
                            label={`Email ${index === 0 ? 'Principale' : index + 1} *`}
                            {...input}
                          />
                          {index > 0 && <RemoveEmail data-cy={'email-remove'} src={imgRemove} alt="edit"
                                                     onClick={() => fields.remove(index)}/>}
                        </FieldWithIcon>
                        {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                      </div>
                    )}
                  </Field>
                ))
              }
            </FieldArray>
            <ButtonContainer>
              <ButtonGreen
                dataCy={'add-email'}
                type="button"
                text='Aggiungi Email'
                image={imgAdd}
                disabled={checkIfEmailIsValorized(values.emails)}
                onClick={() => push('emails', undefined)}
              >
                Aggiungi Email
              </ButtonGreen>
            </ButtonContainer>
          </Container>
        </FormStyled>
      )}
    />
  )
}

/* region STYLE */

const Container = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 400px;
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-y: auto;
  @media(max-width: 768px) {
    width: 100%;
    min-height: 10%;
  }
`

const FormStyled = styled.form`
  height: 100%;
  display: flex;
  flex-direction: row;
  @media(max-width: 768px) {
    width: 100%;
    height: unset;
  }
`

const InputBox = styled.div`
  margin: 15px 0;
  opacity: 0.9;
  width: 280px;
`

const ButtonContainer = styled.div`
  width: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`
const ResetPasswordContainer = styled.div`
  width: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ResetPasswordDisclaimer = styled.div`
  width: 310px;
  text-align: center;
  font-size: 1rem;
  color: #393939;
  padding: 10px 5px;
`

const Undo = styled.span`
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;
  color: #A31D1D;
`
const FieldWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 280px !important;
  margin-top: 15px;
`
const RemoveEmail = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
`
/* endregion */

export default FormUser