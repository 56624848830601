import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import {confirmAlert} from 'react-confirm-alert'
import {ButtonBlu} from '../../common'
import imgChange from '../../../images/ico-checked-white.svg'
import QRCode from 'qrcode.react'
import useEntities from '../../../hooks/useEntities'
import authenticator from 'authenticator'
import notify from '../../../helpers/notify'

const QRCodeBox = ({user}) => {

  const [showQR, setShowQR] = useState(false)
  const [uri, setUri] = useState('')
  const {call} = useEntities('', {withoutEffect: true})

  useEffect(() => {
    if (user) {
      setUri(getUri(user))
    }
  }, [user])

  const getUri = (user) => {
    if (!user) return ''
    return authenticator.generateTotpUri(user.secret, user.email, "FCut", 'SHA1', 6, 90)
  }

  const changeOTP = () => {
    call('put', '/users/change-otp', {})
      .then(({otp}) => {
        setUri(getUri({...user, secret: otp}))
      })
      .catch(e => {
        console.log(e)
        notify('failure', 'Non è stato possibile cambiare il codice OTP')
      })
  }

  const changeOTPConfirm = (e) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cambio del codice OTP',
      message: 'Attenzione, se prosegui dovrai riconfigurare il codice sull\'app Google Authenticator',
      buttons: [
        {label: 'Ok', onClick: () => changeOTP()},
        {label: 'Indietro'}
      ]
    })
  }

  return (
    <Row>
      <QRCodeContainer data-cy={'qr'} pose={showQR ? 'open' : 'closed'}>
        <QRCode value={uri} renderAs="svg" size={256}/>
      </QRCodeContainer>
      <QRCodeDisclaimer>
        <p>Scannerizza questo codice dalla fotocamera o dall'applicazione Google Authenticator sul tuo smartphone per
          ottenere il codice OTP.</p>
        <Row>
          <ButtonBlu
            dataCy={'show-QR'}
            text={showQR ? 'Nascondi codice' : 'Mostra codice'}
            image={imgChange}
            onClick={() => setShowQR(!showQR)}
          />
          <ButtonBlu
            dataCy={'change-Qr'}
            text='Cambia codice'
            image={imgChange}
            onClick={(e) => changeOTPConfirm(e)}
          />
        </Row>
      </QRCodeDisclaimer>
    </Row>
  )
}

export default QRCodeBox

//region Style

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const detailsProps = {
  closed: {filter: 'blur(6px)'},
  open: {filter: 'blur(0px)'}
}

const QRCodeContainer = styled(posed.div(detailsProps))`
  border: 2px solid #000000;
  outline: 1px solid #FFFFFF;
  width: 256px;
  height: 256px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
`

const QRCodeDisclaimer = styled.div`
  margin-left: 50px;
  font-size: 1.2rem;
  opacity: 0.9;
  width: 400px;
`

//endregion