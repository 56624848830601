import React from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import FormCustomer from './Form'
import {getUser} from '../../../reducers'

const mapStateToProps = state => ({
  user: getUser(state)
})

const Customer = ({setTitle, match, history, user: me}) => {

  const id = match.params.ID === 'new' ? null : match.params.ID

  useTitle(setTitle, id ? 'Dettaglio cliente' : 'Nuovo cliente')

  const options = {
    id,
    redirect: history.push,
    withoutEffect: match.params.ID === 'new'
  }
  const {
    entities: customer,
    update,
    create,
    remove,
  } = useEntities('customers', options)
  const {entities: customerTypes} = useEntities('customer-types', {isNotPaginated: true})
  const {entities: regions} = useEntities('regions', {isNotPaginated: true})
  return (
    <PageContainer>
      <FormCustomer
        entity={customer}
        id={id}
        action={id ? update : create}
        customerTypes={customerTypes}
        remove={remove}
        userIsAdmin={me.role.isAdmin}
        regions={regions}
      />
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`

/* endregion */

export default withRouter(connect(mapStateToProps, {setTitle})(Customer))