import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import {useRouteMatch} from 'react-router'
import withPagination from '../../withPagination'
import Filters from './Filters'
import Table from './Table'
import useEntities from '../../../hooks/useEntities'
import {getPageFilters, getUser} from '../../../reducers'

const TablePaginated = withPagination(Filters, Table)


const PageMyTickets = () => {
  const initialLimit = 50
  const match = useRouteMatch()
  const user = useSelector(getUser)
  const filters = useSelector((state) => getPageFilters(state, match.url))
  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'I miei Ticket')

  const options = {initialFilters: filters || {}}

  options.initialFilters.user = user.id
  options.initialFilters.opened = true
  options.initialLimit = initialLimit

  const {entities, total, get} = useEntities('tickets', options)
  const {entities: states} = useEntities('ticket-states', {withoutLoading: true, isNotPaginated: true})
  const {entities: ticketType} = useEntities('ticket-types', {withoutLoading: true, isNotPaginated: true})
  const {entities: priorities} = useEntities('ticket-priorities', {withoutLoading: true, isNotPaginated: true})
  const {entities: categories} = useEntities('ticket-categories', {
    withoutLoading: true,
    isNotPaginated: true,
    initialFilters: {
      withProblemTypes: true
    }
  })
  const {entities: subcategories} = useEntities('ticket-subcategories', {withoutLoading: true, isNotPaginated: true})
  const {entities: problemTypes} = useEntities('ticket-problemtypes', {withoutLoading: true, isNotPaginated: true})
  const {entities: users} = useEntities('users', {withoutLoading: true, isNotPaginated: true})

  const [open, setOpen] = useState(false)
  const [usedFilters, setUsedFilters] = useState(false)

  return (
    <TablePaginated
      match={match}
      entities={entities}
      total={total}
      get={get}
      user={user.id}
      states={states}
      ticketType={ticketType}
      priorities={priorities}
      categories={categories}
      subcategories={subcategories}
      problemTypes={problemTypes}
      users={users}
      initialFilters={options.initialFilters || null}
      open={open}
      setOpen={setOpen}
      usedFilters={usedFilters}
      setUsedFilters={setUsedFilters}
      initialLimit={initialLimit}
      worklist
    />
  )
}

export default PageMyTickets