import React from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import FormTicket from './Form'

import {getUser} from '../../../reducers'

const mapStateToProps = state => ({
  user: getUser(state)
})

const TicketTemplate = ({setTitle, match, user, history}) => {

  const id = match.params.ID === 'new' ? null : match.params.ID

  useTitle(setTitle, id ? 'Dettaglio ticket template' : 'Nuovo ticket template')

  const options = {
    id,
    redirect: history.push,
    withoutEffect: match.params.ID === 'new'
  }
  const {
    entities: ticketTemplate,
    update,
    create,
    remove,
  } = useEntities('ticket-templates', options)

  const entityOption = {
    isNotPaginated: true
  }
  const enabledOption = {
    isNotPaginated: true,
    initialFilters: {enabled: true}
  }

  const {entities: categories} = useEntities('ticket-categories', {
    isNotPaginated: true,
    initialFilters: {
      enabled: true,
      withProblemTypes: true
    }
  })
  const {entities: subcategories} = useEntities('ticket-subcategories', enabledOption)
  const {entities: problemTypes} = useEntities('ticket-problemtypes', enabledOption)
  const {entities: priorities} = useEntities('ticket-priorities', entityOption)
  const {entities: sources} = useEntities('ticket-sources', enabledOption)
  const {entities: types} = useEntities('ticket-types', entityOption)

  return (
    <PageContainer>
      <FormTicket
        entity={ticketTemplate}
        action={id ? update : create}
        remove={remove}
        categories={categories}
        priorities={priorities}
        problemTypes={problemTypes}
        subcategories={subcategories}
        sources={sources}
        userIsAdmin={user.role.isAdmin}
        types={types}
      />
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
/* endregion */

export default withRouter(connect(mapStateToProps, {setTitle})(TicketTemplate))