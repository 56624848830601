import React from 'react'
import {Table, TableContainer, Td, Th, Tr} from "../../../common"

const BySubcategoryTable = ({data, dateArray}) => {

    return (
        <TableContainer paddingTop={'10px'}>
            <Table>
                <thead>
                <tr>
                    <Th/>
                    <Th dataCy={`title-subcategory`} report> Sotto Categoria (Categoria)</Th>
                    {
                        dateArray && dateArray.map(date => <Th report key={date} dataCy={`title-${date}`}> {date} </Th>)
                    }
                    <Th dataCy={`title-total`} report> Totale </Th>
                </tr>
                </thead>
                <tbody data-cy={'report-table'}>
                {
                    data && data.map(reportRow => (
                        <Tr key={`${reportRow.Subcategory}-${reportRow.Category}`}
                            data-cy={`report-row-${reportRow.Subcategory}`}>
                            <Td report/>
                            <Td dataCy={'subcategory'}
                                report>{reportRow.Subcategory ? `${reportRow.Subcategory}${reportRow.Category ? ' (' + reportRow.Category + ')' : ''}` : '-'}</Td>
                            {
                                dateArray && dateArray
                                    .map(date =>
                                        <Td key={`${reportRow.Subcategory}-${date}`}
                                            dataCy={`${reportRow.Subcategory}-${date}`}>
                                            {reportRow[date] || '0'}
                                        </Td>)
                            }
                            <Td dataCy={`total`} report> {reportRow['Totale'] || '0'}  </Td>
                        </Tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    )
}

export default BySubcategoryTable
