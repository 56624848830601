import React from 'react'
import {Table, TableContainer, Td, Th, Tr} from "../../../common"


const ByCategoryTable = ({data, dateArray}) => {

  return (
    <TableContainer paddingTop={'10px'}>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th dataCy={`title-subcategory`} report> Categoria </Th>
          {
            dateArray && dateArray.map(date => <Th report key={date} dataCy={`title-${date}`}> {date} </Th>)
          }
          <Th dataCy={`title-total`} report> Totale </Th>
        </tr>
        </thead>
        <tbody data-cy={'report-table'}>
        {
          data && data.map(reportRow => (
            <Tr key={reportRow.Category} data-cy={`report-row-${reportRow.Category}`}>
              <Td report/>
              <Td dataCy={'category'} report>{reportRow.Category || '-'}</Td>
              {
                dateArray && dateArray
                  .map(date =>
                    <Td key={`${reportRow.Category}-${date}`} dataCy={`${reportRow.Category}-${date}`}>
                      {reportRow[date] || '0'}
                    </Td>)
              }
              <Td dataCy={`total`} report> {reportRow['Totale'] || '0'}  </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default ByCategoryTable