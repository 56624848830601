import React from 'react'
import styled from 'styled-components'
import {TableContainer, Table, Tr, Th, Td, TdImage} from '../../common'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/remove.svg'

const TableTicketCategories = ({entities, order, switchEnabled, ...rest}) => {
  return (
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th {...rest}>Username</Th>
          <Th {...rest}>Nome</Th>
          <Th {...rest}>Cognome</Th>
          <Th {...rest}>Email</Th>
          <Th {...rest}>Gruppo</Th>
          <Th {...rest}>Ruolo</Th>
          <Th {...rest}>Abilitato</Th>
        </tr>
        </thead>
        <tbody data-cy={'users-table'}>
        {entities && entities.map(user =>
          <Tr key={user.id} data-cy={`users-row-${user.id}`}>
            <TdImage tooltip="Modifica utente" editPath={`/users/${user.id}`}/>
            <Td dataCy={'username'}>{user.username || '-'}</Td>
            <Td dataCy={'name'}>{user.name || '-'}</Td>
            <Td dataCy={'surname'}>{user.surname || '-'}</Td>
            <Td notCapitalized dataCy={'email'}>{user.emails ? user.emails.join('; ') : '-'}</Td>
            <Td dataCy={'group'}>{user.group ? user.group.name : '-'}</Td>
            <Td dataCy={'role'}>{user.role ? user.role.name : '-'}</Td>
            <Td>
              {user.enabled
                ? <ImageButton data-cy={'item-enabled'} src={imgEnabled} onClick={() => switchEnabled(user)}/>
                : <ImageButton data-cy={'item-disabled'} src={imgDisabled} onClick={() => switchEnabled(user)}/>
              }
            </Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

//region STYLE

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

//endregion

export default TableTicketCategories