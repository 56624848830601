import React from 'react'
import { TableContainer, Table, Tr, Th, Td, TdImage } from '../../common'

const TableUserGroup = ({ shownEntities, order, switchEnabled, ...rest }) => {
  return(
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th field='id' {...rest}>ID</Th>
          <Th field='name' {...rest}>Nome</Th>
        </tr>
        </thead>
        <tbody data-cy={'group-table'} >
        { shownEntities && shownEntities.map( priority =>
          <Tr key={priority.id} data-cy={`group-row-${priority.id}`} >
            <TdImage tooltip="Modifica gruppo" editPath={`/user-groups/${priority.id}`} />
            <Td dataCy={'id'} >{priority.id || '-'}</Td>
            <Td dataCy={'name'} >{priority.name || '-'}</Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default TableUserGroup