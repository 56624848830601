
// LOGIN ACTIONS
export const LOGIN = 'LOGIN'
export const OTP_NEEDED = 'OTP_NEEDED'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_USER = 'SET_USER'

// PAGES
export const CHANGE_TITLE = 'CHANGE_TITLE'
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const SET_REDIRECT = 'SET_REDIRECT'
export const SET_FILTERS = 'SET_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'
export const SET_PREVIOUS_PATH = 'SET_PREVIOUS_PATH'