import React from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions/'
import {ReactComponent as WarningLogo} from '../../../images/warning.svg'


const NotFound = ({setTitle}) => {

  useTitle(setTitle, 'Nessuna pagina trovata')

  return (
    <PageContainer>
      <WarningLogo width={70} fill={'#CFCBCB'}/>
      <NotFoundText> NOT FOUND </NotFoundText>
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`
const NotFoundText = styled.div`
  margin-top: 5px;

`

/* endregion */

export default connect(null, {setTitle})(NotFound)