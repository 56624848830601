import React from 'react'
import styled from 'styled-components'
import { TableContainer, Table, Tr, Th, Td, TdImage } from '../../common'

const TableTicketPriority = ({ shownEntities, order, switchEnabled, ...rest }) => {
  return(
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th field='id' {...rest}>ID</Th>
          <Th field='name' {...rest}>Nome</Th>
          <Th field='sorting' {...rest}>Ordinamento</Th>
          <Th {...rest}>Colore Testo</Th>
          <Th {...rest}>Colore Sfondo</Th>
        </tr>
        </thead>
        <tbody data-cy={'priority-table'} >
        { shownEntities && shownEntities.map( priority =>
          <Tr key={priority.id} data-cy={`priority-row-${priority.id}`} >
            <TdImage tooltip="Modifica priorità" editPath={`/ticket-priorities/${priority.id}`} />
            <Td dataCy={'id'} >{priority.id || '-'}</Td>
            <Td dataCy={'name'} >{priority.name || '-'}</Td>
            <Td dataCy={'sorting'}>{priority.sorting || '-'}</Td>
            <Td dataCy={'text-color'}>
              { priority.textColor
                ? (<ColorBox color="#FFFFFF" />)
                : (<ColorBox color="#000000" />)
              }
            </Td>
            <Td dataCy={'background-color'}>
              {priority.backgroundColor
                ? <ColorBox color={priority.backgroundColor } />
                : '-'
              }
            </Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default TableTicketPriority

//region Style

const ColorBox = styled.div`
  background: ${({ color }) => color || '#000000'};
  border-radius: 50%;
  width: 22px;
  height: 22px;
`

//endregion