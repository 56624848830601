import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import { getUser } from '../../../reducers'
import {setTitle} from '../../../actions'
import useTitle from '../../../hooks/useTitle'
import QRCodeBox from './QRCode'
import ChangePassword from './ChangePassword'

const Profile = () => {

  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'Profile utente')

  return (
    <Container>
      <QRCodeBox user={user} />
      <ChangePassword user={user} />
    </Container>
  )
}

export default Profile

//region Style

const Container = styled.div`
  margin: 30px 40px 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

//endregion