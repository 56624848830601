import React from 'react'
import DP from 'react-date-picker'
import styled from 'styled-components'

const DatePicker = ({input, label, ...rest}) => {
  return (
    <div>
      <CustomLabel>{label}</CustomLabel>
      <DP {...input} {...rest} locale='it' format='dd-MM-yyyy'
          value={input.value}
      />
    </div>
  )
}
const CustomLabel = styled.label`
  opacity: 0.9;
  font-size: 0.95rem;
  display: block;
`

export {DatePicker}