import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import useTitle from '../../../hooks/useTitle'
import { setTitle } from '../../../actions/'


const AddressTypes = ({ setTitle }) => {

  useTitle(setTitle, 'Tipi di indirizzo')

  return(
    <PageContainer>
      <div> ADDRESS TYPES </div>
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

/* endregion */

export default connect(null, { setTitle })(AddressTypes)