import React, {useState} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {useReactToPrint} from 'react-to-print'
import {ButtonGreen} from '../../common'
import useEntities from '../../../hooks/useEntities'
import imgAdd from '../../../images/ico-add.svg'
import imgDownload from '../../../images/download-arrow.svg'
import imgSendMail from '../../../images/send-mail.svg'
import imgPrint from '../../../images/printer.svg'

import imgEdit from '../../../images/copy-icon-white.svg'
import imgDelete from '../../../images/close.svg'
import {confirmAlert} from "react-confirm-alert";


const History = ({history, setOpenModal, setOpenModalChangeState, setHistoryId,user, refreshData, isClosed, customer, ticket, componentRef}) => {

  const [title] = useState(document.title)

  const {getOneFile: download} = useEntities('ticket-histories', {withoutEffect: true})
  const {call} = useEntities('', {withoutEffect: true})


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      const surname = customer.surname || customer.businessName
      const name = customer.name || customer.structureName
      const ticketId = ticket.id
      document.title = `${surname} ${name} - ID ${ticketId}`
    },
    onAfterPrint: () => {
      document.title = title
    }
  })
  const mailTo = () => {
    const surname = customer.surname || customer.businessName
    const name = customer.name || customer.structureName
    const ticketId = ticket.id
    const subject = encodeURI(`subject=${surname} ${name} - ID ${ticketId}`)
    window.location.href = `mailto: ?${subject}&body=${history && history[0] ? history[0].note.replace(/(<([^>]+)>)/gi, "") : ''}`
  }

  const onRemove = (e, historyId) => {
    e.preventDefault()
    confirmAlert({
      title: 'Cancellazione Stato ticket',
      message: 'Vuoi davvero cancellare lo Stato? La cancellazione andrà a buon fine solo se è presente almeno un altro Stato nel ticket',
      buttons: [
        {
          label: 'Si', onClick: async () => {
            try {
              await call('delete', `/tickets/${ticket.id}/history/${historyId}`)
            } catch (e) {
              console.log(e)
            }
            refreshData()
          }
        },
        {label: 'No'}
      ]
    })
  }

  return (
    <Container data-cy={'history-table'}>
      <Buttons>
        {!isClosed &&
        <ButtonGreen
          onClick={() => setOpenModal(true)}
          dataCy={'add-history'}
          text='Nuovo Stato'
          image={imgAdd}
        />
        }
        <ButtonGreen
          onClick={mailTo}
          dataCy={'new-email'}
          text='Nuova Email'
          image={imgSendMail}
        />
        <ButtonGreen
          onClick={() => download(`tickets/${ticket.id}/attachments`, `${ticket.id}.zip`)}
          dataCy={'download-all'}
          text='Scarica allegati'
          image={imgDownload}
          disabled={history && history.reduce((acc, curr) => (curr.attachments.length || 0) + acc, 0) === 0}
        />
        <ButtonGreen
          onClick={handlePrint}
          dataCy={'print-ticket'}
          text='Stampa ticket'
          image={imgPrint}
        />
      </Buttons>
      {history && history.map(item =>
        <Item key={item.id} data-cy={`history-${item.id}`}>
          <Title data-cy={`title-${item.id}`}>
            {moment(item.created).format("HH:mm DD-MM-YYYY")}
            {(user && user.role && user.role.isAdmin) && <ButtonContainer>
              <ButtonHistory
                onClick={() => {
                  setOpenModalChangeState(true)
                  setHistoryId(item.id)
                }}
              >
                <ButtonImg src={imgEdit}/>
              </ButtonHistory>
              {
                history.length > 1 ?
                  <ButtonHistory
                    onClick={(e) => {
                      onRemove(e, item.id)
                    }}
                  >
                    <ButtonImg src={imgDelete}/>
                  </ButtonHistory>
                  :
                  <NoButton/>
              }

            </ButtonContainer>}
          </Title>
          <Body data-cy={`body-${item.id}`}>
            <BodyRow>
              <BodyRowField>Creatore</BodyRowField>
              <BodyRowValue data-cy={`creator-${item.id}`}>{item.creator.name} {item.creator.surname}</BodyRowValue>
            </BodyRow>
            <BodyRow>
              <BodyRowField>Assegnato a</BodyRowField>
              <BodyRowValue data-cy={`assignTo-${item.id}`}>{item.assignTo.name} {item.assignTo.surname}</BodyRowValue>
            </BodyRow>
            <BodyRow>
              <BodyRowField>Stato</BodyRowField>
              <BodyRowValue data-cy={`state-${item.id}`}>{item.state.name}</BodyRowValue>
            </BodyRow>
            <BodyRow>
              <BodyRowField>Note</BodyRowField>
              <BodyRowValue data-cy={`note-${item.id}`} dangerouslySetInnerHTML={{__html: item.note}}/>
            </BodyRow>
            {
              (item.attachments && item.attachments.length > 0) &&
              <BodyRow>
                {item.attachments.length > 1 &&
                <DownloadForHistory>
                  <ButtonGreen
                    onClick={() => download(`tickets/${ticket.id}/history/${item.id}/attachments`, `${ticket.id}-${item.id}.zip`)}
                    dataCy={'download-for-history'}
                    text='Scarica tutti'
                    image={imgDownload}
                  />
                </DownloadForHistory>
                }
                <BodyRowField>Allegati</BodyRowField>
                <BodyRowValue>
                  {
                    item.attachments.map(a => (
                      <BodyRowValue key={a.id} data-cy={`attachment-${a.id}`} flex attachments>
                        <DownloadButton
                          onClick={() => download(`tickets/${ticket.id}/history/${item.id}/attachments/${a.id}`, a.filename)}
                          data-cy={`download-attachment-${a.id}`}
                        >
                          <DownloadIcon src={imgDownload}
                          />
                        </DownloadButton>
                        <FileInfo>
                          {`${a.filename}`}
                        </FileInfo>
                      </BodyRowValue>)
                    )
                  }
                </BodyRowValue>
              </BodyRow>
            }
          </Body>
        </Item>
      )}
    </Container>
  )
}

export default History

//region Style

const Buttons = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  margin: 0 10px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    height: unset;
  }
`

const Item = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 450px;

  &:not(:first-child) {
    margin-top: 20px;
  }
  
  @media print {
    justify-content: center;
  }
`

const Title = styled.div`
  box-sizing: border-box;
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 1.1rem;
  width: 100%;
  background-color: #218FC1;
  padding: 5px 10px;
  text-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  flex-direction: column;
  position: relative;
`
const ButtonContainer = styled.div`
  position: absolute;
  padding: 5px;
  right: 0;
  top: 0;

`
const ButtonHistory = styled.button`
  border: 0;
  cursor: pointer;
  align-items: center;
  flex-wrap: nowrap;
  color: #FFFFFF;
  background: #218FC1;
  
  :focus {
    outline: none;
  }
`
const NoButton = styled.div`
  border: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline;
`
const ButtonImg = styled.img`
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
`
const Body = styled.div`
  box-sizing: border-box;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 5px 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

const BodyRow = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 0;
  font-size: 0.85rem;
  min-width: 450px;

  &:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
  }
`

const BodyRowField = styled.div`
  width: 120px;
  font-weight: bold;
`

const BodyRowValue = styled.div`
  text-align: left;
  flex: 1;
  ${({flex}) => flex && `display: flex;`};
  ${({flex}) => flex && `justify-content: flex-start;`}
  p {
    margin: 0;
    padding: 0;
  }
`
const FileInfo = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    @media (min-width: 1650px) {
      width: 200px;
    }
    @media (max-width: 1650px) {
      width: 300px;
    }
`

const DownloadButton = styled.button`
  border: 0;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  margin-right: 10px;
`
const DownloadIcon = styled.img`
  width: 23px;
`

const DownloadForHistory = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`

//endregion
