import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Field, Form} from 'react-final-form'
import styled from 'styled-components'
import axios from 'axios'
import {baseUrl} from '../../../constants/config'
import {Input, ErrorMessage} from '../../common'
import {setLoginInformations} from '../../../actions'
import {getError, getUser, getToken} from '../../../reducers'
import imgLogo from '../../../images/logo.png'
import {validatePassword} from '../../../helpers/formErrors'
import {confirmAlert} from 'react-confirm-alert'
import queryString from 'query-string'

const ResetPassword = ({history, location}) => {

  const loginError = useSelector(getError)
  const user = useSelector(getUser)
  const token = useSelector(getToken)
  const dispatch = useDispatch()

  let tokenFromUrl

  useEffect(() => {
    tokenFromUrl = queryString.parse(location.search).token
    const resetPassword = user ? user.resetPassword : false
    if (!resetPassword && !tokenFromUrl) {
      history.push('/login')
    }
  }, [])

  const onSubmit = async values => {
    const password = values.password
    const passwordConfirm = values.confirm
    try {
      let response
      if (tokenFromUrl) {
        response = await axios.put(
          `${baseUrl}/reset-password`,
          {password, passwordConfirm},
          {headers: {'Authorization': `Bearer ${tokenFromUrl}`}})
      } else {
        response = await axios.put(
          `${baseUrl}/users/${user.id}/change-password`,
          {password, passwordConfirm},
          {headers: {'Authorization': `Bearer ${token || localStorage.getItem('token')}`}})
      }
      let newToken, newUser
      localStorage.setItem('token', response.data.token)
      newToken = response.data.token
      newUser = response.data.user
      dispatch(setLoginInformations({...newUser, resetPassword: false}, newToken))
      setTimeout(() => history.push('/'), 100)
    } catch (e) {
      console.log(e)
      const message = (e.response && e.response.data)
        ? `Il server risponde: ${e.response.data.message}`
        : 'Qualcosa è andato storto'
      confirmAlert({
        title: 'Errore',
        message,
        buttons: [
          {label: 'Ok'}
        ]
      })
    }
  }

  return (
    <LoginContainer>
      <Logo src={imgLogo} alt='logo'/>
      <Disclaimer data-cy={'disclaimer-force-reset'}>
        Un amministratore ha richiesto il cambio della password. <br/>
        Modificala qui per poter accedere:
      </Disclaimer>
      <Form
        onSubmit={onSubmit}
        validateOnBlur
        validate={values => {
          const errors = {}
          if (!values.password) {
            errors.username = 'Obbligatorio'
          }
          if (!values.confirm) {
            errors.confirm = 'Obbligatorio'
          } else if (values.password !== values.confirm) {
            errors.confirm = 'Le password non corrispondono'
          }
          return errors
        }}
        render={({handleSubmit}) => (
          <FormContainer onSubmit={handleSubmit}>
            <InputContainer
              data-cy={'password'}
            >
              <Field
                name="password"
                validate={validatePassword(true)}
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="password"
                      label="Password"
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage color="#000000" message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputContainer>
            <InputContainer
              data-cy={'confirm-password'}
            >
              <Field
                name="confirm"
              >
                {({input, meta}) => (
                  <>
                    <Input
                      type="password"
                      label="Conferma"
                      {...input}
                    />
                    {meta.error && meta.touched && <ErrorMessage color="#000000" message={meta.error}/>}
                  </>
                )}
              </Field>
            </InputContainer>
            {loginError
              ? (<ButtonError data-cy="login-error">
                Login Errato
              </ButtonError>)
              : (<ButtonLogin type='submit' onClick={handleSubmit} data-cy="button-login">
                Imposta
              </ButtonLogin>)
            }
          </FormContainer>
        )}
      />
    </LoginContainer>
  )
}

/* region STYLE */

const LoginContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 550px;
  width: 350px;
  background: #218FC1;
  border-radius: 15px;
  box-shadow: 0 0 16px 1px rgba(0,0,0,0.75);
  border: 1px solid #818181;
`

const Logo = styled.img`
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  margin-top: 15px;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 257px;
`

const InputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 35px;
  color: #FFFFFF;
`

const Button = styled.button`
  border: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: bold;
  opacity: 0.9;
  font-size: 1.2rem;
  height: 70px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  cursor: pointer;
`

const ButtonLogin = styled(Button)`
  background: #273A7D;
`

const ButtonError = styled(Button)`
  background: #941212;
`

const Disclaimer = styled.div`
  text-align: center;
  color: #FFFFFF;
  padding: 5px 15px;
`

/* endregion */

export default ResetPassword