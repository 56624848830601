import {useState, useEffect, useCallback} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {baseUrl, itemsPerPage} from '../constants/config'
import {LOGOUT, START_LOADING, STOP_LOADING} from '../actions/types'
import makeHeader from '../helpers/makeHeader'
import formatValues from '../helpers/formatValues'
import notify from '../helpers/notify'
import {getLoading} from '../reducers'
import store from '../store'

let requestsCounter = 0

axios.interceptors.request.use((config) => {
  if (config.params && config.params.withoutLoading)
    return config
  requestsCounter++
  return config
})

axios.interceptors.response.use(
  (response) => {
    if (response.config.params && response.config.params.withoutLoading) {
      requestsCounter === 0 && store.dispatch({type: STOP_LOADING})
      return response
    }
    // Do something with response data
    --requestsCounter === 0 && store.dispatch({type: STOP_LOADING})
    return response
  }, (error) => {
    // Do something with response error
    --requestsCounter === 0 && store.dispatch({type: STOP_LOADING})
    if (error && error.response && error.response.status === 401) {
      console.log("Token expired")
      localStorage.clear()
      store.dispatch({type: LOGOUT})
      window.location.hfref = '/login'
    }
    throw(error)
  })

/**
 *  Hook for rest call's management
 * @param {string}    entity
 * @param {object}    [options]
 * @param {string}    [options.id]
 * @param {boolean}   [options.isNotPaginated]
 * @param {function}  [options.redirect]
 * @param {boolean}   [options.withoutEffect]
 * @param {object}    [options.initialFilters]
 * @param {int}       [options.initialItemPerPage]
 * @param {int}       [options.initialPage]
 * @param {boolean}    [options.withoutNotifications]
 * @return {{
 *    total:        number,
 *    setTotal:     function
 *    entities:     Array,
 *    setEntities:  function,
 *    get:          function,
 *    getOne:       function,
 *    update:       function,
 *    asyncUpdate:  function,
 *    create:       function,
 *    asyncCreate:  function
 *    remove:       function
 *    asyncRemove:  function
 *    associate:    function
 *    disassociate: function
 * }}
 */
export default (entity, options = {}) => {

  const [entities, setEntities] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const dispatch = useDispatch()
  const loadingFromState = useSelector(getLoading)
  const history = useHistory()

  const {id, isNotPaginated, redirect, withoutEffect, withoutNotifications, withoutLoading = false, initialLimit = itemsPerPage} = options

  useEffect(() => {
    if (!withoutEffect) {
      if (id) {
        getOne()
      } else {
        options.initialFilters
          ? get(options.initialFilters, options.initialPage, options.initialItemPerPage)
          : get()
      }
    }
  }, [id])

  const get = useCallback((filters, page, itemsNumberPerPage) => {
    let url = `${baseUrl}/${entity}?`
    // Pagination
    if (!isNotPaginated) {
      const pageSize = itemsNumberPerPage ? itemsNumberPerPage : initialLimit
      url += `limit=${pageSize}`
      const pageNumber = page ? (page - 1) : 0
      const start = pageNumber * pageSize
      url += `&start=${start}`
    }
    // Filters
    if (filters) {
      const newFilters = formatValues(filters)
      const arrayFilters = []
      Object.entries(newFilters).forEach(([key, value]) => {
        if (value === null || value === undefined) {
          return
        }
        arrayFilters.push(`${key}=${encodeURIComponent(value.toString())}`)
      })
      if (arrayFilters.length > 0) {
        url += '&'
        url += arrayFilters.join('&')
      }
    }
    !withoutLoading && dispatch({type: START_LOADING})
    setLoading(true)
    axios.get(url, makeHeader(null, { withoutLoading }))
      .then(({data}) => {
        setEntities(data.payload)
        setTotal(data.total)
      })
      .catch(err => {
        console.log(`Retrieving ${entity} error`)
        console.log(err)
        setEntities([])
        setTotal(0)
        notify('failure')
      })
      .finally(() => setLoading(false))
  }, [entity, options])

  const asyncGet = useCallback(async (filters, page, itemsNumberPerPage) => {
    let url = `${baseUrl}/${entity}?`
    // Pagination
    if (!isNotPaginated) {
      const pageSize = itemsNumberPerPage ? itemsNumberPerPage : itemsPerPage
      url += `limit=${pageSize}`
      const pageNumber = page ? (page - 1) : 0
      const start = pageNumber * pageSize
      url += `&start=${start}`
    }
    // Filters
    if (filters) {
      const newFilters = formatValues(filters)
      const arrayFilters = []
      Object.entries(newFilters).forEach(([key, value]) => {
        arrayFilters.push(`${key}=${encodeURIComponent(value.toString())}`)
      })
      url += '&'
      url += arrayFilters.join('&')
    }
    !withoutLoading && dispatch({type: START_LOADING})
    setLoading(true)
    try {
      const {data} = await axios.get(url, makeHeader(null, { withoutLoading }))
      setEntities(data.payload)
      setTotal(data.total)
      return data.payload
    } catch (err) {
      console.log(`Retrieving ${entity} error`)
      console.log(err)
      setEntities([])
      setTotal(0)
      notify('failure')
    } finally {
      setLoading(false)
    }
  }, [entity, options])

  const getOne = useCallback(() => {
    let url = `${baseUrl}/${entity}/${id}`
    dispatch({type: START_LOADING})
    axios.get(url, makeHeader(options.responseType))
      .then(({data}) => {
        setEntities(data.payload)
        setTotal(1)
      })
      .catch(err => {
        console.log(`Retrieving ${entity} error`)
        console.log(err)
        setEntities([])
        setTotal(0)
        notify('failure')
      })
  }, [entity, options])
  const getOneFile = (url, itemName, filters) => {
    let enrichedUrl = `${baseUrl}/${url}`

    //TODO
    if (filters) {
      const newFilters = formatValues(filters)
      const arrayFilters = []
      Object.entries(newFilters).forEach(([key, value]) => {
        if (value)
          arrayFilters.push(`${key}=${encodeURIComponent(value.toString())}`)
      })
      enrichedUrl += '?&'
      enrichedUrl += arrayFilters.join('&')
    }
    dispatch({type: START_LOADING})
    axios.get(enrichedUrl, makeHeader('blob'))
      .then(({data}) => {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${itemName}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setTotal(1)
      })
      .catch(err => {
        console.log(`Retrieving ${entity} error`)
        console.log(err)
        setEntities([])
        setTotal(0)
        notify('failure')
      })
  }

  const update = (values, ID, action) => {
    let url = `${baseUrl}/${entity}/${ID || id}`
    if (action) url += `/${action}`
    const newValues = formatValues(values)
    axios.put(url, newValues, makeHeader())
      .then((data) => {
        !withoutNotifications && notify('success')
      })
      .catch(err => {
        console.log(`Update ${entity} error`)
        console.log(err)
        !withoutNotifications && notify('error')
      })
  }

  /**
   *
   * @param values {Object}
   * @param ID {number}
   * @param options {Object}
   * @param options.action {string}
   * @param options.throwError {boolean}
   * @returns {Promise<void>}
   */
  const asyncUpdate = async (values, ID, {action = null, throwError = false} = {}) => {
    setLoading(true)
    let url = `${baseUrl}/${entity}/${ID || id}`
    if (action) url += `/${action}`
    const newValues = formatValues(values)
    try {
      const result = await axios.put(url, newValues, makeHeader())
      !withoutNotifications && notify('success')
      return result
    } catch (err) {
      console.log(`Update ${entity} error`)
      console.log(err)
      !withoutNotifications && notify('failure')
      if (throwError) throw err
    } finally {
      setLoading(false)
    }
  }

  const create = (values, redirectUrl) => {
    let url = `${baseUrl}/${entity}`
    values = formatValues(values)
    axios.post(url, values, makeHeader())
      .then(({data}) => {
        if (redirect && data.payload.id) {
          options.id = data.payload.id
          let redUrl = redirectUrl || ''
          redUrl += `/${entity}/${data.payload.id}`
          redirect(redUrl)
        }
        !withoutNotifications && notify('success')
        return data.payload
      })
      .catch(err => {
        console.log(`Update ${entity} error`)
        console.log(err)
        !withoutNotifications && notify('failure')
      })
  }

  const asyncCreate = async (values, redirectUrl, {throwError = false} = {}) => {
    let url = `${baseUrl}/${entity}`
    const newValues = formatValues(values)
    try {
      const {data} = await axios.post(url, newValues, makeHeader())
      if (redirect && data.payload.id) {
        options.id = data.payload.id
        let redUrl = redirectUrl || ''
        redUrl += `/${entity}/${data.payload.id}`
        redirect(redUrl)
      }
      !withoutNotifications && notify('success')
      return data.payload
    } catch (err) {
      console.log(`Update ${entity} error`)
      console.log(err)
      if (throwError) throw err
      !withoutNotifications && notify('failure')
    }
  }

  const remove = (ID, nextUrl) => {
    let url = `${baseUrl}/${entity}/${ID || id}`
    axios.delete(url, makeHeader())
      .then(({data}) => {
        if (redirect) {
          if (nextUrl)
            redirect(`/${nextUrl}/`)
          else
            redirect(`/${entity}/`)
        }
        !withoutNotifications && notify('success')
      })
      .catch(err => {
        console.log(`Delete ${entity} error`)
        console.log(err)
        !withoutNotifications && notify('failure')
      })
  }

  const asyncRemove = async (ID, {action, throwError = false} = {}) => {
    setLoading(true)
    let url = `${baseUrl}/${entity}/${ID || id}`
    if (action) url += `/${action}`
    try {
      const {data} = await axios.delete(url, makeHeader())
      if (redirect) {
        redirect(`/${entity}/`)
      }
      !withoutNotifications && notify('success')
    } catch (err) {
      console.log(`Delete ${entity} error`)
      console.log(err)
      !withoutNotifications && notify('failure')
      if (throwError) throw err
    } finally {
      setLoading(false)
    }
  }

  const associate = async (ID, other, otherID) => {
    let url = `${baseUrl}/${entity}/${ID}/${other}/${otherID}`
    try {
      const {data} = await axios.post(url, {}, makeHeader())
      if (redirect && data.payload.id) {
        options.id = data.payload.id
        redirect(`/${entity}/${data.payload.id}`)
      }
      !withoutNotifications && notify('success')
    } catch (err) {
      console.log(`Update ${entity} error`)
      console.log(err)
      !withoutNotifications && notify('failure')
    }
  }

  const disassociate = async (ID, other, otherID) => {
    let url = `${baseUrl}/${entity}/${ID}/${other}/${otherID}`
    try {
      const {data} = await axios.delete(url, makeHeader())
      if (redirect && data.payload.id) {
        options.id = data.payload.id
        redirect(`/${entity}/${data.payload.id}`)
      }
      !withoutNotifications && notify('success')
    } catch (err) {
      console.log(`Update ${entity} error`)
      console.log(err)
      !withoutNotifications && notify('failure')
    }
  }

  const call = async (method, path, payload, redirectTo) => {
    const signature = [`${baseUrl}${path}`]
    if (payload) signature.push(payload)
    try {
      const {data} = await axios[method](...signature, makeHeader())
      !withoutNotifications && notify('success')
      redirectTo && redirect(`${redirectTo}/${data.payload.id}`)
      return data
    } catch (e) {
      console.log(`Update ${entity} error`)
      console.log(e)
      !withoutNotifications && notify('failure')
      throw e
    }
  }

  return {
    entities,
    setEntities,
    total,
    setTotal,
    get,
    asyncGet,
    getOne,
    getOneFile,
    update,
    asyncUpdate,
    create,
    asyncCreate,
    remove,
    asyncRemove,
    associate,
    disassociate,
    call,
    loading
  }
}