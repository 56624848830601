import React from 'react'
import styled from 'styled-components'
import { TableContainer, Table, Tr, Th, Td, TdImage } from '../../common'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/remove.svg'

const TableTicketCategories = ({ entities, order, switchEnabled, ...rest }) => {
  return(
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th/>
          <Th field='label' {...rest}>ID</Th>
          <Th field='division' {...rest}>Nome</Th>
          <Th field='division' {...rest}>Abilitata</Th>
        </tr>
        </thead>
        <tbody data-cy={'categories-table'} >
        { entities && entities.map( category =>
          <Tr key={category.id} data-cy={`categories-row-${category.id}`} >
            <TdImage tooltip="Modifica categoria" editPath={`/ticket-categories/${category.id}`} />
            <Td dataCy={'id'} >{category.id || '-'}</Td>
            <Td dataCy={'name'} >{category.name || '-'}</Td>
            <Td>
              {category.enabled
                ? <ImageButton data-cy={'item-enabled'} src={imgEnabled} onClick={() => switchEnabled(category)} />
                : <ImageButton data-cy={'item-disabled'} src={imgDisabled} onClick={() => switchEnabled(category)} />
              }
            </Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

//region STYLE

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

//endregion

export default TableTicketCategories