import Excel from 'exceljs'

const mesi = {
  Jan: 'Gennaio',
  Feb: 'Febbraio',
  Mar: 'Marzo',
  Apr: 'Aprile',
  May: 'Maggio',
  Jun: 'Giugno',
  Jul: 'Luglio',
  Aug: 'Agosto',
  Sep: 'Settembre',
  Oct: 'Ottobre',
  Nov: 'Novembre',
  Dec: 'Dicembre',
  Totali: 'Totali'
}
const colorArray = [
  'FF6633', 'FFB399', 'FFFF99', '00B3E6',
  'E6B333', '3366E6', '999966', '99FF99', 'B34D4D',
  '80B300', '809900', 'E6B3B3', '6680B3', '66991A',
  'CCFF1A', 'FF1A66', 'E6331A', '33FFCC',
  '66994D', 'B366CC', '4D8000', 'B33300', 'CC80CC',
  '66664D', '991AFF', '4DB3FF', '1AB399',
  'E666B3', '33991A', 'CC9999', 'B3B31A', '00E680',
  '4D8066', '809980', 'E6FF80', '1AFF33', '999933',
  'FF3380', 'CCCC00', '66E64D', '4D80CC', '9900B3',
  'E64D66', '4DB380', 'FF4D4D', '99E6E6', '6666FF'
]

const numberToLetters = (number) => {

  let firstLetterCode = Math.trunc(number / 26) + 64
  let secondLetterCode = (number % 26) + 64
  if (secondLetterCode < 65) {
    firstLetterCode = firstLetterCode - 1
    secondLetterCode = 64 + 26
  }

  const firstLetter = firstLetterCode > 64 ? String.fromCharCode(firstLetterCode) : ''
  const secondLetter = String.fromCharCode(secondLetterCode)
  return `${firstLetter || ''}${secondLetter}`

}


export const createExcel = async (reportName, data, keys, year) => {

  const groups = []

  const header = []

  const report = new Excel.Workbook()
  report.creator = 'Fcut'
  report.created = new Date()

  const page = report.addWorksheet(reportName)

  //region Header
  keys.forEach((k, index) => {

    const cell = {
      key: k,
      width: 15
    }

    if (k === 'Totale Aperti') {
      groups.push('Totale')
      cell.header = 'Ticket Creati'
    }
    if (k === 'Totale Chiusi') {
      cell.header = 'Ticket Chiusi'
    }
    if (k === 'Month/Year') {
      cell.header = year
    }
    if (k.includes('Opened')) {
      cell.header = 'Ticket Aperti'
      const group = k.replace('Ticket Opened by ', '')
      groups.push(group)
    }
    if (k.includes('Closed')) {
      cell.header = 'Ticket Chiusi'
    }
    if (k.includes('Res. Rate %')) {
      cell.header = 'Res. Rate %'
    }

    header.push(cell)
  })

  page.columns = header

  page.getRow(1).eachCell((c, index) => {
    if (c.text !== '') {
      const backGround = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '008E8C'}
      }
      const font = {
        color: {argb: "FFFFFF"},
        bold: true
      }
      const alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
      }
      const border = {
        left: {},
        bottom: {},
        right: {},
        top: {}
      }
      if (index === 1) {
        border.bottom.style = 'thin'
        border.right.style = 'medium'
        border.top.style = 'medium'
        alignment.horizontal = 'right'
      }
      if (index === 2) {
        backGround.fgColor.argb = colorArray[0]
        border.bottom.style = 'medium'
        border.right.style = 'thin'
        border.top.style = 'thin'
        border.left.style = 'medium'
        font.color.argb = '000000'
      }
      if (index === 3) {
        backGround.fgColor.argb = colorArray[0]
        border.bottom.style = 'medium'
        border.right.style = 'medium'
        border.top.style = 'thin'
        border.left.style = 'thin'
        font.color.argb = '000000'
      }
      if (index > 3) {
        switch (index % 3) {
          case (1): {
            backGround.fgColor.argb = colorArray[Math.trunc(index / 3)]
            border.bottom.style = 'medium'
            border.right.style = 'thin'
            border.top.style = 'thin'
            border.left.style = 'medium'
            font.color.argb = '000000'
            break
          }
          case (2): {
            backGround.fgColor.argb = colorArray[Math.trunc(index / 3)]
            border.bottom.style = 'medium'
            border.right.style = 'thin'
            border.top.style = 'thin'
            border.left.style = 'thin'
            font.color.argb = '000000'
            break
          }
          case (0): {
            backGround.fgColor.argb = colorArray[Math.trunc(index / 3) - 1]
            border.bottom.style = 'medium'
            border.right.style = 'medium'
            border.top.style = 'thin'
            border.left.style = 'thin'
            font.color.argb = '000000'
            break
          }
          default:
            console.log('no cases')
        }
      }

      c.fill = backGround
      c.font = font
      c.alignment = alignment
      c.border = border
    }
  })
  //endregion
  //region DATA
  const elaborateData = data.map(d => {
    const el = d
    header.forEach(h => {
      if (h.key.includes('Res Rate % for')) {
        if (d[h.key] === undefined || d[h.key] === null) {
          d[h.key] = '0.00%'
        }
      } else if (d[h.key] === undefined || d[h.key] === null)
        d[h.key] = 0
    })
    const [month] = d['Month/Year'].split(' ')
    el['Month/Year'] = mesi[month]
    return el
  })
  page.addRows(elaborateData)
  //endregion
  //region Column
  for (let i = 1; i <= header.length; i++) {
    page.getColumn(i).eachCell((c, index) => {
        if (index > 1 && index < data.length + 4) {
          c.style = {
            alignment: {horizontal: 'right'},
            border:
              {
                top: {style: 'thin'},
                bottom: {style: 'thin'},
                left: {},
                right: {}
              },
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {}
            }
          }
          if (i === 1) {
            c.style.border.right.style = 'medium'
            c.style.font = {
              color: {argb: "FFFFFF"},
              bold: true
            }
            c.style.fill.fgColor.argb = '008E8C'
          }
          if (i === 2) {
            c.style.border.right.style = 'medium'
            c.style.fill.fgColor.argb = 'D9D9D9'
          }
          if (i === 3) {
            c.style.border.left.style = 'medium'
          }
          if (i > 3) {
            switch (i % 3) {
              case (1): {
                c.style.border.left.style = 'medium'
                c.style.border.right.style = 'thin'

                c.style.fill.fgColor.argb = 'D9D9D9'
                break
              }
              case(2): {
                c.style.border.left.style = 'thin'
                c.style.border.right.style = 'thin'

                c.style.fill.fgColor.argb = 'EAEAEA'
                break
              }
              case(0): {
                c.style.border.left.style = 'thin'
                c.style.border.right.style = 'medium'
                break
              }
              default:
                console.log('no cases')
            }
          }
        }
      }
    )
  }

//endregion

  page.insertRow(1, '')
  page.insertRow(1, '')
  page.insertRow(1, '')

  //region ROW Titolo
  page.mergeCells(`A1:${numberToLetters(header.length)}1`)
  const title = page.getCell('A1')
  title.alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true
  }
  title.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'FFFF00'}
  }
  title.border = {
    left: {style: 'medium'},
    bottom: {style: 'medium'},
    right: {style: 'medium'}
  }
  title.font = {
    bold: true
  }
  title.value = `ANALISI TICKET MSD - Anno ${year}`
//endregion
  //region ROW Groups
  groups.forEach((g, index) => {

    let startIndex
    let endIndex
    if (index === 0) {
      startIndex = `B3`
      endIndex = `C3`
    } else {
      startIndex = `${numberToLetters(1 + (index * 3))}3`
      endIndex = `${numberToLetters((index * 3) + 3)}3`
    }

    page.mergeCells(`${startIndex}:${endIndex}`)
    const group = page.getCell(`${startIndex}`)
    group.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: colorArray[index]}
    }
    group.font = {
      bold: true
    }
    group.border = {
      left: {style: 'medium'},
      bottom: {style: 'thin'},
      right: {style: 'medium'},
      top: {style: 'medium'}
    }
    group.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true
    }
    group.value = g
  })
//endregion
  //region TOTALE
  page.getRow(page.lastRow.number).eachCell((c, index) => {
      c.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFFF00'}
      }
      if (index === 1) {
        c.border = {
          bottom: {style: 'medium'},
          top: {style: 'medium'},
          right: {style: 'medium'},
        }
      }
      if (index === 2) {
        c.style.border = {
          bottom: {style: 'medium'},
          left: {style: 'medium'},
          right: {style: 'thin'},
          top: {style: 'medium'},
        }
      }
      if (index === 3) {
        c.style.border = {
          bottom: {style: 'medium'},
          left: {style: 'thin'},
          right: {style: 'medium'},
          top: {style: 'medium'},
        }
      }
      if (index > 3) {
        switch (index % 3) {
          case (1): {
            c.style.border = {
              bottom: {style: 'medium'},
              left: {style: 'medium'},
              right: {style: 'thin'},
              top: {style: 'medium'},
            }
            break
          }
          case (2): {
            c.style.border = {
              bottom: {style: 'medium'},
              right: {style: 'thin'},
              left: {style: 'thin'},
              top: {style: 'medium'},
            }
            break
          }
          case (0): {
            c.style.border = {
              bottom: {style: 'medium'},
              right: {style: 'medium'},
              left: {style: 'thin'},
              top: {style: 'medium'},
            }
            break
          }
          default:
            console.log('no cases')
        }
      }
      c.font = {
        bold: true
      }
    })
  //endregion
  const buf = await report.xlsx.writeBuffer()

  return new Blob(
    [buf],
    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
  )
}