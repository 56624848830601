import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Media from 'react-media'
import styled from 'styled-components'
import { bounceInLeft } from 'react-animations'
import {MenuButton} from "../common/MenuButon";

const Navbar = (props) => {

  const {
    menuItems,
    backgroundPrimaryColor,
    backgroundSecondaryColor,
    textColor,
    hasTopBar,
    collapsedImage,
    expandedImage,
    user,
    collapsed,
    setCollapsed
  } = props

  const [itemsExpanded, setItemsExpanded] = useState([])
  const [itemOver, setItemOver] = useState(null)

  const handleMenuItemClick = (e, menuItem) => {
    if (!menuItem.submenu) return
    // If it's a dropdown
    e.preventDefault()
    if (itemsExpanded.includes(menuItem.label)) {
      setItemsExpanded(itemsExpanded.filter(ie => ie !== menuItem.label))
    } else {
      setItemsExpanded([...itemsExpanded, menuItem.label])
    }
  }

  return (
    <Media query={{maxWidth: 1024}}>
      {matches =>
        <NavbarContainer
          collapsed={collapsed || matches ? 1 : 0}
          backgroundcolor={backgroundPrimaryColor}
          top={hasTopBar ? hasTopBar : 0}
          data-cy={`navbar-container`}
        >
          <MenuButton
            collapsed={collapsed || matches}
            setCollapsed={setCollapsed}
          />
          {menuItems && menuItems.map((menuItem, itemIndex) => (
            <React.Fragment key={itemIndex}>
              {(!menuItem.roles || menuItem.roles.some(role => (user.role && role === user.role.id) )) &&
              <>
                <NavbarItem
                  data-cy={`navbar${menuItem.link}`}
                  to={menuItem.link}
                  onClick={(e) => handleMenuItemClick(e, menuItem)}
                  onMouseEnter={() => setItemOver(menuItem.link)}
                  onMouseLeave={() => setItemOver(null)}
                >
                  <ImgItem src={menuItem.img} alt={menuItem.alt}/>
                  <LinkItem color={textColor}>{menuItem.label}</LinkItem>
                  { (matches || collapsed ) && itemOver === menuItem.link &&
                    <NavbarItemLabel backgroundcolor={backgroundSecondaryColor}>
                      <NavbarItemLabelImage backgroundcolor={backgroundSecondaryColor}>
                        <ImgItem src={menuItem.img} alt={menuItem.alt}/>
                      </NavbarItemLabelImage>
                      <NavbarItemLabelText backgroundcolor={backgroundSecondaryColor}>
                        {menuItem.label}
                      </NavbarItemLabelText>
                    </NavbarItemLabel>
                  }
                  {menuItem.submenu &&
                    <ImgCollapsedExpanded
                      src={itemsExpanded.includes(menuItem.label)
                        ? expandedImage
                        : collapsedImage
                      }
                      alt={'collapsed'}
                    />
                  }
                </NavbarItem>
                {menuItem.submenu && itemsExpanded.includes(menuItem.label) &&
                 menuItem.submenu.map((subItem, subItemIndex) => (
                  <React.Fragment key={subItemIndex}>
                    {(!subItem.roles || subItem.roles.some(role => (user.role && role === user.role.id))) &&
                      <NavbarItem
                        data-cy={`navbar${subItem.link}`}
                        collapsed={collapsed || matches ? 1 : 0}
                        submenu={1}
                        key={subItemIndex}
                        to={subItem.link}
                        backgroundcolor={backgroundSecondaryColor}
                        onMouseEnter={() => setItemOver(subItem.link)}
                        onMouseLeave={() => setItemOver(null)}
                      >
                        <ImgItem collapsed={collapsed || matches ? 1 : 0} submenu={1} src={subItem.img} alt={subItem.alt}/>
                        <LinkItem submenu={1} color={textColor}>{subItem.label}</LinkItem>
                        { (matches || collapsed ) && itemOver === subItem.link &&
                        <NavbarItemLabel backgroundcolor={backgroundSecondaryColor}>
                          <NavbarItemLabelImage backgroundcolor={backgroundSecondaryColor}>
                            <ImgItem collapsed={collapsed || matches ? 1 : 0} submenu={1} src={subItem.img} alt={subItem.alt}/>
                          </NavbarItemLabelImage>
                          <NavbarItemLabelText backgroundcolor={backgroundSecondaryColor}>
                            {subItem.label}
                          </NavbarItemLabelText>
                        </NavbarItemLabel>
                        }
                      </NavbarItem>
                    }
                  </React.Fragment>
                ))
                }
              </>
              }
            </React.Fragment>
          ))}
        </NavbarContainer>
      }
    </Media>
  )
}

/* region STYLE */

const NavbarContainer = styled.div`
  position: absolute;
  top: ${props => props.top};
  bottom: 0;
  left: 0;
  width: 250px;
  background-color: ${props => props.backgroundcolor};
  color: #FFFFFF;
  box-sizing: border-box;
  padding: 40px 0 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
`

const NavbarItem = styled(Link)`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${props => props.backgroundcolor ? props.backgroundcolor : 'transparent'};
  opacity: 1;
  margin: ${props => props.submenu ? '0' : '12px 0'};
  padding: ${props => props.submenu && !props.collapsed ? '0px 19px 0px 40px' : '5px 19px 5px 19px'};
  border-left: ${props => props.submenu ? '1px solid #a0a0a0' : '0'};
  height: 42px;
  cursor: pointer;
  outline: none;
  text-decoration: none !important; 
`

const NavbarItemLabel = styled.div`
  position: absolute;
  top: -17px;
  left: 0;
  bottom: 0;
  max-width: 250px;
  height: 42px;
  box-sizing: border-box;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding: 40px 0 20px 0;
  z-index: 99999;
  animation: 1s ${bounceInLeft};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const NavbarItemLabelImage = styled.div`
  height: 42px;
  width: 64px;
  background-color: ${props => props.backgroundcolor};
  color: #FFFFFF;
  box-sizing: border-box;
  z-index: 99999;
  animation: 1s ${bounceInLeft};
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavbarItemLabelText = styled.div`
  height: 42px;
  max-width: 186px;
  background-color: ${props => props.backgroundcolor};
  color: #FFFFFF;
  box-sizing: border-box;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 99999;
  vertical-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  padding-right: 25px;
`

const ImgItem = styled.img`
  width: ${props => props.submenu && !props.collapsed ? '20px' : '25px'};
`

const LinkItem = styled.div`
  margin-left: 20px;
  font-size: 1.0rem;
  color: ${props => props.color};
`

const ImgCollapsedExpanded = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 12px;
`

/* endregion */

export default Navbar