import React from 'react'
import styled from 'styled-components'

const Table = ({children}) => {
  return (
    <TableStyled>
      {children}
    </TableStyled>
  )
}

const TableStyled = styled.table`
  min-height: 120px!important;
  width: 100%;
  opacity: 0.9;
  border-collapse: collapse;
  margin-bottom: 20px;
`

export {Table}