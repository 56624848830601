import React from 'react'
import {Link} from 'react-router-dom'
import {Form, Field, FormSpy} from 'react-final-form'
import styled from 'styled-components'
import {Input, ButtonBlu, ButtonGreen, ButtonRed, Select} from '../../common'
import imgSearch from '../../../images/ico-search.svg'
import imgAdd from '../../../images/ico-add.svg'
import imgReset from "../../../images/resetFilters.svg";
import imgArrowUp from "../../../images/up-arrow.svg";
import imgArrowDown from "../../../images/down-arrow.svg";


const TicketTemplateFilters = (props) => {

  const {
    onSubmit, priorities,
    categories, subcategories,
    problemTypes, ticketType, filters,
    open, setOpen
  } = props

  const onSearch = (values) => {
    const filters = {...values}
    onSubmit(filters)
    setOpen(false)
  }


  const openFilters = (e) => {
    e.preventDefault()
    setOpen(!open)
  }

  const resetFilters = (form) => {
    form.reset()
    onSubmit({})
  }

  const ShowFilters = (values) => {
    const usedFilters = Object.keys(values)
      .filter(k => {
        if (k==='name') return false
        return !!values[k]
      })
    return (
      <ShowFiltersButton
        data-cy={'show-filters'}
        background='#218FC1'
        onClick={openFilters}
      >
        <ButtonImage src={open ? imgArrowUp : imgArrowDown}/>
        {
          usedFilters.length > 0 &&
          <ValorizedFilter data-cy={'filters-notification'}/>
        }
      </ShowFiltersButton>
    )
  }

  return (
    <Form
      onSubmit={onSearch}
      initialValues={filters}
      render={({handleSubmit, values}) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <FilterBar>
            <InputBox data-cy={'filters-name'}>
              <Field
                name="name"
                component={Input}
                type="text"
                label="Nome"
              />
            </InputBox>
            <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
            <FormSpy subscription={{pristine: true}}>
              {({pristine, form}) => (
                <ButtonRed
                  image={imgReset}
                  dataCy={'filters-reset'}
                  text={'Resetta'}
                  type="button"
                  disabled={pristine}
                  onClick={() => resetFilters(form)}
                />
              )}
            </FormSpy>
            <LinkStyled data-cy={'ticket-templates-new'} to='/ticket-templates/new'>
              <ButtonGreen text='Nuovo' image={imgAdd}/>
            </LinkStyled>
            {
              ShowFilters(values)
            }
            {open &&
            <FilterContainer open={open}>
              <FilterRow>
                <InputBox data-cy={'filters-type'}>
                  <Field
                    name="type"
                    component={Select}
                    type="text"
                    label="Tipo"
                  >
                    <option value={null}/>
                    {ticketType && ticketType.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-priority'}>
                  <Field
                    name="priority"
                    component={Select}
                    type="text"
                    label="Priorità"
                  >
                    <option value={null}/>
                    {priorities && priorities.filter(p => p.id !== 1).map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
              </FilterRow>
              <FilterRow>
                <InputBox data-cy={'filters-category'}>
                  <Field
                    name="category"
                    component={Select}
                    type="text"
                    label="Categoria"
                  >
                    <option value={null}/>
                    {categories && categories.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-subcategory'}>
                  <Field
                    name="subcategory"
                    component={Select}
                    type="text"
                    label="Sottocategoria"
                  >
                    <option value={null}/>
                    {subcategories && subcategories.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
                <InputBox data-cy={'filters-problemType'}>
                  <Field
                    name="problemType"
                    component={Select}
                    type="text"
                    label="Tipo di problema"
                  >
                    <option value={null}/>
                    {problemTypes && problemTypes.map(entity =>
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    )}
                  </Field>
                </InputBox>
              </FilterRow>
            </FilterContainer>}
          </FilterBar>
        </form>
      )}
    />
  )
}

/* region STYLE */

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
  margin-bottom: 50px;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
`
const FilterContainer = styled.div`
        display: flex;
        height: ${props => props.open || '0px !important'};
        width: ${props => props.open && '100% !important'};
        flex-direction: column;
        `
const FilterRow = styled.div`
        display: flex;
        flex-direction: row;
        `
const ShowFiltersButton = styled.button`
        position: relative;
        border: 0;
        font-size: 0.9rem;
        min-width: 35px;
        min-height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        background: ${props => props.background ? props.background : '#1A6C9C'};
        color: #FFFFFF;
        padding: 8px 10px;
        margin: 15px;
        cursor: pointer;
        `
const ValorizedFilter = styled.span`
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        min-width: 7px !important;
        min-height: 7px !important;
        border-radius: 7px;
        `
const LinkStyled = styled(Link)`
  text-decoration: none;
`
const ButtonImage = styled.img`
        width: 18px;
        `
/* endregion */

export default TicketTemplateFilters
