import React, {useState} from 'react'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import {Modal, Input} from '../../common'
import imgLoad from '../../../images/loader.svg'
import imgSelect from '../../../images/down-arrow-green.svg'

const ModalChangeCustomer = ({isOpened, closeModal, changeTicketCustomer }) => {

  const { asyncGet: getCustomers, loading } = useEntities('customers', { withoutEffect: true, isNotPaginated: true })
  const [shownCustomers, setShownCustomers] = useState([])

  const search = async (event) => {
    event.preventDefault()
    const customer = event.target.value.trim().toLowerCase()
    if (customer.length < 3) return
    const customers = await getCustomers({ customer, enabled: true })
    setShownCustomers(customers)
  }


  return (
    <Modal
      isOpened={isOpened}
      closeModal={closeModal}
      initWidth={650}
      initHeight={750}
    >
      <ModalContainer>
        <ModalTitle>Cerca cliente</ModalTitle>
        <ModalFormContainer>
          <InputBox data-cy={'search-customer'}>
            <Input
              name="customer"
              component={Input}
              type="text"
              placeholder="Cerca"
              onChange={search}
            />
          </InputBox>
        </ModalFormContainer>
        <Customers data-cy={'customers-list'}>
          {loading &&
            <LoadingArea><img src={imgLoad} alt="loading"/></LoadingArea>
          }
          {!loading && shownCustomers.map(c =>
            <Row key={c.id} data-cy={`customer-${c.id}`}>
              <RowIcon disabled={false}><img src={imgSelect} alt="add" onClick={() => changeTicketCustomer(c)}/></RowIcon>
              {c.name || c.businessName} {c.surname || c.structureName}
            </Row>
          )}
        </Customers>
      </ModalContainer>
    </Modal>
  )
}

/* region STYLE */

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
  width: 650px;
  height: 750px;
  
  @media(max-width: 1024px) {
    width: 80vw;
  }
  
  @media(max-width: 768px) {
    width: 100vw;
  }
`

const ModalTitle = styled.div`
  font-size: 1.2rem;
  opacity: 0.7;
  font-weight: bold;
`

const ModalFormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
  ${({width}) => `width: ${width}`};
`

const Customers = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 2px;
  font-size: 0.85rem;
  :nth-child(odd){
      background:#fafafa;
  }
`

const RowIcon = styled.div`
  margin-right: 20px;
  & img {
    width: 20px;
    height: 20px;
    margin: auto;
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  }
`

const LoadingArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
   & img {
    width: 50px;
    height: 50px;
    margin: auto;
   }
`
/* endregion */

export default ModalChangeCustomer