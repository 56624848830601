import React from 'react'
import styled from 'styled-components'


const Td = ({children, onClick, notCapitalized, dataCy, report, ...rest}) => {
  return (
    <TdStyled
      data-cy={dataCy}
      onClick={onClick}
      notCapitalized={notCapitalized}
      report={report}
      flexGrow={rest.flexGrow}
      {...rest}>
      {children}
    </TdStyled>
  )
}

const TdStyled = styled.td`
  ${props => props.report ? 'padding: 4px 5px 4px 5px !important;' : 'padding: 13px;'};
  font-size: 0.9rem;
  text-transform: ${props => props.notCapitalized ? 'none' : 'capitalize'};
  ${props => props.onClick ? 'cursor: pointer;' : null}
  ${({width}) => width && `width: ${width}`};
  ${({maxWidth}) => maxWidth && `max-width: ${maxWidth}`};
  ${({flexGrow}) => flexGrow && 'flex-grow: 1'};
`

export {Td}