import axios from 'axios'
import {baseUrl} from '../constants/config'
import {
  LOGIN, OTP_NEEDED,
  LOGIN_ERROR, CHANGE_TITLE,
  LOGOUT, START_LOADING,
  STOP_LOADING, SET_REDIRECT,
  SET_FILTERS, RESET_FILTERS, SET_PREVIOUS_PATH
} from './types'
import {getRedirectTo} from '../reducers'


export const login = (payload, history) => (dispatch, getState) => {
  const url = `${baseUrl}/login`
  axios.post(url, payload)
    .then(({data}) => {
      if (data.payload.otp) {
        dispatch({type: OTP_NEEDED, payload: data.payload.token})
      } else {
        if (data.payload.user.resetPassword) {
          dispatch({type: LOGIN, payload: data.payload})
          localStorage.setItem('token', data.payload.token)
          history.push('/reset-password')
        } else {
          dispatch({type: LOGIN, payload: data.payload})
          localStorage.setItem('token', data.payload.token)
          const redirectTo = getRedirectTo(getState())
          history.push(redirectTo)
        }
      }
    })
    .catch(err => {
      console.log(err)
      dispatch({type: LOGIN_ERROR, payload: true})
      setTimeout(() => dispatch({type: LOGIN_ERROR, payload: false}), 2000)
    })
}

export const sendOtp = (otp, token, history) => (dispatch, getState) => {
  const url = `${baseUrl}/login/otp`
  axios.post(url, otp, {headers: {Authorization: "Bearer " + token}})
    .then(({data}) => {
      if (data.payload.user.resetPassword) {
        dispatch({type: LOGIN, payload: data.payload})
        localStorage.setItem('token', data.payload.token)
        history.push('/reset-password')
      } else {
        dispatch({type: LOGIN, payload: data.payload})
        localStorage.setItem('token', data.payload.token)
        const redirectTo = getRedirectTo(getState())
        history.push(redirectTo)
      }

    })
    .catch(err => {
      console.log(err)
      dispatch({type: LOGIN_ERROR, payload: true})
      setTimeout(() => dispatch({type: LOGIN_ERROR, payload: false}), 2000)
    })
}

export const setLoginInformations = (user, token) => ({
  type: LOGIN,
  payload: {
    user,
    token
  }
})

export const setTitle = (title) => ({type: CHANGE_TITLE, payload: title})

export const logout = (history) => dispatch => {
  history.push('/login')
  dispatch({type: LOGOUT})
  localStorage.clear()
}

export const startLoading = () => ({type: START_LOADING})

export const stopLoading = () => ({type: STOP_LOADING})

export const setRedirectTo = (redirectPath) => {
  return {type: SET_REDIRECT, payload: redirectPath}
}

export const setFilters = (url, filters) => (dispatch, getState) => {
  const newMap = new Map(getState().pages.filters)
  newMap.set(url, filters || {})
  return dispatch({type: SET_FILTERS, payload: newMap})
}

export const  removeFilters = (urls) => (dispatch, getState) => {
  let newUrls = [...urls]
  if (!Array.isArray(urls)) newUrls = [urls]
  const newMap = new Map(getState().pages.filters)
  newUrls.forEach(u => newMap.delete(u))
  return dispatch({type: SET_FILTERS, payload: newMap})
}

export const resetFilters = () => ({type: RESET_FILTERS})

export const setPreviousPath = (path) => ({type: SET_PREVIOUS_PATH, payload: path})