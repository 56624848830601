import React from 'react'
import {connect, useSelector} from 'react-redux'
import styled from 'styled-components'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions/'
import OpenedTickets from './OpenedTickets'
import OpenedTicketsByCompany from './OpenedTicketsByCompany'
import ClosedTickets from './ClosedTickets'
import CreatedLast7Days from './CreatedLast7Days'
import {getUser} from "../../../reducers";


const Home = ({setTitle}) => {

  useTitle(setTitle, 'Home')

  const user = useSelector(getUser)

  return (
    <PageContainer>
      <Row>
        <Card><OpenedTickets/></Card>
        <Card><ClosedTickets/></Card>
      </Row>
      <Row>
        <Card fullrow={true}><CreatedLast7Days/></Card>
      </Row>
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
`

const Card = styled.div`
  margin: 20px 30px;
  padding: 10px 5px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45);
  border-radius: 15px;
  ${({fullrow}) => fullrow && `width: 100%`};
`
/* endregion */

export default connect(null, {setTitle})(Home)