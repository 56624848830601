import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ButtonGreen, Table, Tr, Th, Td } from '../../common'
import ModalNewSubcategory from './ModalNewSubcategory'
import imgAdd from '../../../images/ico-add.svg'
import imgEdit from '../../../images/edit.svg'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/remove.svg'


const TableSubcategories = ({ subcategories, switchEnabled, refreshData, categoryID, disabled }) => {

  const [subcategory, setSubcategory] = useState({})
  const [showModalAddSubcategory, setShowModalAddSubcategory] = useState(false)

  const openModalAddSubcategory = (item) => {
    setSubcategory(item)
    setShowModalAddSubcategory(true)
  }

  const closeModalAddProduct = () => {
    setShowModalAddSubcategory(false)
    setSubcategory({})
    refreshData()
  }

  const toRender = () => {
    if (disabled) {
      return(
        <InfoMessage data-cy={'infomessage-subcategories'}>Per aggiungere le sottocategorie, salvare prima le informazioni della categoria</InfoMessage>
      )
    }
    return(
      <Table >
        <thead>
        <tr>
          <Th/>
          <Th>ID</Th>
          <Th>Nome</Th>
          <Th>Abilitata</Th>
        </tr>
        </thead>
        <tbody data-cy={'subcategories-table'}>
        {subcategories && subcategories.map(subcategory => (
          <Tr key={subcategory.id} data-cy={`subcategory-${subcategory.id}`} >
            <TdButton>
                <LinkStyled data-cy={'edit-subcategory'} to={`/ticket-subcategories/${subcategory.id}`}>
                    <ImgButton src={imgEdit} alt='edit-subcategory'/>
                </LinkStyled>
            </TdButton>
            <Td>{subcategory.id}</Td>
            <Td>{subcategory.name}</Td>
            <Td>
              {subcategory.enabled
                ? <ImageButton
                      src={imgEnabled}
                      onClick={() => switchEnabled(subcategory)}
                      data-cy={'subcategory-enabled'}
                  />
                : <ImageButton
                      src={imgDisabled}
                      onClick={() => switchEnabled(subcategory)}
                      data-cy={'subcategory-disabled'}
                  />
              }
            </Td>
          </Tr>
        ))}
        </tbody>
      </Table>
    )
  }

  return(
    <TableSubcategoriesContainer>
      <ButtonBar>
        <Title>Sottocategorie</Title>
        <ButtonGreen dataCy='add-subcategories'
          text='Aggiungi Sottocategoria'
          image={imgAdd}
          disabled={disabled}
          onClick={() => openModalAddSubcategory({})}
        />
      </ButtonBar>
      { toRender() }
      <ModalNewSubcategory
        isOpened={showModalAddSubcategory}
        entity={subcategory}
        closeModal={closeModalAddProduct}
        refreshData={refreshData}
        categoryID={categoryID}
      />
    </TableSubcategoriesContainer>
  )
}

/* region STYLE */

const TableSubcategoriesContainer = styled.div`
  position: relative;
  flex: 1;
  background: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 25px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`

const InfoMessage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
  font-size: 1.05rem;
  opacity: 0.6;
  font-weight: bold;
`

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  flex-wrap: wrap;
`

const TdButton = styled.td`
  padding: 6px 2px;
`

const ImgButton = styled.img`
  width: 20px;
  cursor: pointer;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
`
/* endregion */

export default TableSubcategories
