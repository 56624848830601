import React from 'react'
import Autosuggest from 'react-autosuggest'
import styled from 'styled-components'
import { Input } from './Input'


const Autocomplete = (props) => {

  //region Props
  const {
    activateMutator,
    dataCy,
    getSuggestionLabel,
    getSuggestionValue,
    label,
    minQueryLength = 2,
    onChange,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    onSuggestionsFetchRequested,
    placeholder,
    suggestions,
    setSuggestions,
    value
  } = props

  const inputProps = {
    autoComplete: "no",
    onChange,
    placeholder,
    value
  }

  //endregion

  // Components

  const Suggestion = suggestion => (
    <Option>
      {getSuggestionLabel(suggestion)}
    </Option>
  )

  const SuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <Container {...containerProps} hideBorderBottom={suggestions.length === 0}>
        {children}
      </Container>
    )
  }

  //endregion

  // Autosuggests methods

  const onSuggestionsClearRequestedDefault = () => {
    setSuggestions([])
  }

  const shouldRenderSuggestions = (value) => {
    return suggestions.length || value.trim().length > minQueryLength
  }

  const onSuggestionSelectedDefault = (event, options) => {
    onSuggestionSelected && onSuggestionSelected(event, options)
    event.target.value = options.suggestionValue
    onChange(event)
    activateMutator && activateMutator()
  }

  //endregion

  return (
    <div data-cy={dataCy}>
      <CustomLabel>{label}</CustomLabel>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested || onSuggestionsClearRequestedDefault}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={Suggestion}
        renderInputComponent={Input}
        renderSuggestionsContainer={SuggestionsContainer}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionSelected={onSuggestionSelectedDefault}
      />
    </div>
  )
}

export { Autocomplete }

//region Style

const CustomLabel = styled.label`
  opacity: 0.7;
  font-size: 0.95rem;
`

const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #000000;
  border-top: none;
  ${({hideBorderBottom}) => hideBorderBottom && 'border-bottom: none'};
  max-height: 300px;
  overflow-y: auto;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
`

const Option = styled.div`
  cursor: pointer;
  padding: 2px;
  &:hover {
    background: #1E90FF;
    color: #FFFFFF;
  }
`
//endregion