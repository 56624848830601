import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setFilters, removeFilters} from "../actions"
import {getFilters, getPreviousPath} from '../reducers'
import {useLocation} from 'react-router'


const mustRemoveFilters = (prevPath, path) => {
  if (!prevPath || (prevPath === '/customers' && path.match(/\/customers\/\d+\/tickets/))) return false
  if (prevPath === '/') {
    return true
  }
  const wasTicketDetailOrCustomerHistory = prevPath.match(/\/customers\/\d+\/tickets\/?\d*/) && (path === '/customers' || path === '/tickets/all' || path === '/my-tickets')
  const wasCustomerDetail = prevPath.match(/\/customers\/?\d+/) && (path === '/customers' || path === '/tickets/all' || path === '/my-tickets')
  return !wasCustomerDetail && !wasTicketDetailOrCustomerHistory
}

export default (values = {}) => {
  const {pathname} = useLocation()
  const filters = useSelector(getFilters)
  const previousPath = useSelector(getPreviousPath)
  const dispatch = useDispatch()

  useEffect(() => {
    if (previousPath === pathname) return
    if (mustRemoveFilters(previousPath, pathname)) {
      dispatch(removeFilters([previousPath, pathname]))
    } else {
      let currentFilters = filters.get(pathname)
      if (!currentFilters)
        currentFilters = values
      else {
        currentFilters = {...currentFilters, ...values}
      }
      dispatch(setFilters(pathname, currentFilters))
    }

  }, [previousPath, pathname])

  return [filters, setFilters]
}
