import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import {ButtonGreen, Input, Modal} from '../../common'
import imgAdd from '../../../images/ico-add-green.svg'
import imgOk from '../../../images/ico-add.svg'
import imgRemove from '../../../images/remove.svg'
import imgLoading from '../../../images/loader.svg'


const ModalStockists = ({isOpened, closeModal, ticket, stockists, setTicket}) => {

  const {
    asyncUpdate: addStockistCall, asyncRemove: removeStockistCall, loading
  } = useEntities('tickets', {withoutEffect: true})

  const {entities: customers, loading: loadingStockists} = useEntities('customers', {
    isNotPaginated: true,
    initialFilters: {enabled: true, stockists: true},
    withoutLoading: true,
    withoutNotifications: true
  })

  const [shownCustomers, setShownCustomers] = useState([])

  useEffect(() => {
    if (stockists && customers)
      setShownCustomers(customers.filter(c => {
        return !stockists.find(s => s.id === c.id)
      }))
  }, [customers, stockists])

  const addStockist = (customer) => {
    if (loading) return
    addStockistCall({}, ticket.id, {action: `stockists/${customer.id}`, throwError: true})
      .then(() => {
        const newTicket = Object.assign({}, ticket)
        newTicket.stockists = stockists.concat([customer])
        setTicket(newTicket)
        setShownCustomers(shownCustomers.filter(c => c.id !== customer.id))
      })
  }

  const removeStockist = (customer) => {
    if (loading) return
    removeStockistCall(ticket.id, {action: `stockists/${customer.id}`, throwError: true})
      .then(() => {
        const newTicket = Object.assign({}, ticket)
        newTicket.stockists = stockists.filter(s => s.id !== customer.id)
        setTicket(newTicket)
        setShownCustomers(shownCustomers.concat([customer]))
      })
  }

  const search = (event) => {
    const customer = event.target.value.toLowerCase()
    if (customer === '')
      setShownCustomers(customers)

    setShownCustomers(customers.filter(c => {
      if (c.name && c.name.toLowerCase().includes(customer)) return true
      if (c.surname && c.surname.toLowerCase().includes(customer)) return true
      if (c.businessName && c.businessName.toLowerCase().includes(customer)) return true
      if (c.structureName && c.structureName.toLowerCase().includes(customer)) return true
      return false
    }))
  }

  return (
    <Modal
      isOpened={isOpened}
      closeModal={closeModal}
      initWidth={650}
      initHeight={750}
    >
      <ModalContainer>
        <ModalTitle>Stockists</ModalTitle>
        <Stockists>
          {(!stockists || stockists.length === 0) &&
            <Disclaimer>Nessuno stockist selezionato</Disclaimer>
          }
          {stockists && stockists.map(s =>
            <Row key={s.id}>
              <RowIcon disabled={loading}><img src={imgRemove} alt="remove"
                                               onClick={() => removeStockist(s)}/></RowIcon>
              {s.name || s.businessName} {s.surname || s.structureName}
            </Row>
          )}
        </Stockists>
        <ModalFormContainer>
          <InputBox data-cy={'search-customer'}>
            <Input
              name="customer"
              component={Input}
              type="text"
              placeholder="Cerca"
              onChange={search}
            />
          </InputBox>
        </ModalFormContainer>
        <ModalTitle>Clienti</ModalTitle>
        <Customers>
          {!loadingStockists && shownCustomers.length === 0 &&
            <Disclaimer>Nessun cliente trovato</Disclaimer>
          }
          {loadingStockists &&
            <Loader src={imgLoading} alt="loading" />
          }
          {shownCustomers.map(c =>
            <Row key={c.id}>
              <RowIcon disabled={loading}><img src={imgAdd} alt="add" onClick={() => addStockist(c)}/></RowIcon>
              {c.name || c.businessName} {c.surname || c.structureName}
            </Row>
          )}
        </Customers>
        <ButtonContainer>
          <ButtonGreen
            onClick={(e) => closeModal()}
            text='OK'
            image={imgOk}
            dataCy={'modal-ok'}
          />
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  )
}

/* region STYLE */

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
  width: 650px;
  height: 750px;
  overflow-y: auto;
  
  @media(max-width: 1024px) {
    width: 80vw;
  }
  
  @media(max-width: 768px) {
    width: 100vw;
  }
`

const ModalTitle = styled.div`
  font-size: 1.2rem;
  opacity: 0.7;
  font-weight: bold;
`

const ModalFormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
  ${({width}) => `width: ${width}`};
`

const Stockists = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 5px;
`

const Customers = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-height: 400px;
  min-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 2px;
  font-size: 0.85rem;
  :nth-child(odd){
      background:#fafafa;
  }
`

const RowIcon = styled.div`
  margin-right: 20px;
  & img {
    width: 20px;
    height: 20px;
    margin: auto;
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  }
`

const Disclaimer = styled.div`
  width: 100%;
  text-align: center;
  margin: auto;
  font-size: 1.2rem;
  opacity: 0.85;
`

const ButtonContainer = styled.div`
  margin: auto;
`

const Loader = styled.img`
  width: 100px;
  margin: auto;
`
/* endregion */

export default ModalStockists