import React from 'react'
import styled from 'styled-components'
import {TableContainer, Table, Tr, Th, Td, TdImage} from '../../common'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/remove.svg'
import imgHistory from '../../../images/history-green.svg'

const TableCustomers = ({entities, order, switchEnabled, userIsAdmin, usedFilters, ...rest}) => {

  return (
    <TableContainer>
      {(usedFilters || entities.length > 0) && <Table>
        <thead>
        <tr>
          <Th/>
          <Th/>
          <Th {...rest}>Tipo</Th>
          <Th {...rest}>Nome / P.IVA</Th>
          <Th {...rest}>Cognome / Ragione sociale</Th>
          <Th {...rest}>Città</Th>
          <Th {...rest}>Indirizzo</Th>
          <Th {...rest}>Email</Th>
          <Th {...rest}>Telefono</Th>
          <Th {...rest}>Abilitato</Th>
        </tr>
        </thead>
        <tbody data-cy={'customers-table'}>
        {entities && entities.length > 0 && entities.map(customer =>
          <Tr key={customer.id} data-cy={`customers-row-${customer.id}`}>
            <TdImage tooltip="Modifica cliente" editPath={`/customers/${customer.id}`}/>
            <TdImage tooltip="Vai allo storico dei ticket" editPath={`/customers/${customer.id}/tickets`}
                     image={imgHistory} alt="history"/>
            <Td dataCy={'customerType'}>
              <CustomerElite elite={customer.elite} data-cy={`elite-style-${customer.id}`}>
                {customer.customerType ? customer.customerType.name : '-'}
              </CustomerElite>
            </Td>
            <Td dataCy={'customer'}>{customer.name || customer.businessName || '-'}</Td>
            <Td dataCy={'surname'}>{customer.surname || customer.structureName || '-'}</Td>
            <Td dataCy={'city'}>{customer.city}</Td>
            <Td dataCy={'address'}>{customer.address || '-'}</Td>
            <Td dataCy={'email'}>{customer.email || '-'}</Td>
            <Td dataCy={'phone'}>{customer.phone || '-'}</Td>
            <Td>
              {customer.enabled
                ? <ImageButton data-cy={'item-enabled'} admin={userIsAdmin} src={imgEnabled}
                               onClick={() => switchEnabled(customer)}
                />
                : <ImageButton data-cy={'item-disabled'} admin={userIsAdmin} src={imgDisabled}
                               onClick={() => switchEnabled(customer)}/>
              }
            </Td>
          </Tr>
        )}
        {entities && entities.length === 0 && <Tr withoutHover>
          <NoRecords data-cy={'no-customers'}
                     colSpan={12}>Nessun customer
          </NoRecords></Tr>}
        </tbody>
      </Table>
      }
      {
        !usedFilters && entities.length === 0 &&
        <NoCustomer data-cy={'no-search-pattern'}>
          Inserisci dei criteri di ricerca per iniziare
        </NoCustomer>
      }
    </TableContainer>
  )
}

//region STYLE

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
  cursor:${props => props.admin && 'pointer'};
`
const CustomerElite = styled.div`
  ${({elite}) => elite && 'background: #1A6C9C'};
  ${({elite}) => elite && 'color: #FFFFFF'};
  width: fit-content;
  padding: 3px;
`
const NoCustomer = styled.div`
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.85;
`
const NoRecords = styled.td`
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.85;
`
//endregion

export default TableCustomers