import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions/'
import useEntities from '../../../hooks/useEntities'
import withPagination from '../../withPagination'
import Filters from './Filters'
import Table from './Table'
import {getUser} from "../../../reducers"
const TablePaginated = withPagination(Filters, Table)


const CustomerTypes = () => {
  const me = useSelector(getUser)
  const dispatch = useDispatch()

  useTitle((title) => dispatch(setTitle(title)), 'Tipo Clienti')
  const {entities, total, get} = useEntities('customer-types')

  return (
    <TablePaginated
      entities={entities}
      total={total}
      get={get}
      userIsAdmin={me.role.isAdmin}
    />
  )
}

export default CustomerTypes