import { combineReducers } from 'redux'
import {LOGIN, LOGIN_ERROR, LOGOUT, OTP_NEEDED} from '../actions/types'

const token = (state = null, {type, payload}) => {
  switch (type) {
    case LOGIN :
      return payload.token
    case LOGOUT :
      return null
    default :
      return state
  }
}

const user = (state = {}, { type, payload}) => {
  switch (type) {
    case LOGIN:
      return payload.user
    case LOGOUT:
      return {}
    default:
      return state
  }
}

const error = (state = false, {type, payload}) => {
  switch (type) {
    case LOGIN_ERROR :
      return payload
    default :
      return state
  }
}

const otpNeeded = (state = null, { type, payload }) =>  {
  switch (type) {
    case OTP_NEEDED :
      return payload
    case LOGIN:
      return null
    default :
      return state
  }
}

export default combineReducers({
  token,
  user,
  error,
  otpNeeded
})
