import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import imgReport from '../../../images/reports-green.svg'


const ReportCard = ({ name, path , dataCy}) => {
  return (
    <Container to={`/reports/${path}`} data-cy={dataCy}>
      <Icon><img src={imgReport} alt={name} /></Icon>
      <Name>{ name }</Name>
    </Container>
  )
}

export default ReportCard

//region Style

const Container = styled(Link)`
  text-decoration: none;
  color: inherit;
  background: #FFFFFF;
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  margin: 20px 10px;
  padding: 10px 5px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.45);
`

const Icon = styled.div`
  width: 30px;
  padding: 10px;
  height: 50px;
  & img {
    width: 30px;
  } 
`

const Name = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50px;
  opacity: 0.85;
`

//endregion