import Excel from 'exceljs'

export const createExcel = async (reportName, data, keys) => {

  const header = keys.map((k) => {
    return {
      header: k,
      key: k,
      width: 15
    }
  })

  const report = new Excel.Workbook()
  report.creator = 'Fcut'
  report.created = new Date()

  const page = report.addWorksheet(reportName)

  page.columns = header

  page.getRow(1).eachCell(c => {
    if (c.text !== '') {
      c.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '008E8C'}
      }

      c.font = {
        color: {argb: "FFFFFF"},
        bold: true
      }

      c.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
      }
    }
  })

  const elaborateData = data.map(d => {
    const el = d
    header.forEach(h => {
      if (d[h.key] === undefined || d[h.key] === null)
        d[h.key] = 0
    })
    return el
  })

  page.addRows(elaborateData)
  for (let i = 1; i <= header.length; i++) {
    page.getColumn(i).eachCell((c, index) => {
      if (index > 1) {
        c.style = {
          alignment: {horizontal: 'right'}
        }
      }
    })
  }
  const buf = await report.xlsx.writeBuffer()

  return new Blob(
    [buf],
    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
  )

}