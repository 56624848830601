import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import stripHtml from '../../../helpers/strip-html'

const TableHistory = ({ history }) => {

  return (
    <Container>
      <Header>
        <HeaderField>Data</HeaderField>
        <HeaderField>Stato</HeaderField>
        <HeaderField>Creatore</HeaderField>
        <HeaderField>Assegnato a</HeaderField>
        <HeaderField flex={1}>Note</HeaderField>
      </Header>
      { history && history.map(t => (
        <Row key={t.id}>
          <RowField>{ moment(t.created).format('HH:mm DD-MM-YYYY') }</RowField>
          <RowField>{ t.state.name }</RowField>
          <RowField>{ t.creator.name } { t.creator.surname }</RowField>
          <RowField>{ t.assignTo.name } { t.assignTo.surname }</RowField>
          <RowField flex={1}>{ stripHtml(t.note) }</RowField>
        </Row>
      ))}
    </Container>
  )
}

export default TableHistory

//region Style

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.85rem;
  padding: 0 10px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

const HeaderField = styled.div`
  font-weight: bolder;
  padding: 5px 15px;
  width: 150px;
  text-align: left;
`

const RowField = styled.div`
  padding: 5px 15px;
  min-width: 150px;
  text-align: left;
`
//endregion
