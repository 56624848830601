import React, {useState} from 'react'
import {connect} from 'react-redux'
import {setTitle} from "../../../../actions"
import useTitle from "../../../../hooks/useTitle"
import useEntities from "../../../../hooks/useEntities"
import Report from './Table'
import Filters from './Filters'
import Graph from './Graph'
import * as moment from "moment"

const BySubcategory = ({setTitle}) => {

  useTitle(setTitle, 'Report - By Subcategory')

  const [open, setOpen] = useState(false)
  const [dates, setDates] = useState([
    moment().subtract(1, 'month').startOf('month').format('MMM YYYY'),
    moment().endOf('month').format('MMM YYYY')
  ])

  const {entities: data, total, get} = useEntities("reports/by-subcategories", {isNotPaginated: true})
  const {entities: sources} = useEntities('ticket-sources', {isNotPaginated: true})
  const {entities: groups} = useEntities('user-groups', {isNotPaginated: true})
  const {entities: categories} = useEntities('ticket-categories', {isNotPaginated: true})

  return (
    <>
      <Filters
        open={open}
        data={data}
        dateArray={dates}
        setOpen={setOpen}
        setDates={setDates}
        sources={sources}
        groups={groups}
        categories={categories}
        total={total}
        onSubmit={get}
      />
      <Graph
        reportRows={data}
        dateArray={dates}
      />
      <Report
        data={data}
        dateArray={dates}
        total={total}
      />
    </>

  )
}

export default connect(null, {setTitle})(BySubcategory)