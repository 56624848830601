import React from 'react'
import styled from 'styled-components'

const TableContainer = ({children, paddingTop, id}) => {
  return (
    <TableContainerStyled paddingTop={paddingTop} id={id}>
      {children}
    </TableContainerStyled>
  )
}

/* region STYLE */

const TableContainerStyled = styled.div`
  ${props => props.paddingTop && `padding-top:${props.paddingTop};`};
  box-sizing: border-box;
  overflow-x: auto;
  width: 100%;
  //height: 1200px;
`

/* endregion */

export {TableContainer}