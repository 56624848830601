import React from 'react'
import styled from 'styled-components'
import { TableContainer, Table, Tr, Th, Td, TdImage } from '../../common'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/remove.svg'

const TableTicketProblems = ({ entities, order, switchEnabled, ...rest }) => {
  return(
    <TableContainer>
      <Table>
        <thead>
        <tr>
          <Th></Th>
          <Th field='label'  {...rest}>ID</Th>
          <Th field='division' {...rest}>Nome</Th>
          <Th field='division' {...rest}>Abilitata</Th>
        </tr>
        </thead>
        <tbody data-cy={'problemtypes-table'} >
        { entities && entities.map( problemType =>
          <Tr key={problemType.id} data-cy={`problemtypes-row-${problemType.id}`} >
            <TdImage tooltip="Modifica tipo di problema" editPath={`/ticket-problemtypes/${problemType.id}`} />
            <Td dataCy={'id'} >{problemType.id || '-'}</Td>
            <Td dataCy={'name'} >{problemType.name || '-'}</Td>
            <Td>
              {problemType.enabled
                ? <ImageButton data-cy={'item-enabled'} src={imgEnabled} onClick={() => switchEnabled(problemType)} />
                : <ImageButton data-cy={'item-disabled'} src={imgDisabled} onClick={() => switchEnabled(problemType)} />
              }
            </Td>
          </Tr>
        )}
        </tbody>
      </Table>
    </TableContainer>
  )
}

//region STYLE

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

//endregion

export default TableTicketProblems