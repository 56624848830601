import React from 'react'
import {Link} from 'react-router-dom'
import {Form, Field, FormSpy} from 'react-final-form'
import styled from 'styled-components'
import {Input, ButtonBlu, ButtonGreen, Select, ButtonRed} from '../../common'
import imgSearch from '../../../images/ico-search.svg'
import imgAdd from '../../../images/ico-add.svg'
import imgReset from "../../../images/resetFilters.svg";


const UsersFilters = ({onSubmit, groups, roles, filters}) => {

  const resetFilters = (form) => {
    form.reset()
    onSubmit({})
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={filters}
      render={({handleSubmit}) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <FilterBar>
            <InputBox data-cy={'filters-username'}>
              <Field
                name="user"
                component={Input}
                type="text"
                label="Utente"
              />
            </InputBox>
            <InputBox data-cy={'filters-group'}>
              <Field
                label="Gruppo"
                name="group"
                component={Select}
                type="text"
              >
                <option value={null}/>
                {groups && groups.map(group =>
                  <option key={group.id} value={group.id}>{group.name}</option>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'filters-role'}>
              <Field
                label="Ruolo"
                name="role"
                component={Select}
                type="text"
              >
                <option value={null}/>
                {roles && roles.map(role =>
                  <option key={role.id} value={role.id}>{role.name}</option>
                )}
              </Field>
            </InputBox>
            <InputBox data-cy={'filters-enabled'}>
              <Field
                label="Abilitato"
                name="enabled"
                component={Select}
                type="text"
              >
                <option value={null}/>
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </Field>
            </InputBox>
            <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
            <FormSpy subscription={{pristine: true}}>
              {({pristine, form}) => (
                <ButtonRed
                  image={imgReset}
                  dataCy={'filters-reset'}
                  text={'Resetta'}
                  type="button"
                  disabled={pristine}
                  onClick={() => resetFilters(form)}
                />
              )}
            </FormSpy>
            <LinkStyled data-cy={'users-new'} to='/users/new'>
              <ButtonGreen text='Nuovo' image={imgAdd}/>
            </LinkStyled>
          </FilterBar>
        </form>
      )}
    />
  )
}

/* region STYLE */

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
  margin-bottom: 50px;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

/* endregion */

export default UsersFilters
