import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import { setTitle } from '../../../actions'
import ReportCard from './ReportCard'


const Reports = () => {
  const dispatch = useDispatch()
  useTitle(title => dispatch(setTitle(title)), 'Reports')

  return (
    <Container>
      <ReportCard name="Report by group (yearly)" path="by-group-yearly"
                  dataCy={'report-by-group-yearly'}/>
      <ReportCard name="Report by agent" path="by-agent"
                  dataCy={'report-by-agent'}/>
      <ReportCard name="Report by customer type" path="by-customer-type"
                  dataCy={'report-by-customer-type'}/>
      <ReportCard name="Report by subcategory" path="by-subcategory"
                  dataCy={'report-by-subcategory'}/>
      <ReportCard name="Report by category" path="by-category"
                  dataCy={'report-by-category'}/>
      <ReportCard name="Report by source" path="by-source"
                  dataCy={'report-by-source'}/>
    </Container>
)
}

export default Reports

//region Style

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0 0 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`

//endregion