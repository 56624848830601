import React from 'react'
import menuItems from '../constants/menu'


const menuToPermissions = new Map()

menuItems.forEach(item => {
  if (item.roles) {
    menuToPermissions.set(item.link, item.roles)
  }
  if (item.submenu) {
    item.submenu.forEach(subitem => {
      if (subitem.roles) {
        menuToPermissions.set(subitem.link, subitem.roles)
      }
    })
  }
})


const withPermission = (Component) => ({ user, ...props }) => {

  const isEnabled = route => {
    if (!user.role || user.resetPassword) return false
    const roles = menuToPermissions.get(route)
    if (!roles) return true
    return roles.includes(user.role.id)
  }

  return (
    <>
      { isEnabled(props.path) && <Component {...props} />}
    </>
  )
}

export default withPermission