import React from 'react'
import {Link} from 'react-router-dom'
import {Form, Field, FormSpy} from 'react-final-form'
import styled from 'styled-components'
import {Input, ButtonBlu, ButtonGreen, ButtonRed} from '../../common'
import imgSearch from '../../../images/ico-search.svg'
import imgAdd from '../../../images/ico-add.svg'
import imgReset from "../../../images/resetFilters.svg";


const TicketProblemTypeFilters = ({onSubmit}) => {

  const resetFilters = (form) => {
    form.reset()
    onSubmit({})
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({handleSubmit}) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <FilterBar>
            <InputBox data-cy={'filters-name'}>
              <Field
                name="name"
                component={Input}
                type="text"
                label="Nome"
              />
            </InputBox>
            <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
            <FormSpy subscription={{pristine: true}}>
              {({pristine, form}) => (
                <ButtonRed
                  image={imgReset}
                  dataCy={'filters-reset'}
                  text={'Resetta'}
                  type="button"
                  disabled={pristine}
                  onClick={() => resetFilters(form)}
                />
              )}
            </FormSpy>
            <LinkStyled data-cy={'ticket-states-new'} to='/ticket-states/new'>
              <ButtonGreen text='Nuovo' image={imgAdd}/>
            </LinkStyled>
          </FilterBar>
        </form>
      )}
    />
  )
}

/* region STYLE */

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
  margin-bottom: 50px;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

/* endregion */

export default TicketProblemTypeFilters
