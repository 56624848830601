import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import { setTitle } from '../../../actions'
import FormTicketSubcategory from './Form'


const TicketCategory = ({ setTitle, match, history  }) => {

  const id = match.params.ID === 'new' ? null : match.params.ID
  const options = {
    id,
    redirect : history.push,
    withoutEffect : match.params.ID === 'new'
  }
  const {
    entities: subcategory,
    update,
    create,
    remove,
  } = useEntities("ticket-subcategories", options)

  const { entities: categories } = useEntities('ticket-categories', {
    isNotPaginated: true,
    initialFilters: { enabled: true }
  })

  useTitle(setTitle, id ? 'Dettaglio sottocategoria' : 'Nuova sottocategoria')

  return(
    <PageContainer>
      <FormTicketSubcategory
        entity={subcategory}
        action={id ? update : create}
        remove={remove}
        categories={categories}
      />
      <SubtablesContainer>
      </SubtablesContainer>

    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const SubtablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  @media(min-width: 1920px) {
     flex-direction: row;
  }
`

/* endregion */

export default withRouter(connect(null, { setTitle })(TicketCategory))