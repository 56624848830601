import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import useEntities from '../../../hooks/useEntities'
import { ButtonGreen, Table, Tr, Th, Td } from '../../common'
import ModalNewProblemType from './ModalNewProblemType'
import imgAdd from '../../../images/ico-add.svg'
import imgRemove from '../../../images/ico-delete-red.svg'
import imgEdit from '../../../images/edit.svg'
import imgEnabled from '../../../images/ico-checked-green.svg'
import imgDisabled from '../../../images/disable-grey.svg'


const TableProblemTypes = ({ problemTypes, refreshData, category, disabled }) => {

  const { disassociate } = useEntities("ticket-categories", { withoutEffect : true })
  const [subcategory, setProblemType] = useState({})
  const [showModalAddProblemType, setShowModalAddProblemType] = useState(false)

  const openModalAddProblemType = (item) => {
    setProblemType(item)
    setShowModalAddProblemType(true)
  }

  const closeModalAddProduct = () => {
    setShowModalAddProblemType(false)
    setProblemType({})
    refreshData()
  }

  const removeItem = async (problemType) => {
    confirmAlert({
      title: 'Disassociazione tipo di problema',
      message: 'Vuoi davvero disassociare questo tipo di problema dalla categoria?',
      buttons: [
        { label: 'Si',
          onClick: async () => {
            await disassociate(category.id, 'problemtype', problemType.id)
            refreshData()
          }
        },
        { label: 'No' }
      ]
    })
  }

  const toRender = () => {
    if (disabled) {
      return(
        <InfoMessage data-cy={'infomessage-problemTypes'}>Per aggiungere i tipi di problema, salvare prima le informazioni della categoria</InfoMessage>
      )
    }
    return(
      <Table >
        <thead>
        <tr>
          <Th/>
          <Th/>
          <Th>ID</Th>
          <Th>Nome</Th>
          <Th>Abilitata</Th>
        </tr>
        </thead>
        <tbody data-cy={'problemTypes-table'} >
        {problemTypes && problemTypes.map(subcategory => (
          <Tr key={subcategory.id} data-cy={`problem-type-${subcategory.id}`} >
            <TdButton>
              <LinkStyled data-cy={'edit-problemTypes'} to={`/ticket-problem-types/${subcategory.id}`}>
                <ImgButton src={imgEdit} alt='edit problem type'/>
              </LinkStyled>
            </TdButton>
            <TdButton>
              <ImgButton src={imgRemove} alt='removeItem'
                         onClick={() => removeItem(subcategory)}
                         data-cy={'remove-problemTypes'}
              />
            </TdButton>
            <Td>{subcategory.id}</Td>
            <Td>{subcategory.name}</Td>
            <Td>
              {subcategory.enabled
                ? <ImageButton src={imgEnabled} data-cy={'problemTypes-enabled'} />
                : <ImageButton src={imgDisabled} data-cy={'problemTypes-disabled'} />
              }
            </Td>
          </Tr>
        ))}
        </tbody>
      </Table>
    )
  }

  return(
    <TableProblemTypesContainer>
      <ButtonBar>
        <Title>Tipi di problema</Title>
        <ButtonGreen dataCy='add-problemTypes'
          text='Aggiungi tipo di problema'
          image={imgAdd}
          disabled={disabled}
          onClick={() => openModalAddProblemType({})}
        />
      </ButtonBar>
      { toRender() }
      <ModalNewProblemType
        isOpened={showModalAddProblemType}
        entity={subcategory}
        closeModal={closeModalAddProduct}
        refreshData={refreshData}
        category={category}
      />
    </TableProblemTypesContainer>
  )
}

/* region STYLE */

const TableProblemTypesContainer = styled.div`
  position: relative;
  flex: 1;
  background: #e2e2e2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 25px;
  overflow: auto;
  width: 100%;
  height: 50%;
  
  @media (min-width: 1920px) {
    height: 100%;
  }
`

const InfoMessage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
  font-size: 1.05rem;
  opacity: 0.6;
  font-weight: bold;
`

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  flex-wrap: wrap;
`

const TdButton = styled.td`
  padding: 6px 2px;
`

const ImgButton = styled.img`
  width: 20px;
  cursor: pointer;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

const ImageButton = styled.img`
  width: 20px;
  height: 20px;
`

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
`
/* endregion */

export default TableProblemTypes
