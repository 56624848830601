import React from 'react'
import { Button } from './Button'

const ButtonRed = (props) => {
  return(
    <Button
      {...props}
      background='#a31d1d'
    />
  )
}

export { ButtonRed }